import React from 'react';

import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';


// Importation du fichier de configuration
import {
    PHONE_SEPARATOR,
} from "PathWWW/components/WWWConfig.jsx";

import { toStrPhoneNumber } from "PathCommon/tools/utils.jsx";




//
// Style propre au composant
//
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentContactRoot: style => ({
        ...style.contentContactRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactRoot.xl,
        },
    }),

    contentContactTitle: style => ({
        ...style.contentContactTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactTitle.xl,
        },
    }),

    contentContactList: style => ({
        ...style.contentContactList.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactList.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactList.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactList.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactList.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactList.xl,
        },
    }),

    contentContactListIcon: style => ({
        ...style.contentContactListIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactListIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactListIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactListIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactListIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactListIcon.xl,
        },
    }),

    contentContactListItem: style => ({
        ...style.contentContactListItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactListItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactListItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactListItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactListItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactListItem.xl,
        },
    }),

    contentContactListItemExtraInfos: style => ({
        ...style.contentContactListItemExtraInfos.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactListItemExtraInfos.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactListItemExtraInfos.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactListItemExtraInfos.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactListItemExtraInfos.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactListItemExtraInfos.xl,
        },
    }),
}));



//
// Composant représentant un contenu de type 'liste de contacts'
//
export const ContentContact = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentContactRoot: {
                    common: {
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentContactTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentContactList: {
                    common: {
                        padding: theme.spacing(2),
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        borderRadius: theme.spacing(0.5),
                        backgroundColor: '#FFF',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentContactListIcon: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentContactListItem: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentContactListItemExtraInfos: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '0.5rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // S'il n'y a pas (ou plus) d'accès rapide, alors on n'affiche rien
    if(data.lst_contacts.length < 1) {
        return null;
    }

    return (
        <Box
            className={classes.contentContactRoot}
        >
            {title &&
                <Typography
                    className={classes.contentContactTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <List className={classes.contentContactList}>
                {data.lst_contacts.map((item, index) => (
                    <ListItem
                        key={index}
                        alignItems="flex-start"
                        disableGutters={true}
                    >
                        <ListItemIcon className={classes.contentContactListIcon}>
                            <AccountCircleIcon />
                        </ListItemIcon>

                        <ListItemText
                            primaryTypographyProps={{variant: 'body2'}}
                            secondaryTypographyProps={{className: classes.contentContactListItem}}
                            primary={`${item.firstname} ${item.lastname}`}
                            secondary={
                                <Box
                                    component="span"
                                    className={classes.contentContactListItemExtraInfos}
                                >
                                    {item.service &&
                                        <Typography component="span" variant="body2">
                                            {item.service}
                                        </Typography>
                                    }

                                    {item.phone &&
                                        <Typography component="span" variant="body2">
                                            {toStrPhoneNumber(item.phone, PHONE_SEPARATOR)}
                                        </Typography>
                                    }
                                </Box>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
