import React from 'react';
import ReactDOM from 'react-dom';

import {
    useEffect,
} from 'react';

import {
    BrowserRouter,
} from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';

import theme from 'PathWWW/components/Theme.jsx';
import { App } from 'PathWWW/components/App.jsx';


const Main = () => {

    useEffect(() => {
        // Utilisé par Material UI pour le SSR
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    );
}

ReactDOM.hydrate(
    <Main />,
    document.getElementById('www-body-app')
);
