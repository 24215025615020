import React from 'react';

import {
    Link as RouterLink,
} from "react-router-dom";

import {
    Box,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import LanguageIcon from '@material-ui/icons/Language';
import LinkIcon from '@material-ui/icons/Link';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';





//
// Style propre au composant
//
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentDirectLinkRoot: style => ({
        ...style.contentDirectLinkRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentDirectLinkRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentDirectLinkRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentDirectLinkRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentDirectLinkRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentDirectLinkRoot.xl,
        },
    }),

    contentDirectLinkTitle: style => ({
        ...style.contentDirectLinkTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentDirectLinkTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentDirectLinkTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentDirectLinkTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentDirectLinkTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentDirectLinkTitle.xl,
        },
    }),

    contentDirectLinkList: style => ({
        ...style.contentDirectLinkList.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentDirectLinkList.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentDirectLinkList.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentDirectLinkList.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentDirectLinkList.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentDirectLinkList.xl,
        },
    }),

    contentDirectLinkListItem: style => ({
        ...style.contentDirectLinkListItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentDirectLinkListItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentDirectLinkListItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentDirectLinkListItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentDirectLinkListItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentDirectLinkListItem.xl,
        },
    }),

    contentDirectLinkListItemIcon: style => ({
        ...style.contentDirectLinkListItemIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentDirectLinkListItemIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentDirectLinkListItemIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentDirectLinkListItemIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentDirectLinkListItemIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentDirectLinkListItemIcon.xl,
        },
    }),

}));



//
// Fonction permettant de générer un composant représentant :
// - soit un lien interne, via le composant <Link> de 'react-router-dom'
// - soit un lien externe, via le composant <Link> de 'Material-ui'
//
const generateLink = (item, index, classes) => {
    // On est sur quel type de lien ?
    if(item.kind_name === "url") {

        // Lien externe : on utilise une balise <a href="..."></a>
        return (
            <ListItem
                key={index}
                className={classes.contentDirectLinkListItem}
                button
                component={Link}
                href={item.link}
                target="_blank"
                rel="noopener"
                disableGutters={true}
            >
                <ListItemIcon className={classes.contentDirectLinkListItemIcon}>
                    <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary={item.label} primaryTypographyProps={{variant: 'body2'}} />
            </ListItem>
        );

    } else {

        // Lien interne : on utilise une balise <Link></Link>
        return (
             <ListItem
                key={index}
                button
                component={RouterLink}
                to={{ pathname: item.link }}
                target={
                    (item.kind_name === "section" || item.kind_name === "article")
                    ? "_self"
                    : "_blank"
                }
                disableGutters={true}
            >
                <ListItemIcon className={classes.contentDirectLinkListItemIcon}>
                    {item.kind_name === "section" && <LinkIcon />}
                    {item.kind_name === "article" && <DescriptionIcon />}
                    {item.kind_name === "resource_document" && <PictureAsPdfIcon />}
                    {item.kind_name === "resource_photo" && <ImageIcon />}
                </ListItemIcon>
                <ListItemText primary={item.label} primaryTypographyProps={{variant: 'body2'}} />
            </ListItem>
        );
    }
}



//
// Composant représentant un contenu de type 'Accès rapide'
//
export const ContentDirectLink = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentDirectLinkRoot: {
                    common: {
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentDirectLinkTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentDirectLinkList: {
                    common: {
                        padding: theme.spacing(2),
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        borderRadius: theme.spacing(0.5),
                        backgroundColor: '#FFF',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentDirectLinkListItem: {
                    common: {
                        color: 'unset',
                        '&:hover': {
                            color: 'unset',
                            textDecoration: 'none',
                        },
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentDirectLinkListItemIcon: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // S'il n'y a pas (ou plus) d'accès rapide, alors on n'affiche rien
    if(data.lst_direct_links.length < 1) {
        return null;
    }

    return (
        <Box
            className={classes.contentDirectLinkRoot}
        >
            {title &&
                <Typography
                    className={classes.contentDirectLinkTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <List
                className={classes.contentDirectLinkList}
                dense={false}
                disablePadding={true}
                aria-label="Menu des accès rapides"
            >
                {data.lst_direct_links.map((item, index) => (
                    item.kind_name !== "undefined" &&
                        generateLink(item, index, classes)
                ))}
            </List>
        </Box>
    );
}
