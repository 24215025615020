import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    newsPreviewCard: {
        height: '100%',
    },
    newsPreviewCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    newsPreviewCardMedia: {
        width: '100%',
        height: '70%',
        objectFit: 'contain',
    },
    newsPreviewCardContent: {
        width: '100%',
        height: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    newsPreviewCardContentTitle: {
        fontSize: '1.2rem',
        textAlign: 'center',
        fontWeight: 'bold',
    },
}));





//
// Composant représentant une actualité présentée sous
// forme d'une carte.
//
export const ContentNewsSlide = ({ news, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////


    return (
        <Card className={classes.newsPreviewCard}>
            <CardActionArea
                className={classes.newsPreviewCardActionArea}
                component={Link}
                to={{ pathname: news.url_detail }}
            >
                {news.img_header.src &&
                    <CardMedia
                        className={classes.newsPreviewCardMedia}
                        component="img"
                        src={news.img_header.src}
                        srcSet={news.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${news.img_header.legend} - ${news.img_header.credits}`}
                        alt={news.img_header.alt}
                    />
                }
                <CardContent
                    className={classes.newsPreviewCardContent}
                    component="span"
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.newsPreviewCardContentTitle}
                    >
                        {news.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
