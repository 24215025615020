import React from 'react';

import {
    useState,
    useEffect,
} from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';


/////////////////////////////////////////////////////////
//                                                     //
//          Configuration OpenStreetMap (OSM)          //
//                                                     //
/////////////////////////////////////////////////////////

export const OSM_MAP_NAME = "OpenStreetMap_France";

export const OSM_MARKER_ICON_RETINA_URL = "/static/img/leaflet/dist/images/marker-icon-2x.png";
export const OSM_MARKER_ICON_URL = "/static/img/leaflet/dist/images/marker-icon.png";
export const OSM_MARKER_SHADOW_URL = "/static/img/leaflet/dist/images/marker-shadow.png";





// Liste des serveurs pouvant être utilisés pour le rendu des tuiles
const LST_OSM_MAPS = {

    OpenStreetMap_Mapnik: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        params: {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
    },

    OpenStreetMap_France: {
        url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
        params: {
            attribution: '&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
    },

    OpenTopoMap: {
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        params: {
            attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        }
    },

    CyclOSM: {
        url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
        params: {
            attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
    },

    Esri_WorldImagery: {
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        params: {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
    },

    GeoportailFrance_orthos: {
        url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
        params: {
            attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
            apikey: 'choisirgeoportail',
            format: 'image/jpeg',
            style: 'normal'
        }
    },

};





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    root: {
        height: '40vh',
        marginBottom: theme.spacing(2),

        '& .leaflet-container': {
            height: '100%',
        }
    },
}));



// Fonction permettant de configurer les "pop-up" associés à chaque lieu
function map_onEachFeatureLieu(feature, layer) {

    // Est-ce qu'une 'pop-up' a été définie pour cet 'feature' ?
    if(feature.properties.popupContent) {
        // OUI : on crée cette 'pop-up'
        layer.bindPopup(feature.properties.popupContent);

        // Doit-on l'afficher au clic ?
        if(feature.properties.showPopupContentOnClick) {
            // OUI
            layer.on({
                onclick: function(e) { e.target.openPopup(); },
            });
        } else {
            // NON : on l'affiche au survol
            layer.on({
                mouseover: function(e) { e.target.openPopup(); },
                mouseout: function(e) { e.target.closePopup(); },
            });
        }
    }
}



// Fonction utilisée pour dessiner les contours d'une commune
function map_style(feature) {
    return {
        weight: 1,
        color: "#ff0000",
        opacity: 0.6,
        fillColor: "#ff0000",
        fillOpacity: 0.2
    };
}



//
// Composant permettant d'afficher une carte OpenStreetMap
//
export function OSMMap(props) {

    // On récupère les propriétés...
    const { center, zoom, markers, outline, gestureHandling } = props;



    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Variable contenant les bibliothèques 'leaflet' et 'leaflet-gesture-handling'
    // ATTENTION : 'leaflet' ne supporte pas (encore ?) le SSR. Du coup, ici, on charge
    // dynamiquement les bibliothèques dans un 'useState' via un 'useEffect' pour s'assurer
    // que l'on est partie 'client'
    const [leafletLibs, setLeafletLibs] = useState({
        L: null,
        gestureHandlingComponent: null,
    });

    // Donnée représentant la carte OSM, ainsi que les deux calques (marqueurs et contours) qu'elles contient
    const [currentMap, setCurrentMap] = useState({
        map: null,
        markerLayer: null,
        outlineLayer: null
    });



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lors du chargement de la page
    useEffect(() => {

        // On est côté 'client' : on peut initialiser les bibliothèques
        const L = require('leaflet');
        const GestureHandling = require('leaflet-gesture-handling');
        require('leaflet/dist/leaflet.css');
        require('leaflet-gesture-handling/dist/leaflet-gesture-handling.css');

        // On modifie le chemin par défaut des images des marqueurs
        // pour le faire pointer vers le répertoire 'static'
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: OSM_MARKER_ICON_RETINA_URL,
            iconUrl: OSM_MARKER_ICON_URL,
            shadowUrl: OSM_MARKER_SHADOW_URL,
        });

        // On sauvegarde les bibliothèques dans une variable pour pouvoir s'en servir ultérieurement
        setLeafletLibs({
            L: L,
            gestureHandlingComponent: GestureHandling,
        });

    }, []);


    // Hook appelé lorsque les bibliothèques ont été chargées
    useEffect(() => {

        // Est-ce que le chargement s'est bien passé ?
        if(leafletLibs && leafletLibs.L) {

            // OUI...

            // On attache 'leaflet-gesture-handling' à 'leaflet'
            if(leafletLibs.gestureHandlingComponent) {
                leafletLibs.L.Map.addInitHook("addHandler", "gestureHandling", leafletLibs.GestureHandling);
            }

            // Initialisation de la carte
            var customMap = leafletLibs.L.map("id-osm-map", {
                zoom: zoom === "auto" ? 10 : zoom,
                gestureHandling: true
            });

            // Affichage de la carte
            leafletLibs.L.tileLayer(
                LST_OSM_MAPS[OSM_MAP_NAME].url,
                LST_OSM_MAPS[OSM_MAP_NAME].params
            ).addTo(customMap);

            // On crée le claque qui sera utilisé pour stocker les marqueurs
            var markerLayer = leafletLibs.L.featureGroup();
            customMap.addLayer(markerLayer);

            // On crée le claque qui sera utilisé pour stocker les contours
            var outlineLayer = leafletLibs.L.featureGroup();
            customMap.addLayer(outlineLayer);


            // On sauvegarde la carte, ainsi que les références vers les deux calques
            setCurrentMap({
                map: customMap,
                markerLayer: markerLayer,
                outlineLayer: outlineLayer,
            });
        }

    }, [leafletLibs]);


    // Hook appelé lorque la 'map' a changé (lors de son initialisation par exemple)
    // ou que le centre a été modifié
    useEffect(() => {

        // Est-ce que tout a été correctement initialisé ?
        if(leafletLibs && leafletLibs.L && currentMap && currentMap.map && center) {
            // OUI : on centre la carte sur le centre de la commune
            currentMap.map.setView(new leafletLibs.L.LatLng(center.coordinates[1], center.coordinates[0]));
        }

    }, [currentMap, center]);


    // Hook appelé lorque la 'map' a changé (lors de son initialisation par exemple)
    // ou que le contour a été modifié
    useEffect(() => {

        // Si le contour a été passée en paramètre, on le dessine
        if(leafletLibs && leafletLibs.L && currentMap && currentMap.map && outline) {

            // On supprime d'éventuels contours pouvant être présent dans le calque
            currentMap.outlineLayer.clearLayers();

            // Chargement des données GeoJSON dans la carte, avec
            // mise en place du 'style' et des 'callbacks'
            const geoOutline = leafletLibs.L.geoJSON(outline, {
                style: map_style,
            }).addTo(currentMap.outlineLayer);

            // Si le zoom est en mode 'auto' alors on zoom pour
            // que tout le contour de la commune soit visible
            if(zoom === 'auto') {
                currentMap.map.fitBounds(geoOutline.getBounds());
            }

        } else {
            // On supprime les anciens contours
            currentMap && currentMap.outlineLayer && currentMap.outlineLayer.clearLayers();
        }

    }, [currentMap, outline]);


    // Hook appelé lorque la 'map' a changé (lors de son initialisation par exemple)
    // ou que la liste des marqueurs a été modifiée
    useEffect(() => {

        // Si des 'markers' ont été passées en paramètre, on place un marqueur sur chaque donnée
        if(leafletLibs && leafletLibs.L && currentMap && currentMap.map && markers) {

            // On supprime d'éventuels marqueurs pouvant être présent dans le calque
            currentMap.markerLayer.clearLayers();

            // Chargement des données GeoJSON dans le calque, avec
            // mise en place du 'style' et des 'callbacks'
            leafletLibs.L.geoJSON(markers, {
                onEachFeature: map_onEachFeatureLieu
            }).addTo(currentMap.markerLayer);

        } else {
            // On supprime les anciens marqueurs
            currentMap && currentMap.markerLayer && currentMap.markerLayer.clearLayers();
        }

    }, [currentMap, markers]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.root}
        >
            <div id="id-osm-map" style={{height: '100%'}}></div>
        </Box>
    );
}
