import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = makeStyles((theme) => ({
    contentTourinsoftArticlePreviewCard: {
        height: '100%',
    },
    contentTourinsoftArticlePreviewCardActionArea: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    contentTourinsoftArticlePreviewCardMedia: {
        height: '20vh',
    },
    contentTourinsoftArticlePreviewCardContentCategories: {
        fontStyle: 'italic',
    },
    contentTourinsoftArticlePreviewCardContentTypes: {
        fontStyle: 'italic',
    },
    contentTourinsoftArticlePreviewCardContentActivite: {
        fontStyle: 'italic',
    },
    contentTourinsoftArticlePreviewCardContentName: {
        fontSize: '1.3rem',
    },
    contentTourinsoftArticlePreviewCardContentDates: {
        fontSize: '0.9rem',
    }
}));





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', en mode 'preview'
// c'est-à-dire à afficher dans une liste d'articles
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticlePreview = ({data, ...props}) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.contentTourinsoftArticlePreviewCard}>
            <CardActionArea
                className={classes.contentTourinsoftArticlePreviewCardActionArea}
                component={Link}
                to={data.url_detail}
            >
                <CardMedia
                    className={classes.contentTourinsoftArticlePreviewCardMedia}
                    component="img"
                    src={data.img.src}
                    srcSet={data.img.srcset}
                    sizes="50vw"
                    title={`${data.img.legend} - ${data.img.credits}`}
                    alt={data.img.alt}
                />

                <CardContent
                    component="span"
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.contentTourinsoftArticlePreviewCardContentName}
                    >
                        {data.name}
                    </Typography>

                    {data.lst_dates &&
                        <Typography
                            className={classes.contentTourinsoftArticlePreviewCardContentDates}
                            component="p"
                        >
                            {data.lstDates.map((dt) => (
                                dt[0] && dt[1] && dt[0] !== dt[1] ? "Du " + dt[0] + " au " + dt[1] : "Le " + dt[0]
                            ))}
                        </Typography>
                    }

                    <Typography variant="body2" color="textSecondary" component="p">
                        {data.city_name}
                    </Typography>

                    {data.activite &&
                        <Typography
                            className={classes.contentTourinsoftArticlePreviewCardContentActivite}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {data.activite}
                        </Typography>
                    }

                    {data.lst_categories &&
                        <Typography
                            className={classes.contentTourinsoftArticlePreviewCardContentCategories}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {data.lst_categories.map((item) => item.libelle).join(', ')}
                        </Typography>
                    }

                    {data.lst_types &&
                        <Typography
                            className={classes.contentTourinsoftArticlePreviewCardContentTypes}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {data.lst_types.map((item) => item.libelle).join(', ')}
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
