import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    happeningPreviewCard: {
        height: '100%',
    },
    happeningPreviewCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    happeningPreviewCardMedia: {
        width: '100%',
        height: '70%',
        objectFit: 'contain',
    },
    happeningPreviewCardContent: {
        width: '100%',
        height: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    happeningPreviewCardContentTitle: {
        fontSize: '1.2rem',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    happeningPreviewCardContentPlaceDate: {
        fontSize: '0.9rem',
    },
}));





//
// Composant représentant une manifestation présentée sous
// forme d'une carte.
//
export const ContentHappeningSlide = ({ happening, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mise en forme des dates
    let str_date_publication_start = happening.date_publication_start && date_fns_format(parseISO(happening.date_publication_start), 'dd/MM/yyyy', {locale: fr});
    let str_date_start = happening.date_start && date_fns_format(parseISO(happening.date_start), 'EEEE dd/MM/yyyy', {locale: fr});
    let str_date_end = happening.date_end && date_fns_format(parseISO(happening.date_end), 'EEEE dd/MM/yyyy', {locale: fr});
    let str_date_happening = "";
    if(str_date_start) {
        if(str_date_end) {
            if(str_date_start === str_date_end) {
                str_date_happening = `Le ${str_date_start}`;
            } else {
                str_date_happening = `Du ${str_date_start} au ${str_date_end}`;
            }
        } else {
            str_date_happening = `À partir du ${str_date_start}`;
        }
    } else {
        str_date_happening = "Date de l'événement non précisé"
    }

    return (
        <Card className={classes.happeningPreviewCard}>
            <CardActionArea
                className={classes.happeningPreviewCardActionArea}
                component={Link}
                to={{ pathname: happening.url_detail }}
            >
                {happening.img_header.src &&
                    <CardMedia
                        className={classes.happeningPreviewCardMedia}
                        component="img"
                        src={happening.img_header.src}
                        srcSet={happening.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${happening.img_header.legend} - ${happening.img_header.credits}`}
                        alt={happening.img_header.alt}
                    />
                }
                <CardContent
                    className={classes.happeningPreviewCardContent}
                    component="span"
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.happeningPreviewCardContentTitle}
                    >
                        {happening.title}
                    </Typography>

                    <Typography
                        component="span"
                        className={classes.happeningPreviewCardContentPlaceDate}
                    >
                        {happening.city_name} - {str_date_happening}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
