import React from 'react';

import {
    NavLink,
} from "react-router-dom";

import {
    Box,
    Container,
    Divider,
    List,
    ListItem,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';


import {
    MENU_ICON_MAPPING,
} from 'PathWWW/components/WWWConfig.jsx';





//
// Style propre aux composants 'MenuItemN1' et 'MenuItemN2'
//
const useMenuItemStyles = makeStyles((theme) => ({
    menuItemLi: {
        marginLeft: '0.1rem',
        marginRight: '0.1rem',
        padding: 0,  // Pas de padding pour que toute la zone soit cliquable via le <a></a>
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0px',
        '&:hover': {
            backgroundColor: props => theme.menu.colors[props.menuColor],
            '& $menuItemIcon, $menuItemLabelN1, $menuItemLabelN2': {
                color: 'white'
            },
        },
    },
    menuItemLink: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
    },
    menuItemActive: {
        backgroundColor: props => theme.menu.colors[props.menuColor],
        '& $menuItemIcon, $menuItemLabelN1, $menuItemLabelN2': {
            color: 'white'
        },
    },
    menuItemBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuItemIcon: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 'auto',
        height: '2.3rem',
        color: props => theme.menu.colors[props.menuColor],
    },
    menuItemLabelN1: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        fontSize: '0.9rem',
        fontWeight: 'normal',
        color: props => theme.menu.colors[props.menuColor],
    },
    menuItemLabelN2: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '0.9rem',
        color: props => theme.menu.colors[props.menuColor],
    },
}));



//
// Composant représentant un item (de niveau 1) du menu principal du site.
//
const MenuItemN1 = ({ menuLabel, menuIcon, menuColor, url }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useMenuItemStyles({
        menuColor: menuColor,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On récupère dynamiquement le composant React contenant l'icone associé au menu courant
    const CurrentIcon = MENU_ICON_MAPPING[menuIcon];

    return (
        <ListItem
            className={classes.menuItemLi}
        >
            <NavLink
                className={classes.menuItemLink}
                to={url}
                activeClassName={classes.menuItemActive}
            >
                <Box
                    className={classes.menuItemBox}
                >
                    <CurrentIcon
                        className={classes.menuItemIcon}
                    />

                    <Typography
                        className={classes.menuItemLabelN1}
                    >
                        { menuLabel }
                    </Typography>
                </Box>
            </NavLink>
        </ListItem>
    );
}





//
// Composant représentant un item (de niveau 2) du menu principal du site.
//
const MenuItemN2 = ({ menuLabel, menuColor, url }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useMenuItemStyles({
        menuColor: menuColor,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <ListItem
            className={classes.menuItemLi}
        >
            <NavLink
                className={classes.menuItemLink}
                to={url}
                activeClassName={classes.menuItemActive}
            >
                <Box
                    className={classes.menuItemBox}
                >
                    <Typography
                        className={classes.menuItemLabelN2}
                    >
                        { menuLabel }
                    </Typography>
                </Box>
            </NavLink>
        </ListItem>
    );
}





//
// Style propre au composant 'Menu'
//
const useMenuStyles = makeStyles((theme) => ({
    menuRoot: {
        backgroundColor: 'rgb(250, 250, 250)',
    },
    menuUl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
}));



//
// Composant représentant le menu principal du site. Il contient notamment
// les liens vers les différentes pages, ainsi que le menu actuellement sélectionné.
//
export const Menu = ({ menu }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useMenuStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        /* Zone prennant toute la largeur de la page (utilisée pour la couleur de fond) */
        <Box
            className={classes.menuRoot}
        >
            {/* Zone utile, c'est-à-dire avec des marges sur les écrans larges */}
            <Container
                maxWidth="lg"
            >
                {/* Menu de niveau 1 : toujours visible */}
                <Box
                    component='nav'
                >
                    <List
                        className={classes.menuUl}
                    >
                        {menu.n1.map((item) => (
                            <MenuItemN1
                                key={item.id}
                                menuLabel={item.label}
                                menuIcon={item.icon}
                                menuColor={item.color}
                                url={item.url}
                            />
                        ))}
                    </List>
                </Box>

                {/* Menu de niveau 2 : visible uniquement si un menu N1 est actif */}
                {menu.n2.length > 0 &&
                    <>
                        <Divider />
                        <Box
                            component='nav'
                        >
                            <List
                                className={classes.menuUl}
                            >
                                {menu.n2.map((item) => (
                                    <MenuItemN2
                                        key={item.id}
                                        menuLabel={item.label}
                                        menuIcon={item.icon}
                                        menuColor={item.color}
                                        url={item.url}
                                    />
                                ))}
                            </List>
                        </Box>
                    </>
                }

            </Container>
        </Box>
    );
}
