import React from 'react';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';

// Bibliothèque 'formik', utilisée pour la gestion des formulaires
import {
    useField,
} from 'formik';





//
// Composant utilisé pour wrapper un 'CheckboxField' de 'Material-UI' dans un formulaire 'formik'
//
export const FormikCheckboxField = (props) => {

    // ATTENTION : pour le moment, on ne passe pas par le composant <Field ... component={FormikTextField} />
    // mais directement depuis <FormikTextField ... /> avec le hook 'useField' car dans le premier cas,
    // 'meta' n'est pas contenu dans les propiétés renvoyées par '<Field />'. Du coup, il est plus complexe
    // dans ce premier cas de récupérer les valeurs de 'touched' et 'error' pour chaque champ


    // On construit le champ en fonction de 'props'
    // field : name, onBlur, onChange, value
    // meta : error, initialError, initialTouched, initialValue, touched, value
    // helpers : setError, setTouched, setValue
    const [field, meta, helpers] = useField(props);

    // On affiche le résultat via un 'Checkbox' de Material-UI
    return (
        <FormControl
            error={meta.touched && Boolean(meta.error)}
            component="fieldset"
            {...props}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        id={field.name}
                        name={field.name}
                        checked={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                    />
                }
                label={props.label}
            />
            {meta.touched && meta.error &&
                <FormHelperText>{meta.error}</FormHelperText>
            }
        </FormControl>
    );
};
