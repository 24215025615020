import React from 'react';

import {
    Box,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExploreIcon from '@material-ui/icons/Explore';


import { TabPanel } from "PathCommon/tools/TabPanel.jsx";
import { toStrPhoneNumber } from "PathCommon/tools/utils.jsx";

import {
    Slideshow,
    SlideshowContent,
    SlideshowSlide,
} from "PathCommon/tools/Slideshow.jsx";

import {
    PHONE_SEPARATOR,
} from "PathWWW/components/WWWConfig.jsx";






//
// Convertit une durée (exprimée en minutes) en une chaîne de caractères
//
function int_to_hour(duree) {
    var hours = Math.trunc(duree / 60);
    var minutes = duree % 60;

    var str_hours = "";
    if(hours > 0) {
        str_hours = hours + " heure" + (hours >= 2 ? "s" : "");
    }

    var str_minutes = "";
    if(minutes > 0) {
        str_minutes = minutes + " minute" + (minutes >= 2 ? "s" : "");
    }

    return str_hours + " " + str_minutes;
}





//////////////////////////////////////////////////////
//                                                  //
//          Composant Tourinsoft 'ItemNom'          //
//                                                  //
//////////////////////////////////////////////////////

const useItemNomStyles = makeStyles((theme) => ({
    itemNom: {
        color: (props) => theme.menu.colors[props.content_color],
        borderColor: (props) => theme.menu.colors[props.content_color],
    },
}));


export const ItemNom = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useItemNomStyles({
        content_color: data.content_color,
    });


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.nom) { return null; }

    return (
        <Typography
            className={classes.itemNom}
            component="h1"
            variant="h1"
        >
            {data.article.nom}
        </Typography>
    );
}





///////////////////////////////////////////////////////
//                                                   //
//          Composant Tourinsoft 'LstTypes'          //
//                                                   //
///////////////////////////////////////////////////////

const useLstTypesStyles = makeStyles((theme) => ({
    lstTypes: {
        fontSize: '0.9rem',
        fontStyle: 'italic',
        marginBottom: theme.spacing(2),
    },
}));


export const LstTypes = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstTypesStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.lst_types) { return null; }

    return (
        <Typography
            className={classes.lstTypes}
        >
            {data.article.lst_types.join(", ")}
        </Typography>
    );
}






////////////////////////////////////////////////////////
//                                                    //
//          Composant Tourinsoft 'LstLabels'          //
//                                                    //
////////////////////////////////////////////////////////

const useLstLabelsStyles = makeStyles((theme) => ({
    lstLabels: {
        fontSize: '0.9rem',
        fontStyle: 'normal',
        marginBottom: theme.spacing(2),
    },
}));


export const LstLabels = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstLabelsStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Typography
            className={classes.lstLabels}
        >
            Label(s) : {(data.article.lst_labels && data.article.lst_labels.length > 0) ? data.article.lst_labels.join(", ") : "-"}
        </Typography>
    );
}





////////////////////////////////////////////////////////////
//                                                        //
//          Composant Tourinsoft 'LstCategories'          //
//                                                        //
////////////////////////////////////////////////////////////

const useLstCategoriesStyles = makeStyles((theme) => ({
    lstCategories: {
        fontSize: '0.9rem',
        fontStyle: 'italic',
        marginBottom: theme.spacing(2),
    },
}));


export const LstCategories = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstCategoriesStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.lst_categories) { return null; }

    return (
        <Typography
            className={classes.lstCategories}
        >
            {data.article.lst_categories.join(", ")}
        </Typography>
    );
}





///////////////////////////////////////////////////////////////
//                                                           //
//          Composant Tourinsoft 'CategorieEtoiles'          //
//                                                           //
///////////////////////////////////////////////////////////////

const useCategorieEtoilesStyles = makeStyles((theme) => ({
    categorieEtoiles: {
        fontSize: '0.9rem',
        fontStyle: 'normal',
        marginBottom: theme.spacing(2),
    },
}));


export const CategorieEtoiles = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useCategorieEtoilesStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Typography
            className={classes.categorieEtoiles}
        >
            Nombre d'étoiles : {data.article.categorie_etoiles || "Non communiqué"}
        </Typography>
    );
}





//////////////////////////////////////////////////////////
//                                                      //
//          Composant Tourinsoft 'Description'          //
//                                                      //
//////////////////////////////////////////////////////////

const useDescriptionStyles = makeStyles((theme) => ({
    description: {
        fontSize: '1rem',
        textAlign: 'justify',
        marginTop: theme.spacing(2),
    },
}));


export const Description = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDescriptionStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.description) { return null; }

    return (
        <Typography
            className={classes.description}
        >
            {data.article.description}
        </Typography>
    );
}





////////////////////////////////////////////////////////
//                                                    //
//          Composant Tourinsoft 'LstPhotos'          //
//                                                    //
////////////////////////////////////////////////////////

const useLstPhotosStyles = makeStyles((theme) => ({
    noImgTourinsoft: {
        minHeight: '10vh',
        fontStyle: 'italic',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgTourinsoftSliderBox: {
        display: 'flex',
    },
    imgTourinsoftSlide: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5%',
        marginRight: '5%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            marginRight: '0px',
        }
    },
    imgTourinsoft: {
        width: '100%',
        height: '50vh',
        margin: '5px',
        objectFit: 'contain',
    },
}));


export const LstPhotos = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstPhotosStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.lst_photos
    || (data.article.lst_photos && data.article.lst_photos.length == 0)) {

        // lst_photos == null ou lst_photos == [] : pas de photos à afficher
        return (
            <Typography
                component="span"
                variant="body1"
                className={classes.noImgTourinsoft}
            >
                Aucune photo disponible
            </Typography>
        );

    } else {

        // Il y a au moins une photo à afficher
        return (
            <>
                {data.article.lst_photos.length == 1 ?
                    <img
                        className={classes.imgTourinsoft}
                        src={data.article.lst_photos[0].src}
                        srcSet={data.article.lst_photos[0].srcset}
                        sizes="50vw"
                        title={`${data.article.lst_photos[0].legend} - ${data.article.lst_photos[0].credits}`}
                        alt={data.article.lst_photos[0].alt}
                    />
                :
                    <Box
                        className={classes.imgTourinsoftSliderBox}
                    >
                        <Slideshow
                            name="idSlideshowImagesTourinsoft"
                            nbVisibleSlides={1}
                            firstSlide={1}
                            autoPlay={false}
                            transitionDuration={1}
                        >
                            <SlideshowContent>
                                {data.article.lst_photos.map((photo, index) =>
                                    <SlideshowSlide
                                        key={index}
                                        className={classes.imgTourinsoftSlide}
                                    >
                                        <img
                                            className={classes.imgTourinsoft}
                                            src={photo.src}
                                            srcSet={photo.srcset}
                                            sizes="50vw"
                                            title={`${photo.legend} - ${photo.credits}`}
                                            alt={photo.alt}
                                        />
                                    </SlideshowSlide>
                                )}
                            </SlideshowContent>
                        </Slideshow>
                    </Box>
                }
            </>
        );

    }
}




//////////////////////////////////////////////////////////////////////////////////
//                                                                              //
//          Composant Tourinsoft 'Adresse, contact et réseaux sociaux'          //
//                                                                              //
//////////////////////////////////////////////////////////////////////////////////

const useAdresseContactRSStyles = makeStyles((theme) => ({
}));


export const AdresseContactRS = ({ data, displayAddress }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useAdresseContactRSStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <List disablePadding>
            {data.article.adresse1 && displayAddress &&
                <ListItem>
                    <ListItemIcon>
                        <RoomIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`${data.article.adresse1} ${data.article.adresse2 ? " - " + data.article.adresse2 : ""}`}
                        secondary={`${data.article.code_postal} ${data.article.commune_nom}`}
                    />
                </ListItem>
            }

            {data.article.telephone1 &&
                <ListItem>
                    <ListItemIcon>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`${toStrPhoneNumber(data.article.telephone1, PHONE_SEPARATOR)} ${data.article.telephone2 ? " / " + toStrPhoneNumber(data.article.telephone2, PHONE_SEPARATOR) : ""}`}
                    />
                </ListItem>
            }

            {data.article.mail &&
                <ListItem
                    component={Link}
                    href={`mailto:${data.article.mail}`}
                >
                    <ListItemIcon>
                        <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Envoyer un courrier électronique" />
                </ListItem>
            }

            {data.article.site_web &&
                <ListItem
                    component={Link}
                    href={data.article.site_web.startsWith('http') ? data.article.site_web : "//" + data.article.site_web}
                    target="_blank"
                    rel="noopener"
                >
                    <ListItemIcon>
                        <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consulter le site Internet" />
                </ListItem>
            }

            {data.article.lst_reseaux_sociaux && data.article.lst_reseaux_sociaux.map((rs, index) => (
                // 'lstReseauxSociaux' est de la forme : [ [nomRS1, urlRS1], [nomRS2, urlRS2], ...]
                // On itère donc sur la liste et pour chaque élément, on récupère le nom ( = rs[0]) et l'url ( = rs[1])
                <ListItem
                    key={index}
                    component={Link}
                    href={rs[1].startsWith('http') ? rs[1] : "//" + rs[1]}
                    target="_blank"
                    rel="noopener"
                >
                    {
                        (rs[0].toLowerCase() === "facebook" &&
                            <>
                                <ListItemIcon>
                                    <FacebookIcon />
                                </ListItemIcon>
                                <ListItemText primary="Consulter la page Facebook"/>
                            </>
                        )
                        ||
                        (rs[0].toLowerCase() === "twitter" &&
                            <>
                                <ListItemIcon>
                                    <TwitterIcon />
                                </ListItemIcon>
                                <ListItemText primary="Consulter le compte Twitter"/>
                            </>
                        )
                        ||
                        (rs[0].toLowerCase() === "linkedin" &&
                            <>
                                <ListItemIcon>
                                    <LinkedInIcon />
                                </ListItemIcon>
                                <ListItemText primary="Consulter le compte LinkedIn"/>
                            </>
                        )
                        ||
                        (rs[0].toLowerCase() === "instagram" &&
                            <>
                                <ListItemIcon>
                                    <InstagramIcon />
                                </ListItemIcon>
                                <ListItemText primary="Consulter le compte Instagram"/>
                            </>
                        )
                    }
                </ListItem>
            ))}
        </List>
    );
}





////////////////////////////////////////////////////////
//                                                    //
//          Composant Tourinsoft 'LstTarifs'          //
//                                                    //
////////////////////////////////////////////////////////

const useLstTarifsStyles = makeStyles((theme) => ({
}));


export const LstTarifs = ({ data, mode }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstTarifsStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <List disablePadding>
            {data.article.lst_tarifs && data.article.lst_tarifs.length > 0 ?
                data.article.lst_tarifs.map((tarif, index) => (
                    mode === 1 ?
                        <ListItem key={index}>
                            <ListItemText
                                primary={`${tarif[1]} ${tarif[2] ? " : " + (tarif[3] > tarif[2] ? "à partir de " : "") + tarif[2] + " €" : ""} ${tarif[4] ? " - " + tarif[4] : ""}`}
                            />
                        </ListItem>
                    :
                        <ListItem key={index}>
                            <ListItemText
                                primary={`Du ${tarif[6]} au ${tarif[7]}`}
                                secondary={`${tarif[0]} : ${tarif[2] > tarif[1] ? "De " + tarif[1] + " à " + tarif[2] + " €" : tarif[1] + " €"}`}
                            />
                        </ListItem>
                ))
            :
                <ListItem>
                    <ListItemText
                        primary="Tarifs non communiqués"
                    />
                </ListItem>
            }
        </List>
    );
}





///////////////////////////////////////////////////////////////////////////
//                                                                       //
//          Composant Tourinsoft 'ServicesEquipementsActivites'          //
//                                                                       //
///////////////////////////////////////////////////////////////////////////

const useServicesEquipementsActivitesStyles = makeStyles((theme) => ({
    description: {
        fontSize: '1rem',
        textAlign: 'justify',
        marginTop: theme.spacing(2),
    },
}));


export const ServicesEquipementsActivites = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useServicesEquipementsActivitesStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <List disablePadding>
            {data.article.lst_services &&
                <ListItem>
                    <ListItemText
                        primary="Services"
                        secondary={data.article.lst_services.length > 0 ? data.article.lst_services.join(", ") : "Non communiqués"}
                    />
                </ListItem>
            }

            {data.article.lst_equipements &&
                <ListItem>
                    <ListItemText
                        primary="Équipements"
                        secondary={data.article.lst_equipements.length > 0 ? data.article.lst_equipements.join(", ") : "Non communiqués"}
                    />
                </ListItem>
            }

            {data.article.lst_activites &&
                <ListItem>
                    <ListItemText
                        primary="Activités"
                        secondary={data.article.lst_activites.length > 0 ? data.article.lst_activites.join(", ") : "Non communiquées"}
                    />
                </ListItem>
            }
        </List>
    );
}





///////////////////////////////////////////////////////
//                                                   //
//          Composant Tourinsoft 'DateLieu'          //
//                                                   //
///////////////////////////////////////////////////////

const useDateLieuStyles = makeStyles((theme) => ({
    description: {
        fontSize: '1rem',
        textAlign: 'justify',
        marginTop: theme.spacing(2),
    },
}));


export const DateLieu = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDateLieuStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <List disablePadding>
            {data.article.lst_dates &&
                <ListItem>
                    <ListItemIcon>
                        <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Date"
                        secondary={
                            <Box component="span" width="100%" display="flex" flexDirection="column">
                                {data.article.lst_dates.map((dt, index) => (
                                    <Box key={index} component="span" width="100%">{dt[0] && dt[1] && dt[0] !== dt[1] ? "Du " + dt[0] + " au " + dt[1] : "Le " + dt[0]}</Box>
                                ))}
                            </Box>
                        }
                    />
                </ListItem>
            }

            <ListItem>
                <ListItemIcon>
                    <RoomIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Lieu"
                    secondary={
                        <Box component="span" width="100%" display="flex" flexDirection="column">
                            <Box component="span" width="100%">{data.article.lieu ? data.article.lieu : "Non communiqué"}</Box>
                            <Box component="span" width="100%">{`${data.article.adresse1} ${data.article.adresse2 ? " - " + data.article.adresse2 : ""}`}</Box>
                            <Box component="span" width="100%">{`${data.article.code_postal} ${data.article.commune_nom}`}</Box>
                        </Box>
                    }
                />
            </ListItem>
        </List>
    );
}





///////////////////////////////////////////////////////////////////
//                                                               //
//          Composant Tourinsoft 'LstOuverturesAccueil'          //
//                                                               //
///////////////////////////////////////////////////////////////////

const useLstOuverturesAccueilStyles = makeStyles((theme) => ({
}));


export const LstOuverturesAccueil = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstOuverturesAccueilStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.lst_ouverture_accueil
    || (data.article.lst_ouverture_accueil && data.article.lst_ouverture_accueil.length === 0)) { return null; }

    return (
        <>
            {data.article.lst_ouverture_accueil && data.article.lst_ouverture_accueil.length > 0 &&
                <ListItem>
                    <ListItemIcon>
                        <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Box>
                                {data.article.lst_ouverture_accueil.map((horaire, index) => (
                                    // La liste Tourinsoft est au format :
                                    // [
                                    //   ["01/01/2021", "31/05/2021", "09:00", "12:00", "13:00", "19:00", "", "", ""],
                                    //   ...
                                    //   ["01/09/2021", "31/12/2021", "09:00", "12:00", "13:00", "19:00", "", "", ""],
                                    // ]
                                    //
                                    // On itère donc sur chaque élément et on ne conserve que les deux premiers
                                    // éléments (= date de début et date de fin)
                                    <Typography key={index}>{`Du ${horaire[0]} au ${horaire[1]}`}</Typography>
                                ))}
                            </Box>
                        }
                    />
                </ListItem>
            }
        </>
    );
}





////////////////////////////////////////////////////////////
//                                                        //
//          Composant Tourinsoft 'NBCouvertsMax'          //
//                                                        //
////////////////////////////////////////////////////////////

const useNBCouvertsMaxStyles = makeStyles((theme) => ({
    nbCouvertsMax: {
        fontSize: '0.9rem',
        fontStyle: 'normal',
        marginBottom: theme.spacing(2),
    },
}));


export const NBCouvertsMax = ({ data }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useNBCouvertsMaxStyles();


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data.article.nbCouvertsMax) { return null; }

    return (
        <Typography
            className={classes.nbCouvertsMax}
        >
            Nombre de couverts : {data.article.nb_couverts_max}
        </Typography>
    );
}





const useDetailItemStyles = makeStyles((theme) => ({
    sectionPresentation: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    sectionInformations: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
}));



//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'ASC'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemASC = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstTypes data={data} />

                {data.article.lst_types &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Tarifs" />
                    <Tab label="Services et équipements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={1}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'DEG'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemDEG = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstTypes data={data} />

                <LstLabels data={data} />

                {(data.article.lst_types || data.article.lst_labels) &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Tarifs" />
                    <Tab label="Services et équipements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={1}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'FMA'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemFMA = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstCategories data={data} />

                {data.article.lst_categories &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Date et lieu" />
                    <Tab label="Tarifs" />
                    <Tab label="Contacts" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <DateLieu data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={1}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <AdresseContactRS
                        data={data}
                        displayAddress={false}
                    />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'HEB'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemHEB = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstTypes data={data} />

                <LstLabels data={data} />

                <CategorieEtoiles data={data} />

                {(data.article.lst_types || data.article.lst_labels || data.article.categorieEtoiles) &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Tarifs" />
                    <Tab label="Services et équipements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={2}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'ITI'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemITI = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstTypes data={data} />

                {data.article.lst_types &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Informations" />
                    <Tab label="Caractéristiques" />
                    <Tab label="Téléchargements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <List disablePadding>
                        {data.article.commune_depart && data.article.commune_depart !== "" &&
                            <ListItem>
                                <ListItemText
                                    primary="Départ"
                                    secondary={data.article.commune_depart}
                                />
                            </ListItem>
                        }

                        {data.article.commune_arrivee && data.article.commune_arrivee !== "" &&
                            <ListItem>
                                <ListItemText
                                    primary="Arrivée"
                                    secondary={data.article.commune_arrivee}
                                />
                            </ListItem>
                        }

                        {data.article.lst_communes_traversees && data.article.lst_communes_traversees.length > 0 &&
                            <ListItem>
                                <ListItemText
                                    primary="Communes traversées"
                                    secondary={
                                        <Box component="span" width="100%" display="flex" flexDirection="column">
                                            {data.article.lst_communes_traversees.map((commune, index) => (
                                                <Box key={index} component="span" width="100%">{commune}</Box>
                                            ))}
                                        </Box>
                                    }
                                />
                            </ListItem>
                        }

                        {data.article.distanceKM &&
                            <ListItem>
                                <ListItemText
                                    primary="Distance"
                                    secondary={`${data.article.distanceKM} km`}
                                />
                            </ListItem>
                        }

                        {data.article.duree_cyclo &&
                            <ListItem>
                                <ListItemText
                                    primary="Durée à vélo"
                                    secondary={int_to_hour(data.article.duree_cyclo)}
                                />
                            </ListItem>
                        }

                        {data.article.duree_pedestre &&
                            <ListItem>
                                <ListItemText
                                    primary="Durée à pied"
                                    secondary={int_to_hour(data.article.duree_pedestre)}
                                />
                            </ListItem>
                        }

                        {data.article.nombre_etapes &&
                            <ListItem>
                                <ListItemText
                                    primary="Nombre d'étapes"
                                    secondary={data.article.nombre_etapes}
                                />
                            </ListItem>
                        }

                        {data.article.lst_points_forts_circuits && data.article.lst_points_forts_circuits.length > 0 &&
                            <ListItem>
                                <ListItemText
                                    primary="Points forts"
                                    secondary={
                                        <Box component="span" width="100%" display="flex" flexDirection="column">
                                            {data.article.lst_points_forts_circuits.map((pointFort, index) => (
                                                <Box key={index} component="span" width="100%">{pointFort}</Box>
                                            ))}
                                        </Box>
                                    }
                                />
                            </ListItem>
                        }
                    </List>
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <List disablePadding>
                        {data.article.balisage && data.article.balisage !== "" &&
                            <ListItem>
                                <ListItemText
                                    primary="Balisage"
                                    secondary={data.article.balisage}
                                />
                            </ListItem>
                        }

                        {data.article.niveau_cyclo && data.article.niveau_cyclo !== "" &&
                            <ListItem>
                                <ListItemText
                                    primary="Niveau à vélo"
                                    secondary={data.article.niveau_cyclo}
                                />
                            </ListItem>
                        }

                        {data.article.niveau_pedestre && data.article.niveau_pedestre !== "" &&
                            <ListItem>
                                <ListItemText
                                    primary="Niveau à pied"
                                    secondary={data.article.niveau_pedestre}
                                />
                            </ListItem>
                        }

                        {(
                            (data.article.denivele_altitude_min && data.article.denivele_altitude_min !== "") ||
                            (data.article.denivele_altitude_max && data.article.denivele_altitude_max !== "") ||
                            (data.article.denivele && data.article.denivele !== "")
                        ) &&
                            <ListItem>
                                <ListItemText
                                    primary="Altitude"
                                    secondary={
                                        <Box component="span" width="100%" display="flex" flexDirection="column">
                                            <Box component="span" width="100%">{(data.article.denivele_altitude_min && data.article.denivele_altitude_min !== "") ? "Minimale : " + data.article.denivele_altitude_min + " m" : ""}</Box>
                                            <Box component="span" width="100%">{(data.article.denivele_altitude_max && data.article.denivele_altitude_max !== "") ? "Maximale : " + data.article.denivele_altitude_max + " m" : ""}</Box>
                                            <Box component="span" width="100%">{(data.article.denivele && data.article.denivele !== "") ? "Dénivelé : " + data.article.denivele + " m" : ""}</Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        }

                        {(
                            (data.article.reference_carte_IGN_1 && data.article.reference_carte_IGN_1 !== "") ||
                            (data.article.reference_carte_IGN_2 && data.article.reference_carte_IGN_2 !== "") ||
                            (data.article.reference_carte_IGN_3 && data.article.reference_carte_IGN_3 !== "") ||
                            (data.article.reference_carte_IGN_4 && data.article.reference_carte_IGN_4 !== "")
                        ) &&
                            <ListItem>
                                <ListItemText
                                    primary="Référence(s) carte IGN"
                                    secondary={
                                        <Box component="span" width="100%" display="flex" flexDirection="column">
                                            <Box component="span" width="100%">{(data.article.reference_carte_IGN_1 && data.article.reference_carte_IGN_1 !== "") ? data.article.reference_carte_IGN_1 : ""}</Box>
                                            <Box component="span" width="100%">{(data.article.reference_carte_IGN_2 && data.article.reference_carte_IGN_2 !== "") ? data.article.reference_carte_IGN_2 : ""}</Box>
                                            <Box component="span" width="100%">{(data.article.reference_carte_IGN_3 && data.article.reference_carte_IGN_3 !== "") ? data.article.reference_carte_IGN_3 : ""}</Box>
                                            <Box component="span" width="100%">{(data.article.reference_carte_IGN_4 && data.article.reference_carte_IGN_4 !== "") ? data.article.reference_carte_IGN_4 : ""}</Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        }

                    </List>
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <List disablePadding>
                        {data.article.lst_fichiers_PDF && data.article.lst_fichiers_PDF.map((fichier, index) => (
                            <ListItem
                                key={index}
                                component={Link}
                                to={{ pathname: fichier[0] }}
                                target="_blank"
                                rel="noopener"
                            >
                                <ListItemIcon>
                                    <PictureAsPdfIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={fichier[1]}
                                    secondary={fichier[2]}
                                />
                            </ListItem>
                        ))}

                        {data.article.lst_traces_GPX && data.article.lst_traces_GPX.map((fichier, index) => (
                            <ListItem
                                key={index}
                                component={Link}
                                to={{ pathname: fichier }}
                                target="_blank"
                                rel="noopener"
                            >
                                <ListItemIcon>
                                    <ExploreIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Trace GPX"
                                />
                            </ListItem>
                        ))}
                    </List>
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'ORG'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemORG = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            <Box style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                {data.article.lst_photos && data.article.lst_photos.length > 0 &&
                    <Grid item container xs={12} md={5} spacing={1}>
                        <Grid item xs={12}>
                            <img
                                style={{ width: '100%', height: '40vh', objectFit: 'contain' }}
                                src={data.article.lst_photos[0].src}
                                srcSet={data.article.lst_photos[0].srcset}
                                sizes="50vw"
                                title={`${data.article.lst_photos[0].legend} - ${data.article.lst_photos[0].credits}`}
                                alt={data.article.lst_photos[0].alt}
                            />
                        </Grid>
                    </Grid>
                }

                {/* Section 'Présentation' */}
                <Box
                    className={classes.sectionPresentation}
                    style={{ width: '100%', height: '100%' }}
                >
                    <Typography
                        style={{fontSize: '0.9rem', fontStyle: 'italic'}}
                    >
                        {data.article.lst_categories && data.article.lst_categories.join(", ")} - {data.article.activite}
                    </Typography>

                    {/*data.sigle === "MDO" ? " - Médiathèque Départementale de l'Oise" : null*/}

                    {data.article.lst_categories &&
                        <Divider />
                    }

                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />
                </Box>
            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'PCN'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemPCN = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstTypes data={data} />

                {data.article.lst_types &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Tarifs" />
                    <Tab label="Services et équipements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />

                    <LstOuverturesAccueil data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={1}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'RES'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemRES = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>

                <LstCategories data={data} />

                <LstLabels data={data} />

                <NBCouvertsMax data={data} />

                {(data.article.lst_categories || data.article.lst_labels || data.article.nb_couverts_max) &&
                    <Divider />
                }

                <Description data={data} />

            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Tarifs" />
                    <Tab label="Services et équipements" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />

                    <LstOuverturesAccueil data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <LstTarifs
                        data={data}
                        mode={1}
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}





//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft', pour le flux 'SEM'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetailItemSEM = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDetailItemStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    if(!data) { return null; }

    return (
        <>
            <ItemNom data={data} />

            {/* Section 'Présentation' */}
            <Box className={classes.sectionPresentation}>
                <LstTypes data={data} />

                {data.article.lst_types &&
                    <Divider />
                }

                <Description data={data} />
            </Box>

            {/* Section 'Informations' */}
            <Box className={classes.sectionInformations}>

                <Tabs
                    value={activeTabId}
                    onChange={handleChangeActiveTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Photos" />
                    <Tab label="Adresse et contacts" />
                    <Tab label="Capacité" />
                    <Tab label="Informations complémentaires" />
                </Tabs>

                <TabPanel value={activeTabId} index={0}>
                    <LstPhotos data={data} />
                </TabPanel>

                <TabPanel value={activeTabId} index={1}>
                    <AdresseContactRS
                        data={data}
                        displayAddress
                    />
                </TabPanel>

                <TabPanel value={activeTabId} index={2}>
                    <List disablePadding>
                        <ListItem>
                            <ListItemText
                                primary="Nombre de salles"
                                secondary={data.article.nb_salles}
                            />
                        </ListItem>

                        {data.article.nb_salles == 1 ?
                            <ListItem>
                                <ListItemText
                                    primary="Capacité"
                                    secondary={`${data.article.capacite_petite_salle} personnes`}
                                />
                            </ListItem>
                        :
                            <>
                                <ListItem>
                                    <ListItemText
                                        primary="Petite salle"
                                        secondary={`${data.article.capacite_petite_salle} personnes`}
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary="Grande salle"
                                        secondary={`${data.article.capacite_grande_salle} personnes`}
                                    />
                                </ListItem>
                            </>
                        }
                    </List>
                </TabPanel>

                <TabPanel value={activeTabId} index={3}>
                    <List disablePadding>
                        <ListItem>
                            <ListItemText
                                primary="Restauration"
                                secondary={data.article.restauration ? data.article.restauration : "Non communiquée"}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="Hébergement"
                                secondary={data.article.hebergement ? data.article.hebergement : "Non communiqué"}
                            />
                        </ListItem>
                    </List>

                    <ServicesEquipementsActivites data={data} />
                </TabPanel>

            </Box>
        </>
    );
}
