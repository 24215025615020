import React from 'react';

import {
    useState,
    useEffect,
} from 'react';

import {
    Box,
    Checkbox,
    TextField,
    Paper,
    Typography,
} from '@material-ui/core';

import {
    Autocomplete
} from '@material-ui/lab';

import {
    makeStyles,
} from '@material-ui/core/styles';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    filtresITIPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    filtresITIBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            gap: '2rem',
        },
    },
    filtresITIAutocomplete: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    filtresITINbResultsTextField: {
        width: '100%',
        marginTop: theme.spacing(4),
        fontStyle: 'italic',
    },
}));





// Méthode utilisée pour demander les données au serveur
// 'url' : url du GET permettant de récupérer les données
// 'setLstTypes' : callback utilisée pour mettre à jour les données 'types'
// 'setLstCommunes' : callback utilisée pour mettre à jour les données 'communes'
const fetchData = async (url, setLstTypes, setLstCommunes) => {
    await fetch(url)
    .then(response => response.json())
    .then(result => {
        setLstTypes(result.types)
//        setLstCommunes(result.communes)
    })
    .catch(error => {
        // TODO : notifier l'utilisateur et masquer les données...
        console.error(error);
    })
};





//
// Composant permettant de gérer les filtres associés à la page 'Randonnées'.
//
export function ContentTourinsoftFilterITI(props) {

    // On récupère les propriétés...
    const { lstData, setLstData } = props;



    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook d'état permettant de gérer les cases à cocher associées
    // au filtre du type de randonnée. La variable 'checkedTypesId'
    // est un tableau contenant la liste des IDs sélectionnés
    const [checkedTypesId, setCheckedTypesId] = useState([]);

    // Hook d'état permettant de gérer les cases à cocher associées
    // au filtre des communes. La variable 'checkedCommunesId' est
    // un tableau contenant la liste des IDs sélectionnés
    const [checkedCommunesId, setCheckedCommunesId] = useState([]);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook d'effet utilisé pour mettre à jour les données visibles lorsque
    // des filtres ont été modifiés
    useEffect(() => {
        // On met à jour la visibilité des data en fonction de la liste des IDs visibles
        const newData = [...lstData];

        // Par défaut, toutes les données sont visibles
        newData.forEach((data) => {
            data.visible = true;
        });

        // Est-ce qu'au moins une option a été sélectionnée dans le filtre 'types de randonnée' ?
        if(checkedTypesId.length > 0) {
            // OUI, seulement certains types ont été sélectionnés : on n'affiche
            // que ceux-là, c'est-à-dire ceux dont l'ID est présent dans la liste

            // Pour chaque item...
            newData.forEach((data) => {
                // On vérifie si un des ID présents dans la liste 'checkedTypesId' (= ID des filtres sélectionnés)
                // est également présent dans 'types' de l'item courant, via la méthode 'includes'
                // - la méthode '.some()' retourne true si un des ID des filtres sélectionnés est présent
                // - la méthode '.includes()' permet de regarder si l'ID du filtre courant est présent dans la liste des ID
                // de 'types' de l'item courant
                // - 'types' est une liste de dictionnaires de type [{id: 1, libelle: "Chambres d'hôtes"}, {id: 2, libelle: "Meublés et Gîtes"}...]
                // on effectue donc un '.map()' pour ne récupérer qu'une liste des ID de la forme '[1, 2...]'
                data.visible &= checkedTypesId.some((value) => data.lst_types.map((v) => v.id).includes(value));
            });
        }

/*
        // Est-ce qu'au moins une option a été sélectionnée dans le filtre 'communes' ?
        if(checkedCommunesId.length > 0) {
            // OUI, seulement certaines communes ont été sélectionnées : on n'affiche
            // que celles-là, c'est-à-dire celles dont l'ID est présent dans la liste
            newData.forEach((data) => {
                data.visible &= (checkedCommunesId.indexOf(data.communeId) !== -1)
            });
        }
*/

        // On met à jour le hook d'état...
        setLstData(newData);

    }, [checkedTypesId, checkedCommunesId]);


    // Hooks utilisés pour stocker les données associés aux filtres
    // Ces données sont récupérées depuis le serveur
    const [lstTypes, setLstTypes] = useState([]);
    const [lstCommunes, setLstCommunes] = useState([]);


    // Hook appelé lors du chargement de la page : on demande les données au serveur
    useEffect(() => {
        fetchData(
            "/api/tourinsoft/ITI/filters/",
            setLstTypes,
            setLstCommunes
        );
    }, []);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On calcule le nombre de résultats visibles, en fonction des
    // critères de tri actuellement sélectionnés
    let nbVisibles = lstData.reduce((accumulator, currentValue) => accumulator + (currentValue.visible ? 1 : 0), 0);

    return (
        <Paper className={classes.filtresITIPaper}>
            <Typography variant="h6" component="h2">
                Filtrer les randonnées
            </Typography>

            <Box className={classes.filtresITIBox}>
                {/* Filtre 'Types de randonnée' */}
                <Autocomplete
                    className={classes.filtresITIAutocomplete}
                    multiple
                    id="filtre-type-randonnee"
                    options={lstTypes}
                    freeSolo={false}
                    getOptionLabel={(option) => option.libelle}
                    onChange={(event, newValue) => {
                        setCheckedTypesId(newValue.map((item) => item.id));
                    }}
                    renderOption={(option, { selected }) => (
                        <>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.libelle}
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label="Type de randonnée" placeholder="" />
                    )}
                />


                {/* Filtre 'Communes' */}
{/*
                <Autocomplete
                    className={classes.filtresITIAutocomplete}
                    multiple
                    id="filtre-commune-randonnee"
                    options={lstCommunes}
                    freeSolo={false}
                    getOptionLabel={(option) => option.nom}
                    onChange={(event, newValue) => {
                        setCheckedCommunesId(newValue.map((item) => item.id));
                    }}
                    renderOption={(option, { selected }) => (
                        <>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.nom}
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label="Commune" placeholder="" />
                    )}
                />
*/}
            </Box>

            <Typography
                className={classes.filtresITINbResultsTextField}
            >
                {nbVisibles === 0 ?
                    "Aucun résultat trouvé"
                :
                    nbVisibles === 1 ?
                        "1 randonnée trouvée"
                    :
                        `${nbVisibles} randonnées trouvées`
                }
            </Typography>
        </Paper>
    );
}
