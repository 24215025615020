import React from 'react';

import {
    useEffect,
    useState,
} from 'react';

import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';


import { Calendar } from "PathWWW/components/Calendar.jsx";
import { OSMMap } from "PathCommon/tools/OSMMap.jsx";
import { ContentTourinsoftArticlePreview } from "PathContent/components/ContentTourinsoftArticlePreview.jsx";

import { ContentTourinsoftFilterASC } from "PathContent/components/ContentTourinsoftFilterASC.jsx";
import { ContentTourinsoftFilterDEG } from "PathContent/components/ContentTourinsoftFilterDEG.jsx";
import { ContentTourinsoftFilterHEB } from "PathContent/components/ContentTourinsoftFilterHEB.jsx";
import { ContentTourinsoftFilterITI } from "PathContent/components/ContentTourinsoftFilterITI.jsx";
import { ContentTourinsoftFilterORG_ASS } from "PathContent/components/ContentTourinsoftFilterORG_ASS.jsx";
import { ContentTourinsoftFilterPCN } from "PathContent/components/ContentTourinsoftFilterPCN.jsx";
import { ContentTourinsoftFilterRES } from "PathContent/components/ContentTourinsoftFilterRES.jsx";
import { ContentTourinsoftFilterSEM } from "PathContent/components/ContentTourinsoftFilterSEM.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentTourinsoftArticleListRoot: style => ({
        ...style.contentTourinsoftArticleListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentTourinsoftArticleListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentTourinsoftArticleListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentTourinsoftArticleListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentTourinsoftArticleListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentTourinsoftArticleListRoot.xl,
        },
    }),

    contentTourinsoftArticleListTitle: style => ({
        ...style.contentTourinsoftArticleListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentTourinsoftArticleListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentTourinsoftArticleListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentTourinsoftArticleListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentTourinsoftArticleListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentTourinsoftArticleListTitle.xl,
        },
    }),

}));



//
// Composant représentant un contenu de type 'liste des articles Tourinsoft'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleList = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // currentData : données finales prêtes à être utilisées, après application d'éventuels filtres
    const [currentData, setCurrentData] = useState(data.lst_articles);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lorsque le type de flux a changé : on ré-initialise les données
    // à partir de celles contenues dans "data.lst_articles" (envoyées par le serveur)
    useEffect(() => {
        setCurrentData(data.lst_articles)
    }, [params.flow]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentTourinsoftArticleListRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentTourinsoftArticleListTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On génère le composant contenant le filtre à utiliser en fonction du flux
    let ComponentFilter = null;
    switch(params.flow) {
        case "ASC":
            ComponentFilter = ContentTourinsoftFilterASC;
            break;

        case "DEG":
            ComponentFilter = ContentTourinsoftFilterDEG;
            break;

        case "HEB":
            ComponentFilter = ContentTourinsoftFilterHEB;
            break;

        case "ITI":
            ComponentFilter = ContentTourinsoftFilterITI;
            break;

        case "ORG_ASS":
            ComponentFilter = ContentTourinsoftFilterORG_ASS;
            break;

        case "PCN":
            ComponentFilter = ContentTourinsoftFilterPCN;
            break;

        case "RES":
            ComponentFilter = ContentTourinsoftFilterRES;
            break;

        case "SEM":
            ComponentFilter = ContentTourinsoftFilterSEM;
            break;
    }

    return (
        <Box
            className={classes.contentTourinsoftArticleListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentTourinsoftArticleListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {params.flow === "FMA" ?

                // Cas spéficique du flux "Fêtes et manifestations" : on affiche ce flux
                // sous la forme d'un calendrier
                <Calendar
                    viewMode={(params && params.calendar_view_mode) || 'auto'}
                />

            :
                <Box>
                    {data.map &&
                        <OSMMap
                            center={(params.map && params.map.center) || (data.map && data.map.center)}
                            zoom={(params.map && params.map.zoom) || (data.map && data.map.zoom) || "auto"}
                            markers={currentData.filter(item => item.visible == true).map((item) => item.marker)}
                            outline={(params.map && params.map.outline) || (data.map && data.map.outline) || null}
                            gestureHandling={(params.map && params.map.gestureHandling) || true}
                        />
                    }


                    {ComponentFilter &&
                        <ComponentFilter
                            lstData={currentData}
                            setLstData={setCurrentData}
                        />
                    }

                    <Grid
                        container
                        spacing={3}
                    >
                        {currentData.filter(item => item.visible == true).map((item, index) => (
                            <Grid
                                item
                                xs={12} sm={6} lg={4}
                                key={index}
                            >
                                <ContentTourinsoftArticlePreview
                                    data={item}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Box>
            }
        </Box>
    );
}
