import React from 'react';

import {
    useEffect,
    useState,
} from 'react';

import {
    Box,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';


import {
    Slideshow,
    SlideshowContent,
    SlideshowSlide,
} from "PathCommon/tools/Slideshow.jsx";

import { ContentHappeningSlide } from "PathContent/components/ContentHappeningSlide.jsx";
import { ContentNewsSlide } from "PathContent/components/ContentNewsSlide.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentSliderRoot: style => ({
        ...style.contentSliderRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderRoot.xl,
        },
    }),

    contentSliderTitle: style => ({
        ...style.contentSliderTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderTitle.xl,
        },
    }),

    contentSliderSlideshow: style => ({
        ...style.contentSliderSlideshow.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderSlideshow.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderSlideshow.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderSlideshow.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderSlideshow.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderSlideshow.xl,
        },
    }),
    contentSliderSlide: style => ({
        ...style.contentSliderSlide.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderSlide.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderSlide.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderSlide.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderSlide.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderSlide.xl,
        },
    }),
    contentSliderBoxOne: style => ({
        ...style.contentSliderBoxOne.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderBoxOne.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderBoxOne.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderBoxOne.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderBoxOne.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderBoxOne.xl,
        },
    }),

}));


//
// Composant permettant d'afficher un slider
// Le slider est composé de carte contenant différentes informations
//
export const ContentSlider = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On détermine le mode ('mobile' ou 'desktop')
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));


    // Nombre de slides visibles : valeur calculée par la suite
    // En effet, à cause du SSR, la valeur ne peut pas être calculée au premier rendu
    const [nbVisibleSlides, setNbVisibleSlides] = useState(isMDUp ? 2 : 1);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lorsqu'il y a un changement de mode ('desktop' ou 'mobile')
    // Utilisé également lors de l'initialisation
    useEffect(() => {
        // On calcule le nombre de slide :
        // - mode 'mobile' : 1 slide visible
        // - mode 'desktop' : 2 slides visibles
        setNbVisibleSlides(isMDUp ? 2 : 1)
    }, [isMDUp]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentSliderRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderSlideshow: {
                    common: {
                        display: 'flex',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderSlide: {
                    common: {
                        height: '15rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        marginBottom: '5px',
                    },
                    xs: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    sm: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderBoxOne: {
                    common: {
                        height: '15rem',
                        marginLeft: '20%',
                        marginRight: '20%',
                        marginBottom: '5px',
                    },
                    xs: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    sm: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Dans le cas où il n'y a pas d'informations dans le slider alors on ne l'affiche pas
    if(data.lst_items.length === 0) { return null; }

    return (
        <Box
            className={classes.contentSliderRoot}
        >
            {title &&
                <Typography
                    className={classes.contentSliderTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.lst_items.length === 1 &&
                <Box className={classes.contentSliderBoxOne}>
                    {data.lst_items[0].data_kind === "news" && <ContentNewsSlide news={data.lst_items[0]} />}
                    {data.lst_items[0].data_kind === "happening" && <ContentHappeningSlide happening={data.lst_items[0]} />}
                </Box>
            }

            {data.lst_items.length >= 2 &&
                <Slideshow
                    className={classes.contentSliderSlideshow}
                    name="idSlideshow"
                    nbVisibleSlides={nbVisibleSlides}
                    firstSlide={0}
                    autoPlay={false}
                    transitionDuration={1}
                    overlayArrows={false}
                >
                    <SlideshowContent>
                        {data.lst_items.map((item, index) => (
                            <SlideshowSlide
                                key={index}
                                className={classes.contentSliderSlide}
                            >
                                {item.data_kind === "news" && <ContentNewsSlide news={item} />}
                                {item.data_kind === "happening" && <ContentHappeningSlide happening={item} />}
                            </SlideshowSlide>
                        ))}
                    </SlideshowContent>
                </Slideshow>
            }

        </Box>
    );
}
