import {
    useState,
    useEffect,
    useReducer,
} from 'react';




/*
// Énumération utilisée par le hook 'useRequestData' pour indiquer les différents statuts
export const RequestDataStatus = Object.freeze({
    IDLE:        { id: 1, label: "idle", },
    IN_PROGRESS: { id: 2, label: "in progress", },
    SUCCESS:     { id: 3, label: "success", },
    ERROR:       { id: 4, label: "error", },
    CANCELED:    { id: 5, label: "canceled", },
});
*/


export const RequestStatus = Object.freeze({
    IDLE: 0,
    IN_PROGRESS: 1,
    SUCCESS: 2,
    ERROR: 3,
    CANCELED: 4,
});

//
// Liste des actions gérées par le 'reducer'
//
const RequestActions = Object.freeze({
    FETCH: 0,
    SUCCESS: 1,
    ERROR: 2,
    ABORT: 3,
    RESET: 4,
});


//
// Hook permettant de demander des données au serveur
//
// Paramètres :
//   - initialData : données initiales, avant que la moindre requête n'ait été exécutée
//   - mappingFunc : callback appelée sur les données avant de les retourner. Par défaut,
//                   contient la fonction identité pour ne pas modifier les données
//
export const useRequestData = (initialData=null) => {

    const controller = new AbortController();

    // Valeurs initiales
    const initialState = {
        status: RequestStatus.IDLE,
        response: null,
        options: null,
        data: initialData,
        signal: controller.signal,
    };


    const initReducer = (initialState) => {
        return initialState;
    }

/*
    // Valeurs initiales
    const initialState = {
        status: RequestDataStatus.IDLE,
        response: null,
        options: null,
        data: initialData,
        signal: controller.signal;
    };
*/

    // 'reducer' utilisé pour mettre à jour les données
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {

/*
            // Une requête a été envoyée : on attend la réponse...
            case RequestDataStatus.IDLE.id:
                return {
                    ...state,
                    status: RequestDataStatus.IDLE,
                    data: initialData,
                };

            // Une requête a été envoyée : on attend la réponse...
            case RequestDataStatus.IN_PROGRESS.id:
                return {
                    ...state,
                    status: RequestDataStatus.IN_PROGRESS,
                };

            // La réponse a été correctement reçue : on sauvegarde les données
            case RequestDataStatus.SUCCESS.id:
                return {
                    ...state,
                    status: RequestDataStatus.SUCCESS,
                    response: action.payload.response,
                    options: action.payload.options,
                    data: action.payload.body,
                };

            // Une erreur est survenue : on sauvegarde le message d'erreur
            case RequestDataStatus.ERROR.id:
                return {
                    ...state,
                    status: RequestDataStatus.ERROR,
                    response: action.payload.response,
                    options: action.payload.options,
                    data: null,
                };

            // La requête a été annulée
            case RequestDataStatus.CANCELED.id:
                return {
                    ...state,
                    status: RequestDataStatus.CANCELED,
                    response: null,
                    data: null,
                };
*/

            // Une requête vient d'être envoyée : on attend la réponse...
            case RequestActions.FETCH:
                return {
                    ...state,
                    status: RequestStatus.IN_PROGRESS,
                    response: null,
                    options: action.payload.options,
                };

            case RequestActions.SUCCESS:
                return {
                    ...state,
                    status: RequestStatus.SUCCESS,
                    response: action.payload.response,
                    data: action.payload.data,
                };

            case RequestActions.ERROR:
                return {
                    ...state,
                    status: RequestStatus.ERROR,
                    response: action.payload.response,
                    data: null,
                };

            case RequestActions.ABORT:
                return {
                    ...state,
                    status: RequestStatus.CANCELED,
                    response: null,
                    data: null,
                };

            // La requête doit être réinitialisée
            case RequestActions.RESET:
                return initReducer(initialState);

            // Dans tous les autres cas : ERREUR !
            default:
                throw new Error();
        }
    }, initialState, initReducer);


    // Méthode ASYNCHRONE utilisée pour dialoguer avec le serveur
    const fetchRequest = async (url, options=null, mapFunc=((data) => data)) => {
        // On n'interroge le serveur que si une URL a été précisée
        if(url) {
            // On ré-initialise la requête
            dispatch({type: RequestActions.RESET});

            // On met à jour le statut
            dispatch({type: RequestActions.FETCH, payload: {options: options}});

            try {
                // On demande les données et on attend la réponse du serveur
                const response = await fetch(url, {...options, signal: state.signal});

                if(response.ok) {
                    const body = await response.json();

                    // On sauvegarde les données et on indique que tout s'est bien passé
                    dispatch({type: RequestActions.SUCCESS, payload: {response: response, data: mapFunc(body)}});
                } else {
                    dispatch({type: RequestActions.SUCCESS, payload: {response: response, data: null}});
                }

            } catch(error) {
                // Une erreur est survenue : on change le statut et on sauvegarde l'erreur
                dispatch({type: RequestActions.ERROR, payload: {response: error}});
            }
        }
    };


    const abortRequest = () => {
        controller.abort();
        dispatch({
            type: RequestActions.ABORT,
        });
    }

    const resetRequest = () => {
        dispatch({
            type: RequestActions.RESET,
        });
    }

    // On retourne :
    // - les données et le status d'exécution contenus dans 'state'
    // - la méthode à utiliser pour lancer la requête
    return [state, fetchRequest, abortRequest, resetRequest];
};
