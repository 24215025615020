import React from 'react';

import {
    useRef,
    useState,
} from 'react';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';

// Bibliothèque 'formik', utilisée pour la gestion des formulaires
import {
    Formik,
    Form,
} from 'formik';

// Bibliothèque 'yup', utilisée pour la validation des formulaires
import * as Yup from 'yup';
import {
    boolean,
    string
} from 'yup';


// Hook permettant d'afficher un message dans une 'snackbar'
import {
    useUserMessages,
} from "PathCommon/tools/UserMessages.jsx"

import { FormikTextField } from "PathIntranet/components/fields/FormikTextField.jsx";
import { FormikCheckboxField } from "PathIntranet/components/fields/FormikCheckboxField.jsx";
import { FormikReCaptchaField } from "PathIntranet/components/fields/FormikReCaptchaField.jsx";

import { wwwConfig } from "./WWWConfig.jsx";





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    formulaireContact: {
        display: "flex",
        flexDirection: "column",
    },
    formulaireContactField: {
        marginBottom: theme.spacing(2),
    },
    formulaireContactCGU: {
        fontSize: "0.8rem",
    },
    formulaireContactCGUHelper: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    formulaireContactBouton: {
        marginTop: '16px',
        marginLeft: 'auto',
    },
}));



// Schéma de validation, utilisé par Formik pour valider les données du formulaire
const validationSchema = Yup.object({
    nom: Yup
        .string()
        .required("Veuillez saisir votre nom et votre prénom"),
    email: Yup
        .string()
        .email("Veuillez saisir une adresse électronique valide")
        .required("Veuillez saisir votre adresse électronique"),
    objet: Yup
        .string()
        .required("Veuillez saisir le sujet de votre demande"),
    message: Yup
        .string()
        .required("Veuillez saisir votre message"),
    cgu: Yup
        .boolean()
        .oneOf([true], "Veuillez accepter les conditions d'utilisation"),
    captcha: Yup
        .string()
        .nullable()
        .required("Veuillez valider le captcha"),
});



// Valeurs par défaut du formulaire, utilisées lors d'une création
const initialValues = {
    nom: "",
    email: "",
    objet: "",
    message: "",
    cgu: false,
    captcha: "",
};



//
// Composant utilisé pour afficher le formulaire de contact.
//
export function ContactForm(props) {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Création d'une référence vers le composant interne du 'ReCaptcha'
    // Utilisé notamment pour appeler directement la méthode 'reset' du composant interne
    const recaptchaRef = useRef();

    // Message à afficher dans la boite de dialogue
    // Doit être de la forme : {titre: "...", contenu: "..."}
    const [dlgMessage, setDlgMessage] = useState(null);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque l'utilisateur a fermé la boite de dialogue
    const handleClose = (event) => {
        // On efface le message à afficher pour ferme la boite de dialogue
        setDlgMessage(null);
    }



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange
                onSubmit={
                    async (values, actions) => {

                        let response = null;
                        let body = null;

                        try {

                            // On lance la requête contenant les différentes informations
                            response = await fetch(
                                wwwConfig.api.formulaireContact,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(values),
                                },
                            );

                        } catch(error) {

                            // Une erreur est survenue : on l'affiche !
                            setDlgMessage({
                                titre: "Une erreur est survenue !",
                                contenu: "Votre message n'a pas pu être envoyé correctement. Veuillez réessayer ultérieurement. Nous nous excusons pour la gêne occasionnée.",
                            })

                        }

                        // La requête s'est bien passée... est-ce que le serveur est content ?
                        if(response.ok) {
                            // OUI, tout s'est bien passé :

                            // - on indique que le processus de soumission est fini
                            actions.setSubmitting(false);

                            // - on nettoie le formulaire
                            actions.resetForm();

                            // - on réinitialise le captcha
                            recaptchaRef && recaptchaRef.current.reset();

                            // - on prévient l'utilisateur
                            setDlgMessage({
                                titre: "Demande envoyée",
                                contenu: "Votre demande a bien été envoyée. Nous vous répondrons dans les plus brefs délais.",
                            })

                        } else {

                            // La requête s'est bien passée mais le serveur a retourné une erreur...
                            try {

                                // On récupère le message d'erreur retourné par le serveur
                                body = await response.json();

                                // et on l'affiche
                                setDlgMessage({
                                    titre: "Une erreur est survenue !",
                                    contenu: body,
                                })

                            } catch(error) {

                                // Une erreur est survenue : on l'affiche !
                                setDlgMessage({
                                    titre: "Une erreur est survenue !",
                                    contenu: "Votre message n'a pas pu être envoyé correctement. Veuillez réessayer ultérieurement. Nous nous excusons pour la gêne occasionnée.",
                                })

                            }

                        }
                    }
                }
            >
                {({
                    dirty,
                    isSubmitting,
                    isValid,
                }) => {
                    return (
                        <Form className={classes.formulaireContact}>
                            <FormikTextField
                                id="nom"
                                name="nom"
                                label="Nom et prénom"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikTextField
                                id="email"
                                name="email"
                                label="Adresse électronique"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikTextField
                                id="objet"
                                name="objet"
                                label="Objet de la demande"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikTextField
                                id="message"
                                name="message"
                                label="Message"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                                required
                            />

                            <FormikCheckboxField
                                id="cgu"
                                name="cgu"
                                label={
                                    <Typography variant="body2">
                                        {props.labelGCU}
                                    </Typography>
                                }
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikReCaptchaField
                                id="captcha"
                                name="captcha"
                                recaptchaRef={recaptchaRef}
                                sitekey={__GOOGLE_RECAPTCHA_SITE_KEY__} // récupérée via une variable d'environnement
                                margin="normal"
                                fullWidth
                                required
                            />

                            <Button
                                className={classes.formulaireContactBouton}
                                variant="contained"
                                color="primary"
                                disabled={!isValid || (!dirty || isSubmitting)}
                                endIcon={<SendIcon />}
                                type="submit"
                            >
                                Envoyer
                            </Button>
                        </Form>
                    );
               }}
            </Formik>

            {/* Boite de dialogue permettant d'afficher un éventuel message d'erreur */}
            <Dialog
                open={Boolean(dlgMessage)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dlgMessage && dlgMessage.titre}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dlgMessage && dlgMessage.contenu}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
