import React from 'react';

import {
    Box,
    Divider,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import ScheduleIcon from '@material-ui/icons/Schedule';
import PlaceIcon from '@material-ui/icons/Place';





//
// Style propre au composant
//
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentHappeningDetailTitle: style => ({
        ...style.contentHappeningDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailTitle.xl,
        },
    }),

    contentHappeningDetailRoot: style => ({
        ...style.contentHappeningDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailRoot.xl,
        },
    }),

    contentHappeningDetailHeaderImg: style => ({
        ...style.contentHappeningDetailHeaderImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailHeaderImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailHeaderImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailHeaderImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailHeaderImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailHeaderImg.xl,
        },
    }),

    contentHappeningDetailContent: style => ({
        ...style.contentHappeningDetailContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailContent.xl,
        },
    }),

    contentHappeningDetailContentPlace: style => ({
        ...style.contentHappeningDetailContentPlace.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailContentPlace.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailContentPlace.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailContentPlace.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailContentPlace.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailContentPlace.xl,
        },
    }),

    contentHappeningDetailContentDate: style => ({
        ...style.contentHappeningDetailContentDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailContentDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailContentDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailContentDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailContentDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailContentDate.xl,
        },
    }),

    contentHappeningDetailContentIcon: style => ({
        ...style.contentHappeningDetailContentIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailContentIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailContentIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailContentIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailContentIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailContentIcon.xl,
        },
    }),

    contentHappeningDetailContentDescription: style => ({
        ...style.contentHappeningDetailContentDescription.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailContentDescription.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailContentDescription.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailContentDescription.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailContentDescription.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailContentDescription.xl,
        },
    }),

}));



//
// Composant permettant d'afficher le détail d'une manifestation
//
export const ContentHappeningDetail = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentHappeningDetailTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailRoot: {
                    common: {
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        borderRadius: '4px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        backgroundColor: '#fff',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailHeaderImg: {
                    common: {
                        width: '100%',
                        height: '100%',
                        maxHeight: '50vh',
                        objectFit: 'cover',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailContent: {
                    common: {
                        padding: '1.5rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailContentPlace: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        fontSize: '0.9rem',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailContentDate: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        fontSize: '0.9rem',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailContentIcon: {
                    common: {
                        color: theme.palette.text.secondary,
                        fontSize: 'large',
                        marginRight: theme.spacing(2),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentHappeningDetailContentDescription: {
                    common: {
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mise en forme des dates
    let str_date_publication_start = data.happening.date_publication_start && date_fns_format(parseISO(data.happening.date_publication_start), 'dd/MM/yyyy', {locale: fr});
    let str_date_start = data.happening.date_start && date_fns_format(parseISO(data.happening.date_start), 'EEEE dd MMMM yyyy', {locale: fr});
    let str_date_end = data.happening.date_end && date_fns_format(parseISO(data.happening.date_end), 'EEEE dd MMMM yyyy', {locale: fr});
    let str_date_happening = "";
    if(str_date_start) {
        if(str_date_end) {
            if(str_date_start === str_date_end) {
                str_date_happening = `Le ${str_date_start}`;
            } else {
                str_date_happening = `Du ${str_date_start} au ${str_date_end}`;
            }
        } else {
            str_date_happening = `À partir du ${str_date_start}`;
        }
    } else {
        str_date_happening = "Date de l'événement non précisé"
    }

    return (
        <>
            {data.happening.title &&
                <Typography
                    className={classes.contentHappeningDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {data.happening.title}
                </Typography>
            }

            <Box
                className={classes.contentHappeningDetailRoot}
            >
                {data.happening.img_header && data.happening.img_header.src &&
                    <img
                        className={classes.contentHappeningDetailHeaderImg}
                        src={data.happening.img_header.src}
                        srcSet={data.happening.img_header.srcset}
                        sizes="100vw"
                        title={`${data.happening.img_header.legend} - ${data.happening.img_header.credits}`}
                        alt={data.happening.img_header.alt}
                    />
                }

                <Box
                    className={classes.contentHappeningDetailContent}
                >
                    <Box className={classes.contentHappeningDetailContentDate}>
                        <ScheduleIcon className={classes.contentHappeningDetailContentIcon} />
                        {str_date_happening}
                    </Box>

                    <Box className={classes.contentHappeningDetailContentPlace}>
                        <PlaceIcon className={classes.contentHappeningDetailContentIcon} />
                        {data.happening.place} - {data.happening.city_name}
                    </Box>

                    <Divider />

                    <Box
                        className={classes.contentHappeningDetailContentDescription}
                        dangerouslySetInnerHTML={{ __html: data.happening.description }}
                    />
                </Box>
            </Box>
        </>
    );
}
