import React from 'react';

import {
    useState,
    useEffect,
    useRef,
    useMemo,
} from 'react';

import {
  Route,
} from "react-router-dom";

import {
    Box,
    CssBaseline,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

// Bibliothèque permettant la modification dynamique des tags HTML du <header />
import { Helmet } from "react-helmet";

// Interface avec Google Tag Manager
import TagManager from 'react-gtm-module';


// Composants propres au projet
import { ScrollOnTop } from "PathCommon/tools/ScrollOnTop.jsx";
import { WWWFAB } from "PathWWW/components/WWWFAB.jsx";
import { WWWCookieDialog } from "PathWWW/components/WWWCookieDialog.jsx";

import { FilAriane } from "PathWWW/components/FilAriane.jsx";
import { Header } from "PathWWW/components/Header.jsx";
import { Footer } from "PathWWW/components/Footer.jsx";
import { ContentFactory } from "PathContent/components/ContentFactory.jsx";

// Hook utilisé pour dialoguer avec le serveur
import { useDataLoader } from 'PathCommon/contexts/DataLoaderContext.jsx';





//
// Style propre au composant 'Body'
//
const useStylesBody = makeStyles((theme) => ({
    main: {
        minHeight: '50vh',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    footer: {
    },
}));



//
// Composant représentant la page entière (le 'body') du site web. Il contient notamment :
// - l'entête et le menu
// - le corps de la page avec les éléments de routage
// - le footer
//
export const Body = ({ history, location, match, staticContext, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook permettant de demander les données au serveur, en fonction de l'URL de la page demandée
    // Ce hook est lié au contexte 'DataLoaderContext'
    const { data, fetchData } = useDataLoader();

    // Url associée aux données courantes
    const currentDataUrl = useRef();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lors du chargement de la page : on demande les données au serveur
    useEffect(() => {

        if(__isBrowser__ && !__dev_mode__) {
            // Initialisation de Google Tag Manager
            TagManager.initialize({
                gtmId: __GOOGLE_TAG_MANAGER_ID__,
            });
        }

    }, []);


    // Hook appelé à chaque changement de page : on demande au serveur les données
    // associées à l'URL actuelle
    useEffect(() => {

        // Est-on côté client ?
        if(__isBrowser__) {
            // OUI : est-ce que l'URL courante (correspondant aux données courantes) a changé ?
            if(currentDataUrl.current !== location.pathname) {
                // OUI : on demande les données associées à la nouvelle URL
                fetchData(location.pathname);

                // On met à jour l'URL courante
                currentDataUrl.current = location.pathname;
            }

            // À chaque affichage d'une nouvelle page, on se replace en haut de la page
            window.scrollTo(0, 0);
        }

    }, [location.pathname]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStylesBody();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            {/* Initialisation du style avec des valeurs par défaut */}
            <CssBaseline />

            <ScrollOnTop />

            {/* Mise en place des balises 'meta' et autres, via 'react-helmet' */}
            {useMemo(() => (
                <Helmet>
                    <html lang="fr" />
                    {data && data.head && <title>{data.head.title}</title>}
                    {data && data.head && <meta name="description" content={data.head.description} />}
                </Helmet>
            ), [
                data.head
            ])}


            {/* Partie HEADER */}
            {useMemo(() => (
                <Route
                    path="/"
                    exact={false}
                    render={(propsRoute) => (
                        <Header
                            {...propsRoute}
                            header={data.header}
                            menu={data.menu}
                        />
                    )}
                />
            ), [
                data.header,
                data.menu,
            ])}

            {/* Partie MAIN */}
            <Box
                component='main'
                className={classes.main}
            >
                {/* Fil d'ariane, présent sur toutes les pages */}
                {data.breadcrumb &&
                    <FilAriane
                        breadcrumb={data.breadcrumb}
                    />
                }

                {/* Toutes les URLs sont redirigées vers le composant 'ContentFactory' qui s'occupera du contenu */}
                {useMemo(() => (
                    <Route
                        path="/"
                        exact={false}
                        render={(propsRoute) =>
                            <ContentFactory
                                {...propsRoute}
                                content={data.lst_contents}
                            />
                        }
                    />
                ), [
                    data.lst_contents,
                ])}

                {/* Bouton d'action flottant, présent sur toutes les pages */}
                <WWWFAB />
            </Box>


            {/* Partie FOOTER */}
            <Box
                component='footer'
            >
                {useMemo(() => (
                    <Route
                        path="/"
                        exact={false}
                        render={(propsRoute) => (
                            <Footer {...propsRoute} footer={data.footer} />
                        )}
                    />
                ), [
                    data.footer
                ])}
            </Box>

            {/* Boite de dialogue de configuration des cookies */}
            <WWWCookieDialog />
        </>
    );
}
