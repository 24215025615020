import React from 'react';

import {
    useEffect,
    useRef,
} from 'react';

import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';


import { ContactForm } from "PathWWW/components/ContactForm.jsx";

// Importation du fichier de configuration
import {
    PHONE_SEPARATOR,
} from "PathWWW/components/WWWConfig.jsx";

import { toStrPhoneNumber } from "PathCommon/tools/utils.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentContactFormRoot: style => ({
        ...style.contentContactFormRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormRoot.xl,
        },
    }),

    contentContactFormTitle: style => ({
        ...style.contentContactFormTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormTitle.xl,
        },
    }),

    contentContactFormGridContainer: style => ({
        ...style.contentContactFormGridContainer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormGridContainer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormGridContainer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormGridContainer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormGridContainer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormGridContainer.xl,
        },
    }),

    contentContactFormGridItemImg: style => ({
        ...style.contentContactFormGridItemImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormGridItemImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormGridItemImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormGridItemImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormGridItemImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormGridItemImg.xl,
        },
    }),

    contentContactFormImg: style => ({
        ...style.contentContactFormImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormImg.xl,
        },
    }),

    contentContactFormGridItemInfos: style => ({
        ...style.contentContactFormGridItemInfos.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormGridItemInfos.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormGridItemInfos.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormGridItemInfos.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormGridItemInfos.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormGridItemInfos.xl,
        },
    }),

    contentContactFormInfos: style => ({
        ...style.contentContactFormInfos.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormInfos.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormInfos.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormInfos.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormInfos.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormInfos.xl,
        },
    }),

    contentContactFormGridItemForm: style => ({
        ...style.contentContactFormGridItemForm.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentContactFormGridItemForm.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentContactFormGridItemForm.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentContactFormGridItemForm.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentContactFormGridItemForm.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentContactFormGridItemForm.xl,
        },
    }),
}));


//
// Composant permettant d'afficher un formulaire de contact
//
export const ContentContactForm = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Référence vers le 'div' contenant le formulaire de contact
    // Utilisé pour faire un 'scroll' jusqu'à ce 'div' présent dans la
    // page si l'utilisateur souhaite accéder directement au formulaire
    // (clic sur le lien 'nous contacter'...)
    const refDivContactForm = useRef(null);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé à chaque affichage du composant
    useEffect(() => {

        // Est-ce que dans l'URL, il y a la présence de l'ancre "#formulaire-de-contact" ?
        if(props.location && props.location.hash === "#formulaire-de-contact") {
            // OUI : l'utilisateur souhaite accéder directement au formulaire de contact...
            // Est-ce que la référence vers le 'div' contenant le formulaire est valide ?
            if(refDivContactForm.current) {
                // OUI : on effectue le scroll vers ce 'div' contenant le formulaire
                refDivContactForm.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center'
                });
            }
        }

    }, []);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentContactFormRoot: {
                    common: {
                        marginBottom: theme.spacing(2),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormGridContainer: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormGridItemImg: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormImg: {
                    common: {
                        height: "30vh",
                        width: "100%",
                        objectFit: "cover",
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormGridItemInfos: {
                    common: {},
                    xs: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    sm: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    md: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    lg: {},
                    xl: {},
                },

                contentContactFormInfos: {
                    common: {
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentContactFormGridItemForm: {
                    common: {},
                    xs: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    sm: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    md: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            id="formulaire-de-contact"
            className={classes.contentContactFormRoot}
            ref={refDivContactForm}
        >
            {title &&
                <Typography
                    className={classes.contentContactFormTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Grid
                className={classes.contentContactFormGridContainer}
                container
                spacing={2}
            >

                { data.contact_form && data.contact_form.img_header &&
                    <Grid
                        className={classes.contentContactFormGridItemImg}
                        item
                        xs={12}
                    >
                        <img
                            className={classes.contentContactFormImg}
                            src={data.contact_form.img_header.src}
                            srcSet={data.contact_form.img_header.srcset}
                            sizes="100vw"
                            title={`${data.contact_form.img_header.legend} - ${data.contact_form.img_header.credits}`}
                            alt={data.contact_form.img_header.alt}
                        />
                    </Grid>
                }

                { data.contact_form &&
                    <Grid
                        className={classes.contentContactFormGridItemInfos}
                        item
                        xs={12}
                        md={6}
                    >
                        { data.contact_form.infos &&
                            <Box
                                className={classes.contentContactFormInfos}
                                dangerouslySetInnerHTML={{ __html: data.contact_form.infos }}
                            />
                        }

                        <List aria-label="Liste des moyens de contact de la commune : téléphone, adresse postale, adresse électronique et horaires">
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText primary={
                                    <>
                                        { data.contact_form.entity.address1 &&
                                            <Typography>
                                                {data.contact_form.entity.address1}
                                            </Typography>
                                        }

                                        { data.contact_form.entity.address2 &&
                                            <Typography>
                                                {data.contact_form.entity.address2}
                                            </Typography>
                                        }

                                        { data.contact_form.entity.zip_code && data.contact_form.entity.city &&
                                            <Typography>
                                                {data.contact_form.entity.zip_code} - {data.contact_form.entity.city}
                                            </Typography>
                                        }
                                    </>
                                } />
                            </ListItem>

                            { data.contact_form.entity.phone &&
                                <ListItem>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={toStrPhoneNumber(data.contact_form.entity.phone, PHONE_SEPARATOR)} />
                                </ListItem>
                            }

                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Utilisez notre formulaire de contact" />
                            </ListItem>

                            { data.contact_form.entity.time_slots_text1 &&
                                <ListItem>
                                    <ListItemIcon>
                                        <WatchLaterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <>
                                            { data.contact_form.entity.time_slots_text1 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text1}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text2 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text2}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text3 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text3}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text4 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text4}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text5 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text5}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text6 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text6}
                                                </Typography>
                                            }

                                            { data.contact_form.entity.time_slots_text7 &&
                                                <Typography>
                                                    {data.contact_form.entity.time_slots_text7}
                                                </Typography>
                                            }
                                        </>
                                    } />
                                </ListItem>
                            }
                        </List>
                    </Grid>
                }

                <Grid
                    className={classes.contentContactFormGridItemForm}
                    item
                    xs={12}
                    md={6}
                >
                    <ContactForm
                        labelGCU={data.contact_form && data.contact_form.gcu}
                    />
                </Grid>

            </Grid>

        </Box>
    );
}
