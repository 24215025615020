import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentErrorRoot: style => ({
        ...style.contentErrorRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentErrorRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentErrorRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentErrorRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentErrorRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentErrorRoot.xl,
        },
    }),

    contentErrorText: style => ({
        ...style.contentErrorText.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentErrorText.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentErrorText.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentErrorText.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentErrorText.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentErrorText.xl,
        },
    }),

}));


//
// Composant permettant d'afficher une page d'erreur.
// Le type d'erreur est contenu dans 'data'.
//
export const ContentError = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentErrorRoot: {
                    common: {
                        width: '100%',
                        height: 'inherit',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentErrorText: {
                    common: {
                        fontWeight: 'bold',
                        color: theme.palette.error.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentErrorRoot}
        >
            <Typography
                className={classes.contentErrorText}
                variant="body1"
            >
                { data }
            </Typography>
        </Box>
    );
}
