import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Container,
    Hidden,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventIcon from '@material-ui/icons/Event';


import { CCVTIcon } from "PathWWW/components/CCVTIcon.jsx";





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 'auto',
        height: '8rem',
        [theme.breakpoints.down('sm')]: {
            height: '2.5rem',
        },
    },
    tagline: {
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        },
    },
    taglineCC: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
        },
    },
    taglineTexte: {
        fontSize: '1.5rem',
        fontFamily: 'Satisfy, Arial',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
    },
    iconToolbar: {
        display: 'flex',
        flexDirection: 'row',
    },
    iconButton: {
        margin: theme.spacing(0, 1),
        backgroundColor: theme.menu.colors.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.menu.colors.mainLight,
        },
    },
}));





//
// Composant représentant la partie supérieure du 'header'.
// On y retrouve notamment :
// - le logo de la CC permettant de retourner à la page d'accueil
// - la 'tagline' de la CC
//
export const Entete = (props) => {

    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Container
            maxWidth="lg"
            className={classes.container}
        >
            <Box className={classes.header}>
                <Box
                    className={classes.logo}
                >
                    <Link to="/">
                        <CCVTIcon style={{ width: 'auto', height: '100%', }}/>
                    </Link>
                </Box>

                <Box
                    className={classes.tagline}
                >
                    <Typography
                        className={classes.taglineCC}
                        component="h1"
                        variant="h6"
                    >
                        Communauté de Communes du Vexin-Thelle
                    </Typography>

                    <Typography
                        className={classes.taglineTexte}
                        component="span"
                        variant="h6"
                    >
                        {`\u00AB\u00A0Ensemble, construisons notre avenir\u00A0\u00BB`}
                    </Typography>
                </Box>
            </Box>

            <Hidden
                smDown
                implementation="css"
            >
                <Box className={classes.iconToolbar}>
                    <Tooltip title="Agenda des manifestations">
                        <Link to="/agenda/">
                            <IconButton className={classes.iconButton} aria-label="agenda">
                                <EventIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title="Rechercher sur le site">
                        <Link to="/rechercher/">
                            <IconButton className={classes.iconButton} aria-label="rechercher">
                                <SearchIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title="Ressource documentaire">
                        <Link to="/ressource-documentaire/">
                            <IconButton className={classes.iconButton} aria-label="ressource documentaire">
                                <MenuBookIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            </Hidden>
        </Container>
    );
}
