import React from 'react';

import {
    Link as RouterLink,
} from "react-router-dom";

import {
    Avatar,
    Box,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentSitemapRoot: style => ({
        ...style.contentSitemapRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSitemapRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSitemapRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSitemapRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSitemapRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSitemapRoot.xl,
        },
    }),

    contentSitemapTitle: style => ({
        ...style.contentSitemapTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSitemapTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSitemapTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSitemapTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSitemapTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSitemapTitle.xl,
        },
    }),

    contentSitemapListItemLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contentSitemapListItemTextSection: {
        fontStyle: 'italic',
        fontSize: '0.9rem',
    },

}));



//
// Composant représentant un contenu statique, c'est-à-dire au format HTML
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentSitemap = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentSitemapRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSitemapTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentSitemapRoot}
        >
            {title &&
                <Typography
                    className={classes.contentSitemapTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {/* Liste des articles */}
            {data.lst_items && data.lst_items.lst_articles && data.lst_items.lst_articles.length > 0 &&
                <>
                    <Typography
                        component="h2"
                        variant="h1"
                    >
                        Liste des articles présents sur le site
                    </Typography>

                    <List>
                        {data.lst_items.lst_articles.map((article, index) =>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                <Link
                                    className={classes.contentSitemapListItemLink}
                                    component={RouterLink}
                                    to={article.url_detail}
                                >
                                    <ListItemText
                                        primary={article.title}
                                        secondary={
                                            <Typography
                                                component="span"
                                                className={classes.contentSitemapListItemTextSection}
                                            >
                                                {article.section_name}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </>
            }

            {/* Liste des actualités */}
            {data.lst_items && data.lst_items.lst_news && data.lst_items.lst_news.length > 0 &&
                <>
                    <Typography
                        component="h2"
                        variant="h1"
                    >
                        Liste des actualités présentes sur le site
                    </Typography>

                    <List>
                        {data.lst_items.lst_news.map((news, index) =>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                <Link
                                    className={classes.contentSitemapListItemLink}
                                    component={RouterLink}
                                    to={news.url_detail}
                                >
                                    <ListItemText
                                        primary={news.title}
                                        secondary={
                                            <Typography
                                                component="span"
                                                className={classes.contentSitemapListItemTextSection}
                                            >
                                                {news.section_name}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </>
            }

            {/* Liste des événements */}
            {data.lst_items && data.lst_items.lst_happenings && data.lst_items.lst_happenings.length > 0 &&
                <>
                    <Typography
                        component="h2"
                        variant="h1"
                    >
                        Liste des événements présents sur le site
                    </Typography>

                    <List>
                        {data.lst_items.lst_happenings.map((happening, index) =>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                <Link
                                    className={classes.contentSitemapListItemLink}
                                    component={RouterLink}
                                    to={happening.url_detail}
                                >
                                    <ListItemText
                                        primary={happening.title}
                                        secondary={
                                            <Typography
                                                component="span"
                                                className={classes.contentSitemapListItemTextSection}
                                            >
                                                {happening.section_name}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </>
            }

            {/* Liste des documents */}
            {data.lst_items && data.lst_items.lst_resource_documents && data.lst_items.lst_resource_documents.length > 0 &&
                <>
                    <Typography
                        component="h2"
                        variant="h1"
                    >
                        Liste des documents présents sur le site
                    </Typography>

                    <List>
                        {data.lst_items.lst_resource_documents.map((resourceDocument, index) =>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                <Link
                                    className={classes.contentSitemapListItemLink}
                                    href={resourceDocument.url_detail}
                                    target="_blank"
                                >
                                    <ListItemText
                                        primary={resourceDocument.legend}
                                        secondary={
                                            <Typography
                                                component="span"
                                                className={classes.contentSitemapListItemTextSection}
                                            >
                                                {resourceDocument.credits}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </>
            }

            {/* Liste des images */}
            {data.lst_items && data.lst_items.lst_resource_photos && data.lst_items.lst_resource_photos.length > 0 &&
                <>
                    <Typography
                        component="h2"
                        variant="h1"
                    >
                        Liste des photos présentes sur le site
                    </Typography>

                    <List>
                        {data.lst_items.lst_resource_photos.map((resourcePhoto, index) =>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                <Link
                                    className={classes.contentSitemapListItemLink}
                                    href={resourcePhoto.url_detail}
                                    target="_blank"
                                >
                                    <ListItemText
                                        primary={resourcePhoto.legend}
                                        secondary={
                                            <Typography
                                                component="span"
                                                className={classes.contentSitemapListItemTextSection}
                                            >
                                                {resourcePhoto.credits}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </>
            }

        </Box>
    );
}
