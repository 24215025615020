import React from 'react';

import {
    useEffect,
    useState,
} from 'react';

import {
  Link,
} from "react-router-dom";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Box,
    CircularProgress,
    Container,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Bibliothèque 'date-fns', pour la gestion de la date et de l'heure
import { format } from 'date-fns'
import { fr } from "date-fns/locale";


// Hook utilisé pour dialoguer avec le serveur
import {
    useRequestData,
    RequestStatus,
} from 'PathCommon/hooks/useRequestData.jsx';

// Importation du fichier de configuration
import { wwwConfig } from "PathWWW/components/WWWConfig.jsx";

// Style utilisé pour l'affichage de la date des documents
const strDateFormat = "iii dd/MM/RRRR";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentResourceDocRoot: style => ({
        ...style.contentResourceDocRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocRoot.xl,
        },
    }),

    contentResourceDocTitle: style => ({
        ...style.contentResourceDocTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocTitle.xl,
        },
    }),

    contentResourceDocRootResults: style => ({
        ...style.contentResourceDocRootResults.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocRootResults.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocRootResults.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocRootResults.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocRootResults.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocRootResults.xl,
        },
    }),

    contentResourceDocList: {
        width: '100%',
    },
    contentResourceDocLink: {
        textDecoration: 'none',
        color: 'unset',
    },
    contentResourceDocText: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentResourceDocDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    loadingError: {
        color: theme.palette.error.main,
    },
    loadingBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));



// Callback utilisée par le hook 'useRequestData' pour transformer
// les données reçues par le serveur
const fetchMapFunc = (data) => {
    return data.map((item) => (
        {
            alternateText: item.alternate_text,
            kindId: item.categorie_id,
            kindLabel: item.categorie_label,
            kindName: item.categorie_nom,
            contentDate: item.content_date && new Date(item.content_date),
            credits: item.credits,
            documentUrl: item.document_url,
            id: item.id,
            legend: item.legend,
            tags: item.tags,
            thumbnailUrl: item.thumbnail_url,
            year: item.year,
        }
    ));
}



//
// Composant représentant la page de ressource documentaire (liste de tous les documents)
//
export const ContentResourceDoc = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook utilisé pour récupérer les données depuis le serveur
    // Si tout s'est bien passé, alors "request.status === RequestStatus.SUCCESS" et
    // les données sont contenues dans "request.data"
    const [request, fetchRequest, abortRequest, resetRequest] = useRequestData();


    // Variable contenant la liste des catégories à afficher ('crcm', 'arrmun', 'arrpref', 'autre'...)
    const [lstKinds, setLstKinds] = React.useState();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé au chargement du composant : on demande la liste des documents au serveur
    useEffect(() => {

        // On lance une requête pour demander les données au serveur
        fetchRequest(
            wwwConfig.api.ressourceDoc,
            { method: "GET", },
            fetchMapFunc,
        );

    }, []);


    // Hook appelé lorsque des données ont été reçues
    useEffect(() => {

        // Est-ce que les données ont été reçues correctement ?
        if(request.status === RequestStatus.SUCCESS) {
            // OUI : on calcule la liste des catégories, sous la forme { nom1: label1, nom2: label2, ... }
            setLstKinds(
                // On itère sur toutes les données
                request.data.reduce((accumulator, currentValue) => (
                    // Est-ce que la catégorie (= kindName) est déjà présente dans l'Object ?
                    accumulator.hasOwnProperty(currentValue.kindName) ?
                        // OUI : on ne fait rien de spécial
                        accumulator
                    :
                        // NON : on ajoute la catégorie actuelle, sous la forme { ..., nom: label, ...}
                        {
                            ...accumulator,
                            [currentValue.kindName]: currentValue.kindLabel,
                        }
                ),
                {})
            );
        }

    }, [request.data]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentResourceDocRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocRootResults: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On récupère la liste des noms des catégories, à partir de la liste des catégories
    const lstKindNames = lstKinds && Object.keys(lstKinds);

    return (
        <Box
            className={classes.contentResourceDocRoot}
        >
            {title &&
                <Typography
                    className={classes.contentResourceDocTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Box
                className={classes.contentResourceDocRootResults}
            >
                {request.status === RequestStatus.ERROR &&
                    <Box>
                        <Typography
                            variant="body1"
                            className={classes.loadingError}
                        >
                            Impossible de récupérer la liste des documents ! Veuillez nous excuser pour la gêne occasionnée...
                        </Typography>
                    </Box>
                }

                {request.status === RequestStatus.IN_PROGRESS &&
                    <Box
                        className={classes.loadingBox}
                    >
                        <CircularProgress />
                        <Typography
                            variant="overline"
                        >
                            CHARGEMENT EN COURS...
                        </Typography>
                    </Box>
                }

                {request.status === RequestStatus.SUCCESS && request.data &&
                    <>
                        {lstKindNames && lstKindNames.length > 0 ?

                            lstKindNames.map((kindName) => (
                                kindName !== 'autre' &&

                                <Accordion
                                    key={kindName}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.contentResourceDocAccordionTitle}>
                                            {lstKinds[kindName]}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <List
                                            className={classes.contentResourceDocList}
                                        >
                                            {request.data.filter((value) => value.kindName === kindName).map((item, index) =>
                                                <Link
                                                    key={index}
                                                    to={{ pathname: item.documentUrl }}
                                                    className={classes.contentResourceDocLink}
                                                    target="_blank"
                                                >

                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <PictureAsPdfIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Box
                                                                    className={classes.contentResourceDocText}
                                                                >
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body1"
                                                                        color="textPrimary"
                                                                    >
                                                                        {item.legend}
                                                                    </Typography>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                    >
                                                                        {
                                                                            item.contentDate ?
                                                                                format(item.contentDate, strDateFormat, {locale: fr})
                                                                            :
                                                                                "---"
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        />
                                                    </ListItem>

                                                    <Divider component="li" className={classes.contentResourceDocDivider} />

                                                </Link>
                                            )}

                                        </List>

                                    </AccordionDetails>
                                </Accordion>
                            ))

                        :
                            <Typography>
                                Aucun document disponible pour le moment
                            </Typography>
                        }

                        {/* Cas spécial de la catégorie 'Autre', forcée manuellement en dessous des autres listes */}
                        {/* TODO : pouvoir paramétrer dynamiquement l'ordre des catégories */}
                        {lstKindNames && lstKindNames.includes("autre") &&

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.contentResourceDocAccordionTitle}>
                                        {lstKinds["autre"]}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <List
                                        className={classes.contentResourceDocList}
                                    >
                                        {request.data.filter((value) => value.kindName === "autre").map((item, index) =>
                                            <Link
                                                key={index}
                                                to={{ pathname: item.documentUrl }}
                                                className={classes.contentResourceDocLink}
                                                target="_blank"
                                            >

                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PictureAsPdfIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Box
                                                                className={classes.contentResourceDocText}
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    variant="body1"
                                                                    color="textPrimary"
                                                                >
                                                                    {item.legend}
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                >
                                                                    {
                                                                        item.contentDate ?
                                                                            format(item.contentDate, strDateFormat, {locale: fr})
                                                                        :
                                                                            "---"
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                                </ListItem>

                                                <Divider component="li" className={classes.contentResourceDocDivider} />

                                            </Link>
                                        )}

                                    </List>

                                </AccordionDetails>
                            </Accordion>

                        }
                    </>
                }
            </Box>
        </Box>
    );
}
