import React from 'react';
import clsx from 'clsx';

import {
    Link as RouterLink,
} from "react-router-dom";

import {
    Breadcrumbs,
    Container,
    Link,
} from '@material-ui/core';

import {
    makeStyles
} from '@material-ui/core/styles';

import HomeIcon from '@material-ui/icons/Home';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    breadcrumbsContainer: {
        marginTop: '2rem',
    },
    breadcrumbsRoot: {
        fontSize: '0.8rem',
    },
    breadcrumbsIconHome: {
        marginRight: theme.spacing(1),
        color: props => theme.menu.colors[props.menuColor],
    },
    breadcrumbsLink: {
        display: 'flex',
        alignItems: 'center',
        color: props => theme.menu.colors[props.menuColor],
    },
    breadcrumbsLinkDisabled: {
        pointerEvents: 'none',
        fontWeight: 'bold',
    },
}));





//
// Composant représentant le fil d'ariane de la page courante.
//
export const FilAriane = ({breadcrumb, ...props}) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style, en passant en paramètre la couleur à utiliser pour le titre,
    // déterminée en fonction de la (sous-)section courante
    const classes = useStyles({
        menuColor: breadcrumb.color
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Container
            maxWidth="lg"
            className={classes.breadcrumbsContainer}
        >
            <Breadcrumbs
                className={classes.breadcrumbsRoot}
                aria-label="breadcrumb"
            >
                <Link
                    color="inherit"
                    href="/"
                    className={classes.breadcrumbsLink}
                >
                    <HomeIcon className={classes.breadcrumbsIconHome} />Accueil
                </Link>

                {breadcrumb.lst_routes && breadcrumb.lst_routes.map((route, index) => (
                    <Link
                        key={index}
                        component={RouterLink}
                        color="inherit"
                        to={route.link || {}}
                        className={clsx(
                            classes.breadcrumbsLink,
                            {[classes.breadcrumbsLinkDisabled]: !route.link,}
                        )}
                        disabled={!route.link}
                    >
                        {route.label}
                    </Link>
                ))}
            </Breadcrumbs>
        </Container>
    );
}
