import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EcoIcon from '@material-ui/icons/Eco';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PeopleIcon from '@material-ui/icons/People';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import WorkIcon from '@material-ui/icons/Work';
import TheatersIcon from '@material-ui/icons/Theaters';

import { CollectivitesCCVTIcon } from './CollectivitesCCVTIcon.jsx';

export const PHONE_SEPARATOR = ".";

export const API_CONTENT_PAGE_URL = "/api/content-page/";

export const wwwConfig = {
    api: {
        articlePreview: '/api/web/article/preview/',
        articleDetail: '/api/web/article/',
        actualitePreview: '/api/web/actualite/preview/',
        actualiteDetail: '/api/web/actualite/',
        manifestationPreview: '/api/web/manifestation/preview/',
        manifestationDetail: '/api/web/manifestation/',
        sectionMenuN1: '/api/web/section-menuN1/',
        contact: '/api/web/contact/',
        accesRapide: '/api/web/acces-rapide/',
        comCom: '/api/intranet/comcom/',
        comComInfos: '/api/intranet/comcom/infos/',
        lieu: '/api/web/lieu/',
        website: '/api/intranet/website/',
        websiteContact: '/api/intranet/website/contact/',
        websiteReseauxSociaux: '/api/intranet/website/reseaux-sociaux/',
        websiteDescriptionTerritoire: '/api/intranet/website/description-territoire/',
        websiteMentionsLegales: '/api/intranet/website/mentions-legales/',
        communeContour: '/api/web/commune/contour/',
        vexinfo: '/api/web/vexinfo/',
        compteRendu: '/api/web/compte-rendu/',
        recherche: '/api/web/recherche/',
        ressourceDoc: '/api/web/ressource-documentaire/',
        mentionsLegales: '/api/web/mentions-legales/',
        formulaireContact: '/api/web/formulaire-contact/',
        happeningGetByDateRange: '/api/tourinsoft/FMA/get-by-date-range/',
        newsletterAddUser: '/api/intranet/newsletter/ajouter-utilisateur/',
    },
    accesRapideCategories: {
        undefined: 1,
        url: 2,
        section: 3,
        article: 4,
        resource_document: 5,
        resource_photo: 6,
    },
};


export const MENU_ICON_MAPPING = {
    "CollectivitesCCVTIcon": CollectivitesCCVTIcon,
    "DirectionsRunIcon": DirectionsRunIcon,
    "EcoIcon": EcoIcon,
    "HomeWorkIcon": HomeWorkIcon,
    "PeopleIcon": PeopleIcon,
    "PhotoCameraIcon": PhotoCameraIcon,
    "WorkIcon": WorkIcon,
    "TheatersIcon": TheatersIcon,
}
