// Ensemble de fonctions utilitaires, communes à tout le projet

// Retourne une chaîne de caractères au format 'XX XX XX XX XX' à partir
// d'une chaîne de caractères 'str' au format 'XXXXXXXXXX'
// Le paramètre 'sep' permet d'ajouter un séparacteur entre chaque groupe
export function toStrPhoneNumber(str, sep=" ") {

    // Si 'str' n'est pas une chaine de caractères ou
    // que 'str' ne fait 10 caractères alors on considère que le
    // numéro est invalide : on retourne une chaine vide
    if(typeof(str) !== "string" || str.length != 10) { return ""; }

    // 'str' est bien une chaîne de 10 caractères : on insère
    // le séparateur 'sep' entre les différents nombres
    return str.slice(0, 2) + sep
        + str.slice(2, 4) + sep
        + str.slice(4, 6) + sep
        + str.slice(6, 8) + sep
        + str.slice(8, 10);
}
