import React from 'react';

import clsx from 'clsx';

import {
    useEffect,
    useState,
} from 'react';

import {
    Link,
} from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    Autocomplete,
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import TodayIcon from '@material-ui/icons/Today';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EcoIcon from '@material-ui/icons/Eco';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PeopleIcon from '@material-ui/icons/People';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import WorkIcon from '@material-ui/icons/Work';
import TheatersIcon from '@material-ui/icons/Theaters';

import {
    format as date_fns_format,
    compareAsc,
    addMonths,
    subMonths,
    addDays,
    startOfMonth,
    endOfMonth,
    startOfISOWeek,
    endOfISOWeek,
    eachDayOfInterval,
    differenceInCalendarDays,
    parseISO,
} from "date-fns";

import { fr } from "date-fns/locale";


// Hook utilisé pour dialoguer avec le serveur
import {
    useRequestData,
} from 'PathCommon/hooks/useRequestData.jsx';

// Importation du fichier de configuration
import { wwwConfig } from "PathWWW/components/WWWConfig.jsx";

import { CollectivitesCCVTIcon } from 'PathWWW/components/CollectivitesCCVTIcon.jsx';

const dateFormat = "MMMM yyyy";





// Table de correspondance entre les libellés des icones retournés
// par le serveur et les composants React associés
const STRING_TO_COMPONENT_MAPPING = {
    "DirectionsRunIcon": DirectionsRunIcon,
    "EcoIcon": EcoIcon,
    "HomeWorkIcon": HomeWorkIcon,
    "PeopleIcon": PeopleIcon,
    "PhotoCameraIcon": PhotoCameraIcon,
    "WorkIcon": WorkIcon,
    "TheatersIcon": TheatersIcon,
    "CollectivitesCCVTIcon": CollectivitesCCVTIcon,
}





//
// Style propre au composant 'WeekdayHeader'
//
const useWeekdayHeaderStyles = makeStyles((theme) => ({
    weekdayHeaderRoot: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    },
    weekdayHeaderLabel: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        textAlign: 'center',
        textTransform: 'capitalize',
    },
}));



//
// Composant utilisé pour afficher les jours de la semaine dans la partie "header" du calendrier
// Ces jours sont calculés dynamiquement pour tenir compte de la langue
//
export const WeekdayHeader = (props) => {

    // Récupération des différentes propriétés
    const { downMD } = props;


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On calcule le tableau contenant les libellés des jours de la semaine.
    // Pour cela, on récupère le premier jour de la semaine (de la date courante, quelle qu'elle soit)
    const firstDayOfWeek = startOfISOWeek(new Date());
    // Et on itère sur les 7 jours à partir de cette date, en ne récupérant que le nom du jour
    const weekdays = [...Array(7).keys()].map(
        (i) => date_fns_format(
            addDays(firstDayOfWeek, i),
            downMD ? 'iiiiii' : 'iiii',     // Si la taille est < 'md' alors on affiche les jours sur deux lettres uniquement ('Lu', 'Ma', 'Me'...)
            { locale: fr }
        )
    )



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useWeekdayHeaderStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box className={classes.weekdayHeaderRoot}>
            {weekdays.map((day, index) =>
                <Box key={index} className={classes.weekdayHeaderLabel}>
                    {day}
                </Box>
            )}
        </Box>
    );
}





//
// Style propre au composant 'LstDayCells'
//
const useLstDayCellsStyles = makeStyles((theme) => ({
    lstDayCellsRoot: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
    },
}));



//
// Composant utilisé pour gérer les cellules représentant une journée, pour le mois courant
// La date courante (= jour) est contenue dans la propriété 'selectedDate'
// Les jours de début et de fin de calendrier, pour le mois courant, sont calculés de sorte
// qu'il y ait toujours des semaines complétes affichées. Il est alors généralement nécessaire
// d'intégrer au mois courant quelques jours du mois précédent et quelques jours du mois suivant
//
export const LstDayCells = (props) => {

    // Récupération des différentes propriétés
    const { selectedDate, filterSectionId } = props;


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Premier jour du mois contenant la date courante
    const monthStart = startOfMonth(selectedDate);

    // Dernier jour du mois contenant la date courante
    const monthEnd = endOfMonth(monthStart);

    // Premier jour de la semaine (= lundi) contenant le premier jour du mois
    // En effet, si le mois commence par exemple un jeudi, on fera commencer le
    // calendrier à partir du début de la semaine (à partir du lundi)
    const startDate = startOfISOWeek(monthStart);

    // Dernier jour de la semaine (= dimanche) contenant le dernier jour du mois
    // En effet, si le mois se termine par exemple un jeudi, on fera terminer le
    // calendrier à la fin de la semaine (le dimanche)
    const endDate = endOfISOWeek(monthEnd);

    // On récupère le nombre associé au mois courant (1 = janvier, 2 = février, 3 = mars...)
    // Cette variable est utilisée par chaque cellule (correspondant à une journée) pour savoir
    // si elle est associée à un jour du mois (pour gérer les jours en début et fin de calendrier
    // qui sont dans les mois précédent et suivant)
    const currentMonth = selectedDate.getMonth()


    // Hook utilisé pour récupérer les données depuis le serveur
    const [request, fetchRequest, abortRequest, resetRequest] = useRequestData();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lorsque la date courante a changé : on demande les données au serveur
    useEffect(() => {

        // On lance une requête pour demander les données au serveur
        fetchRequest(
            `${wwwConfig.api.happeningGetByDateRange}?date-start=${startDate.toISOString()}&date-end=${endDate.toISOString()}`,
            { method: "GET", },
        );

    }, [selectedDate]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstDayCellsStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    const today = new Date();

    return (
        <Box className={classes.lstDayCellsRoot}>

            {/* Pour chaque journée comprise dans l'intervalle [startDate : endDate] */}
            {eachDayOfInterval({
                start: startDate,
                end: endDate
            }).map((day, index) => {

                // On récupère la date courante, au format ISO
                let currentDayISO = date_fns_format(day, 'yyyy-MM-dd');

                // On récupère la liste des manifestations se déroulant cette journée
                // (ou une liste vide s'il n'y a pas de manifestation)
                let dayEvents = (request.data && request.data[currentDayISO]) || [];

                // Si une section a été sélectionnée dans le filtre...
                if(filterSectionId) {
                    // alors on ne garde que les événements appartenant à cette section
                    dayEvents = dayEvents.filter(item => item.section_id === filterSectionId)
                }

                // Et on affiche la cellule
                // 'currentMonth' vaut 'true' si la journée associée à la cellule est dans le mois courant (pour gérer les jours au début et à la fin du calendrier)
                // 'lastColumn' vaut 'true' si la cellule courante est en bout de ligne
                return (
                    <DayCell
                        key={index}
                        currentMonth={day.getMonth() === currentMonth}
                        dayNumber={day.getDate()}
                        lastColumn={(index+1)%7}
                        dayEvents={dayEvents}
                        isToday={differenceInCalendarDays(today, day) === 0}
                    />
                );
            })}

        </Box>
    );
}





//
// Style propre au composant 'DayCell'
//
const useDayCellStyles = makeStyles((theme) => ({
    dayCellRoot: {
        borderTop: 'solid rgb(220, 220, 220) 1px',
        borderRight: 'solid rgb(220, 220, 220) 1px',
        minHeight: '6vh',
        minWidth: '0px',      // Nécessaire pour éviter aux colonnes de s'agrandir (utile dans le cas de labels longs)
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            minHeight: '8vw',
        }
    },
    dayCellIsToday: {
        backgroundColor: 'rgba(88, 151, 174, 0.2)',
    },
    dayCellNumber: {
        textAlign: 'end',
        fontSize: 'small',
        padding: theme.spacing(0.5),
    },
    dayCellNotCurrentMonth: {
        color: 'rgb(150, 150, 150)',
        backgroundColor: 'rgb(245, 245, 245)',
    },
    dayCellLastColumn: {
        borderRight: 'unset',
    },
}));



//
// Composant utilisé pour afficher une cellule (= une journée) dans le calendrier
// Cette cellule contient le numéro du jour ainsi que la liste des manifestations (s'il y en a)
// La couleur de fond de la cellule sera différente si elle correspond à une journée qui n'est pas
// dans le mois courant (jours en début et en fin de calendrier)
//
export const DayCell = (props) => {

    // Récupération des différentes propriétés
    const { currentMonth, dayNumber, lastColumn, dayEvents, isToday } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDayCellStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On affiche la cellule, en appliquant un style différent si :
    // - la cellule n'appartient pas au mois courant
    // - la cellule est en fin de ligne (le dimanche)
    //
    // De plus, on intègre dans le composant courant un composant de type 'EventItem' pour chaque manifestation (s'il y en a)
    return (
        <Box
            className={clsx(
                classes.dayCellRoot,
                isToday && classes.dayCellIsToday,
                !currentMonth && classes.dayCellNotCurrentMonth,
                lastColumn === 0 && classes.dayCellLastColumn
            )}
        >
            <Typography component="span" className={classes.dayCellNumber}>{dayNumber}</Typography>

            {dayEvents.map((eventItem, index) =>
                <EventItem key={index} eventItem={eventItem} />
            )}
        </Box>
    );
}





//
// Style propre au composant 'EventItem'
//
const useEventItemStyles = makeStyles((theme) => ({
    eventItem: {
        fontSize: 'small',
        borderLeftColor: props => theme.menu.colors[props.color],
        borderLeftStyle: 'solid',
        borderLeftWidth: theme.spacing(1),
        backgroundColor: theme.palette.primary[50],
        padding: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        borderRadius: '0px',
        textTransform: 'unset',

        '& .MuiButton-label': {
            display: 'block',
            textAlign: 'left',
/*
            // A utiliser pour avoir les labels des événements sur
            // une seule ligne (tronqués si trop grand)
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
*/
        }
    },
}));



//
// Composant permettant d'afficher le titre d'une manifestation dans une cellule (= une journée)
// Un bouton est utilisé pour rendre le titre cliquable (affichage du détail de la manifestation)
// Si une manifestation se déroule sur plusieurs jours alors il y aura un 'EventItem' par jour
//
export const EventItem = (props) => {

    // Récupération des différentes propriétés
    const { eventItem } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useEventItemStyles({
        color: eventItem.color,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Button
            className={classes.eventItem}
            component={Link}
            to={{ pathname: eventItem.url_detail }}
        >
            {eventItem.title}
        </Button>
    );
}





//
// Style propre au composant 'DayListItem'
//
const useDayListItemStyles = makeStyles((theme) => ({
    dayListItem: {
        borderLeftColor: props => theme.menu.colors[props.color],
        borderLeftStyle: 'solid',
        borderLeftWidth: theme.spacing(1),
    },
    dayListItemLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'unset',
    }
}));



//
// Composant représentant un événement (un item) dans la liste de tous les événements
//
export const DayListItem = (props) => {

    // Récupération des différentes propriétés
    const { event } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDayListItemStyles({
        color: event.color,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <ListItem
            className={classes.dayListItem}
            button
        >
            <Link
                className={classes.dayListItemLink}
                to={event.url_detail}
            >
                <ListItemAvatar>
                    <Avatar src={event.photo_url} />
                </ListItemAvatar>
                <ListItemText primary={event.title} />
            </Link>
        </ListItem>
    );
}





//
// Style propre au composant 'DayList'
//
const useDayListStyles = makeStyles((theme) => ({
    dayListDay: {
        backgroundColor: 'rgb(230, 230, 230)',
        textTransform: 'capitalize',
        textAlign: 'right',
        color: 'rgba(0, 0, 0, 0.8)',
    },
}));



//
// Composant utilisé pour afficher un événement dans la liste
//
export const DayList = (props) => {

    // Récupération des différentes propriétés
    const { day, dayEvents, isToday } = props;


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Calcul de la date du jour, en toute lettre
    const strDay = date_fns_format(parseISO(day), 'iiii d MMMM yyyy', {locale: fr});



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDayListStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <ListSubheader component="h2" className={classes.dayListDay}>
                {strDay}
            </ListSubheader>

            {dayEvents.map((event, index) =>
                <DayListItem
                    key={index}
                    event={event}
                />
            )}
        </>
    );
}





//
// Style propre au composant 'LstDayList'
//
const useLstDayListStyles = makeStyles((theme) => ({
    lstDayListNoEvent: {
        margin: theme.spacing(2),
        fontStyle: 'italic',
    },
}));



//
// Composant utilisé pour afficher les événements sous forme de liste
//
export const LstDayList = (props) => {

    // Récupération des différentes propriétés
    const { selectedDate } = props;


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Premier jour du mois contenant la date courante
    const monthStart = startOfMonth(selectedDate);

    // Dernier jour du mois contenant la date courante
    const monthEnd = endOfMonth(monthStart);

    // Hook utilisé pour récupérer les données depuis le serveur
    const [request, fetchRequest, abortRequest, resetRequest] = useRequestData();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lorsque la date courante a changé : on demande les données au serveur
    useEffect(() => {

        // On lance une requête pour demander les données au serveur
        fetchRequest(
            `${wwwConfig.api.happeningGetByDateRange}?date-start=${monthStart.toISOString()}&date-end=${monthEnd.toISOString()}`,
            { method: "GET", },
        );

    }, [selectedDate]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useLstDayListStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Date du jour au format ISO, utilisé pour savoir si un événement a lieu aujourd'hui
    // Le format doit être le même que les clés présentes dans 'request.data' pour voir comparer
    // directement les dates
    const today_ISO = date_fns_format(new Date(), 'yyyy-MM-dd');

    return (
        <List>
            {request && request.data &&

                (Object.keys(request.data).length === 0 ?
                    // Aucune donnée à afficher...
                    <ListItemText
                        className={classes.lstDayListNoEvent}
                        primary="Aucun événement n'est prévu pour ce mois-ci"
                    />
                :
                    // On parcourt tous les jours présents dans le dictionnaire, en récupérant les
                    // événements associés
                    Object.entries(request.data)
                        .sort((a, b) => compareAsc(parseISO(a[0]), parseISO(b[0])))
                        .map(([day, dayEvents], index) => {

                        // On crée un item 'DayList' par jour du dictionnaire (donc contenant au moins un événement)
                        return (
                            <DayList
                                key={index}
                                day={day}
                                dayEvents={dayEvents}
                                isToday={day === today_ISO}
                            />
                        );

                    })
                )
            }
        </List>
    );
}





//
// Style propre au composant 'SelectSectionAsync'
//
const useSelectSectionAsyncStyles = makeStyles((theme) => ({
    contentCalendarHeaderFilterSection: {
        width: '100%',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    menuIcon: {
        margin: '0px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingRight: theme.spacing(2),
        width: 'auto',
    },
    emptyIcon: {
        paddingLeft: theme.spacing(8),
    },
    menu1: {
        color: theme.menu.colors.menu1,
    },
    menu2: {
        color: theme.menu.colors.menu2,
    },
    menu3: {
        color: theme.menu.colors.menu3,
    },
    menu4: {
        color: theme.menu.colors.menu4,
    },
    menu5: {
        color: theme.menu.colors.menu5,
    },
    menu6: {
        color: theme.menu.colors.menu6,
    },
    menu7: {
        color: theme.menu.colors.menu7,
    },
    menu8: {
        color: theme.menu.colors.menu8,
    },
}));



//
// Composant permettant de sélectionner une section parmi la liste
// des sections retournées par le serveur
//
export const SelectSectionAsync = ({setFilterSectionId, ...props}) => {

    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

  const [inputValue, setInputValue] = React.useState('');



    // Variable indiquant si la combo-box est ouverte
    const [open, setOpen] = useState(false);

    // Variable contenant les différentes sections à afficher dans
    // la combo-box (provenant du serveur)
    const [options, setOptions] = useState([]);

    // Variable indiquant si les données de la combo-box
    // sont en trai d'être chargées (récupérées depuis le serveur)
    const loading = open && options.length === 0;



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque le statut 'loading' a changé
    useEffect(() => {
        // Par défaut, le composant est actif
        let active = true;

        // Est-on en mode 'chargement' ?
        if(!loading) {
            // NON : on ne fait plus rien (les données sont déjà chargées)
            return undefined;
        }

        // OUI : on demande les données au serveur
        (async () => {
            const response = await fetch(wwwConfig.api.sectionMenuN1);
            const lstSections = await response.json();

            // Les données reçues sont dans 'lstSections'

            // Est-ce que le composant est toujours actif ?
            if (active) {
                // OUI : on stocke les données reçues
                setOptions(lstSections);
            }
        })();

        return () => {
            // Fin du chargement : on désactive le composant pour éviter
            // une mise à jour ultérieure à cause du 'async'
            active = false;
        };
    }, [loading]);


    // Callback appelée lorsque la valeur sélectionnée dans le filtre 'section' a changé
    useEffect(() => {
        // On recherche la valeur sélectionnée dans la liste des sections
        let current = options.find(element => element.nom === inputValue)

        // Est-ce que la valeur a été trouvée dans cette liste ?
        if(current) {
            // OUI : une section a été sélectionnée : on sauvegarde son ID dans le filtre
            setFilterSectionId(current.id)
        } else {
            // NON : aucune section a été sélectionnée (on veut toutes les sections)
            setFilterSectionId(null)
        }
    }, [inputValue]);



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useSelectSectionAsyncStyles(props);



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Autocomplete
            id="id-select-section"
            name="select-section"
            className={classes.contentCalendarHeaderFilterSection}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.nom}
            options={options}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            loading={loading}
            loadingText="Chargement des données en cours..."
            renderOption={(option) => {
                const ComponentIcon = STRING_TO_COMPONENT_MAPPING[option.icone];

                return(
                    <Box className={classes.[option.couleur]}>
                        {option.icone ?
                            <ComponentIcon className={classes.menuIcon} />
                        :
                            <span className={classes.emptyIcon}></span>
                        }
                        {option.nom}
                    </Box>
                )}
            }
            renderInput={(params) => {
                return <TextField
                    {...params}
                    label="Filtrer par section"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            }}
        />
    );
}





//
// Style propre au composant 'Calendar'
//
const useCalendarStyles = makeStyles((theme) => ({
    contentCalendarRoot: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minHeight: '50vh',
    },
    contentCalendarHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textTransform: 'capitalize',
        borderBottom: 'solid rgb(220, 220, 220) 1px',
        padding: theme.spacing(2),
    },
    contentCalendarHeaderMonth: {
        fontSize: '1.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        order: '1',
        flex: '1 1 100%',
        [theme.breakpoints.up('md')]: {
            marginTop: 'unset',
            marginBottom: 'unset',
            order: 'unset',
            flex: '1 1 0',
        },
    },
    contentCalendarHeaderFilterSectionBox: {
        fontSize: '1.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        order: '1',
        flex: '1 1 100%',
        [theme.breakpoints.up('md')]: {
            marginTop: 'unset',
            marginBottom: 'unset',
            order: '1',
            flex: '1 1 0',
        },
    },
    contentCalendarHeaderButtonMonthGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '0 0 0',
        order: '2',
        [theme.breakpoints.up('md')]: {
            order: 'unset',
        },
    },
    contentCalendarHeaderButtonViewModeGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: '0 0 0',
        order: '3',
        [theme.breakpoints.up('md')]: {
            order: 'unset',
        },
    },
    contentCalendarHeaderButtonMonth: {
        height: 'fit-content',
        border: 'solid rgba(0, 0, 0, 0.15) 1px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    contentCalendarHeaderButtonViewMode: {
        height: 'fit-content',
        color: theme.palette.primary.main,
    },
    contentCalendarBody: {
        display: 'flex',
        flexDirection: 'column',
    },
}));



//
// Composant permettant d'afficher un agenda
//
export const Calendar = (props) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On détermine la taille de l'écran
    const theme = useTheme();
    const downMD = !useMediaQuery(theme.breakpoints.up('md'));


    // Mode d'affichage du calendrier ('grid ou 'list'), tel que sélectionné par l'utilisateur
    // Si 'calendarViewMode' vaut 'undefined' alors cela signifie que l'utilisateur n'a pas
    // encore choisi. On sélectionne donc le mode par défaut (calculé depuis le paramètre, si existant)
    const [calendarViewMode, setCalendarViewMode] = useState();


    // Date sélectionnée dans le calendrier (utilisée pour savoir quel mois doit être affiché)
    // Par défaut, on sélectionne le jour courant
    const [selectedDate, setSelectedDate] = useState(new Date());


    // Filtre sur les sections : contient l'ID de la section sélectionnée ou 'null' pour toutes les sections
    const [filterSectionId, setFilterSectionId] = useState(null);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque l'utilisateur souhaite afficher le mois précédent
    const previousMonth = () => {
        // On retire un mois
        setSelectedDate(
            subMonths(selectedDate, 1)
        );
    };


    // Callback appelée lorsque l'utilisateur souhaite afficher le mois suivant
    const nextMonth = () => {
        // On ajoute un mois
        setSelectedDate(
            addMonths(selectedDate, 1)
        );
    };


    // Callback appelée lorsque l'utilisateur souhaite afficher le mois associé à la date du jour
    const today = () => {
        // On récupère la date du jour
        setSelectedDate(
            new Date()
        );
    };


    // Callback utilisée pour changer le mode d'affichage de l'agenda (grille ou liste)
    const handleCalendarViewMode = (event, newCalendarViewMode) => {
        setCalendarViewMode(newCalendarViewMode);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useCalendarStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mode d'affichage : 'grid' ou 'list'
    // Par défaut, si un mode est précisé dans le fichier de configuration du projet
    // alors on applique ce mode. S'il n'est pas défini ou s'il vaut 'auto' alors on
    // détermine le mode en fonction de la taille de l'écran :
    // < 'md' : mode liste
    // >= 'md' : mode grille
    let currentViewMode = null;

    // Est-ce que l'utilisateur a déjà sélectionné un mode d'affichage ?
    if(calendarViewMode) {

        // OUI : on sélectionne ce mode dans tous les cas
        currentViewMode = calendarViewMode;

    } else {

        // NON : on détermine le mode en fonction du paramètre 'viewMode', si existant
        if(props.viewMode === 'list' || props.viewMode === 'grid') {

            // Un mode a été passé en paramètre : on le sélectionne
            currentViewMode = props.viewMode;

        } else {

            // Cas où le mode est en 'auto' ou non défini : on calcule le mode
            // en fonction de la résolution ('mobile' ou 'desktop')
            currentViewMode = downMD ? 'list' : 'grid';

        }
    }


    return (
        <Paper className={classes.contentCalendarRoot}>
            <Box className={classes.contentCalendarHeader}>
                <Box className={classes.contentCalendarHeaderButtonMonthGroup}>
                    <Tooltip title="Mois précédent" aria-label="Afficher le mois précédent">
                        <IconButton
                            className={classes.contentCalendarHeaderButtonMonth}
                            component="span"
                            color="primary"
                            aria-label="Mois précédent"
                            onClick={(event) => previousMonth()}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Aujourd'hui" aria-label="Afficher le mois courant">
                        <IconButton
                            className={classes.contentCalendarHeaderButtonMonth}
                            component="span"
                            color="primary"
                            aria-label="Afficher le mois courant"
                            onClick={(event) => today()}
                        >
                            <TodayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Mois suivant" aria-label="Afficher le mois suivant">
                        <IconButton
                            className={classes.contentCalendarHeaderButtonMonth}
                            component="span"
                            color="primary"
                            aria-label="Mois suivant"
                            onClick={(event) => nextMonth()}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Typography component="h2" className={classes.contentCalendarHeaderMonth}>
                    {date_fns_format(selectedDate, dateFormat, {locale: fr})}
                </Typography>


                {/* Filtre 'Sections' */}
                <Box className={classes.contentCalendarHeaderFilterSectionBox}>
                    <SelectSectionAsync
                        setFilterSectionId={setFilterSectionId}
                    />
                </Box>


                <ToggleButtonGroup
                    className={classes.contentCalendarHeaderButtonViewModeGroup}
                    value={currentViewMode}
                    exclusive
                    onChange={handleCalendarViewMode}
                    aria-label="Mode d'affichage de l'agenda"
                >
                    <ToggleButton
                        className={classes.contentCalendarHeaderButtonViewMode}
                        value="grid"
                        aria-label="Affichage de l'agenda en mode grille"
                    >
                        <Tooltip title="Mode agenda" aria-label="Afficher les événements en mode agenda">
                            <GridOnIcon />
                        </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                        className={classes.contentCalendarHeaderButtonViewMode}
                        value="list"
                        aria-label="Affichage de l'agenda en mode liste"
                    >
                        <Tooltip title="Mode liste" aria-label="Afficher les événements en mode liste">
                            <ListIcon />
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box className={classes.contentCalendarBody}>

                {currentViewMode === 'grid' ?
                    <>
                        {/* Entête de la grille (jours de la semaine) */}
                        <WeekdayHeader
                            downMD={downMD}
                        />

                        {/* Grille composée des cellules représentant chacune une journée */}
                        <LstDayCells
                            selectedDate={selectedDate}
                            filterSectionId={filterSectionId}
                        />
                    </>
                :
                    // Mode 'liste' : liste des événements se déroulant dans les prochains jours
                    <LstDayList
                        selectedDate={selectedDate}
                        filterSectionId={filterSectionId}
                    />
                }

            </Box>

            <Box className={classes.contentCalendarFooter}>
            </Box>
        </Paper>
    );
}
