import React from "react";

import clsx from 'clsx';

import {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Box,
    Button,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
    useSwipeable,
} from 'react-swipeable';





// Style propre au composant
const useSlideshowStyles = makeStyles((theme) => ({
    sliderRoot: {
        width: "100%",
        height: "auto",
        margin: "0 auto",
        overflow: 'hidden',

        display: "grid",
        gridTemplateRows: props => props.overlayArrows ? "auto" : "auto auto",
        gridTemplateColumns: "100%",
    },
    sliderContentRoot: {
        gridColumn: "1",
        gridRow: "1",
        zIndex: "500",

        display: "flex",
        height: "auto",
        width: props => `${props.width}px`,
        transform: props => `translateX(${props.translation}px)`,
        transition: props => `transform ease-out ${props.translationDuration}s`,
    },
    slideshowArrows: {
        gridColumn: "1",
        gridRow: props => props.overlayArrows ? "1" : "2",

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    arrowRoot: {
        margin: "1rem",
        display: "flex",
        height: "50px",
        width: "50px",
        minWidth: "unset",         // Désactivation de la propriété par défaut de 'Material-UI'
        justifyContent: "center",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        borderRadius: "50%",
        border: 'unset',
        cursor: "pointer",
        alignItems: "center",
        transition: "transform ease-in 0.1s",
        opacity: "0.5",
        zIndex: "1000",
        '&:hover': {
            transform: "scale(1.1)",
            opacity: "0.8",
            backgroundColor: theme.palette.secondary.light,      // Désactivation de la propriété par défaut de 'Material-UI'
        },
    },
    arrowRootDisabled: {
    },
}));





//
// Composant représenant une flêche (gauche ou droite) permettant
// sélectionner l'image suivante ou précédente
//
function SlideshowArrow(props) {

    // On récupère les propriétés...
    const { direction, handleClick, disabled } = props;



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useSlideshowStyles(props);



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Button
            disabled={disabled}
            onClick={handleClick}
            className={clsx(classes.arrowRoot, disabled && classes.arrowRootDisabled)}
        >
            {direction === 'right' ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
        </Button>
    );
}





//
// Style propre au composant 'SlideshowSlide'
//
const useSlideshowSlideStyles = makeStyles((theme) => ({
    innerSlide: {
        width: props => `${props.slideWidth}px`,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '5px',
        height: '15rem',
        marginLeft: '0px',
        marginRight: '0px',

        [theme.breakpoints.up('sm')]: {
            marginLeft: 'unset',
            marginRight: 'unset',
        },
    },
}));



//
// Composant représentant une slide
//
export const SlideshowSlide = (props) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useSlideshowSlideStyles({
        slideWidth: props.slideWidth,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Affichage du slide : avant d'afficher la slide telle que déssinée
    // par l'utilisateur, on lui ajoute la classe 'innerSlide' contenant
    // le style spécifique au 'slider' courant
    return (
        <Box
            className={clsx(classes.innerSlide, props.className)}
        >
            {props.children}
        </Box>
    );
}





//
//
//
export const SlideshowContent = (props) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useSlideshowStyles(props);


    return (
        <Box
            id={props.name}
            className={clsx(classes.sliderContentRoot, props.className)}
        >
            {props.children.map((slide, index) =>
                <SlideshowSlide
                    key={index}
                    slideWidth={props.slideWidth}
                    {...slide.props}
                />
            )}
        </Box>
     );
}



// Générateur utilisé pour générer la liste des nombres
// compris dans l'intervalle [start ; end ;  step[
function* range(start, end, step=1) {
    for (let i = start; i < end; i+=step) {
        yield i;
    }
}



// Calcule l'index de la slide précédente, à partir de
// la slide 'index', dans un diaporama composé de 'nbSlides' slides
const computePreviousSlide = (index, nbSlides) => {
    // Si on est sur la première slide alors la slide précédente sera
    // la dernière slide du diaporama
    return index == 0 ? nbSlides - 1 : index - 1;
};



// Calcule l'index de la slide suivante, à partir de
// la slide 'index', dans un diaporama composé de 'nbSlides' slides
const computeNextSlide = (index, nbSlides) => {
    // Si on est sur la dernière slide alors la slide suivante sera
    // la première slide du diaporama
    return index == nbSlides - 1 ? 0 : index + 1;
};



// Représente le 'slider' c'est-à-dire le composant principal :
// - il définit la zone d'affichage
// - il contient le composant ''SliderContent
// - il gère la navigation entre les slides (flêches gauche et droite)
// - il gère les 'dots' qui indiquent le nombre total de slides
// - il définit le type de transition entre chaque slide
export const Slideshow = (props) => {


    // On récupère la configuration du slider, telle que
    // définie par l'utilisateur
    const content = props.children;
    const lstSlides = content.props.children;
    const nbTotalSlides = lstSlides.length;
    const nbVisibleSlides = props.nbVisibleSlides;


    // Durée de la transition entre deux slides
    // ATTENTION : le slider réagit à l'événement 'transitionend'. On s'assure donc que cette valeur
    // n'est jamais nulle (mais potentiellement toute petite si pas d'effet de transition) pour être
    // sûr que l'événement 'transitionend' est toujours généré, ce qui n'est pas le cas si 'transitionDuration == 0'
    const transitionDuration = props.transitionDuration == 0 ? 0.01 : props.transitionDuration;


    // Taille de chaque slide. Cette donnée est gérée via un 'useState'
    // pour pouvoir mettre à jour les slides si la taille du
    // conteneur change (redimensionnement...)
    const [slideWidth, setSlideWidth] = useState(0);


    // Liste des slides à mettre dans le conteneur
    // = slide précédente, slide courante et slide suivante
    // Cette liste est recalculée à chaque changement de slide
    const [lstCurrentSlides, setLstCurrentSlides] = useState([])


    // État du slider :
    // - index de la slide courante
    // - translation associée au conteneur : dès que ce nombre change alors on passe à la slide suivante
    // - durée de la translation (en seconde) entre deux slides
    const [state, setState] = useState({
        firstVisibleSlide: props.firstSlide,
        translation: 0,
        translationDuration: 0,
    })


    // Variable permettant de savoir si une animation est en cours... c'est-à-dire si :
    // - la callback 'prevSlide' ou la callback 'nextSlide' vient d'être appelée
    // ET
    // - la callback 'handleTransitionEnd' n'a pas encore été appelée
    const [transitionInProgress, setTransitionInProgress] = useState(false);


    // Référence sur le slideshow, utilisée par les autres composants pour
    // connaître la taille du diaporama
    const refSlideshow = useRef();

    // Référence sur la fonction utilisée après le changement des slides
    const transitionRef = useRef();

    // Référence sur la fonction utilisé pour passer à la slide suivante
    const autoPlayRef = useRef();


    // Handlers utilisés par la bibliothèque 'react-swipeable'
    const swipeableHandlers = useSwipeable({
        // Si l'utilisateur 'swipe' vers la gauche, on passe à la slide suivante
        onSwipedLeft: () => nextSlide(),
        // Si l'utilisateur 'swipe' vers la droite, on passe à la slide précédente
        onSwipedRight: () => prevSlide(),
    });


    // Fonction utilisée pour intercepter la référence associée au composant 'swipeable'
    // afin de transmettre la référence du composant initial au 'handlers' de 'react-swipeable''
    const refSwipeableHandlerFunc = (el) => {
        // On met à jour la référence de 'react-swipeable'
        swipeableHandlers.ref(el);

        // On met à jour la référence initiale 'react'
        refSlideshow.current = el;
    }


    // Fonction permettant de calculer les slides à placer dans le conteneur,
    // en fonction de l'index de la première slide visible, du nombre de slides
    // à afficher et du nombre total de slides dans le diaporama
    function computeCurrentSlides() {
        // On génère une liste de 'nbVisibleSlides + 2' nombres consécutifs (+2 car on place dans
        // le conteneur une slide avant et une slide après qui ne sont pas visibles) puis on calcule
        // les indices des slides visibles à partir de 'firstVisibleSlide'
        // Finalement, on retourne un tableau contenant les 'nbVisibleSlides + 2' slides associés
        return [...range(-1, nbVisibleSlides+1)].map((i) => {
            return lstSlides[(state.firstVisibleSlide + nbTotalSlides + i) % nbTotalSlides];
        });
    }


    // Callback utilisée pour passer à la slide précédente
    const prevSlide = () => {
        // Dans un premier temps, on désactive les boutons "précédent" et "suivant" du slider
        // pour éviter de lancer plusieurs animations en même temps
        setTransitionInProgress(true);

        // On translate de conteneur vers la coordonnée '0'. Comme le conteneur est
        // intialisé avec une position négative alors cela a pour effet de lancer la
        // transition entre les deux slides, via un effet de la gauche vers la droite
        // et on calcule l'index de la nouvelle slide visible
        setState({
            ...state,
            firstVisibleSlide: computePreviousSlide(state.firstVisibleSlide, nbTotalSlides),
            translation: 0,
            translationDuration: transitionDuration,
        });
    };


    // Callback utilisée pour passer à la slide suivante
    const nextSlide = () => {
        // Dans un premier temps, on désactive les boutons "précédent" et "suivant" du slider
        // pour éviter de lancer plusieurs animations en même temps
        setTransitionInProgress(true);

        // On translate de conteneur vers la coordonnée '0'. Comme le conteneur est
        // intialisé avec une position négative alors cela a pour effet de lancer la
        // transition entre les deux slides, via un effet de la gauche vers la droite
        // et on calcule l'index de la nouvelle slide visible
        setState({
            ...state,
            firstVisibleSlide: computeNextSlide(state.firstVisibleSlide, nbTotalSlides),
            translation: -2 * slideWidth,
            translationDuration: transitionDuration,
        });
    };


    // Callback appelée lorsque la transition entre deux slides est terminée
    const handleTransitionEnd = (e) => {
        // L'événement 'transitionend' peut avoir été généré par n'importe quel élement (c'est le
        // cas par exemple s'il y a plusieurs sliders dans la même page): on vérifie donc que
        // c'est bien le slider qui a généré l'événement
        if (e.target == document.getElementById(props.name)) {
            // Si c'est le cas, alors on exécute la fonction de rappel associée
            // Ici, on passe par une référence pour s'assurer que les données sont
            // bien à jour
            transitionRef.current()

            // L'animation est terminée : on réactive les boutons "précédent" et "suivant"
            setTransitionInProgress(false);
        }
    }


    // Callback appelée lorsque le changement de slides a eu lieu
    const updateSlides = () => {
        // On repositionne le conteneur
        setState({
            ...state,
            translation: -slideWidth,
            translationDuration: 0,
        });

        // On recalcule la liste des slides à afficher
        setLstCurrentSlides(computeCurrentSlides());
    }


    // Hook appelé lorsque la taille du conteneur a changé :
    // on positionne correctement le conteneur, sans effet de transistion
    useEffect(() => {
        // On met à jour la position du conteneur, sans transition
        setState({
            ...state,
            translation: -slideWidth,
            translationDuration: 0,
        })
    }, [slideWidth]);


    // Hook appelé lorsque la variable contenant le nombre de slides à afficher
    // a été mise à jour
    useEffect(() => {

        // On recalcule la liste des slides à afficher
        // (pour prendre en compte le nouveau nombre de slides)
        setLstCurrentSlides(computeCurrentSlides());

    }, [props.nbVisibleSlides]);


    // Hook appelé lors que chargement de la page
    useEffect(() => {

        if(refSlideshow) {
            // alors on récupère la taille du conteneur et on calcule
            // la taille de chaque slide
            setSlideWidth(
                Math.ceil(refSlideshow.current.offsetWidth / nbVisibleSlides)
            );
        }

        //
        const play = () => {
            autoPlayRef.current()
        }

        // Timer utilisé pour le défilement automatique des slides (mode 'auto-play')
        const interval = props.autoPlay ? setInterval(play, props.autoPlay * 1000) : null;

        // On met en place le listener associé à la fin de la transition entre deux slides
        const transitionEndEvent = window.addEventListener('transitionend', handleTransitionEnd);

        // On initialise la liste des slides à afficher
        setLstCurrentSlides(computeCurrentSlides());

        // Fonction de nettoyage...
        return _ => {
            // Dans le cas d'une lecture automatique ('auto-play'), on supprime le timer mis en place
            interval && clearInterval(interval);
            // On supprime également le listener associé à la fin de la transition
            window.removeEventListener('transitionend', transitionEndEvent);
        }
    }, []);


    useEffect(() => {
        transitionRef.current = updateSlides;
        autoPlayRef.current = nextSlide;

        if(refSlideshow) {
            // alors on récupère la taille du conteneur et on calcule
            // la taille de chaque slide
            setSlideWidth(
                Math.ceil(refSlideshow.current.offsetWidth / nbVisibleSlides)
            );
        }
    });



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useSlideshowStyles(props);



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.sliderRoot}
            {...swipeableHandlers}
            ref={refSwipeableHandlerFunc}
        >
            {lstCurrentSlides && lstCurrentSlides.length > 0 &&
                <SlideshowContent
                    name={props.name}
                    width={slideWidth * (nbVisibleSlides + 2)}
                    slideWidth={slideWidth}
                    translation={state.translation}
                    translationDuration={state.translationDuration}
                    {...content.props}
                >
                    {lstCurrentSlides}
                </SlideshowContent>
            }

            <Box
                className={classes.slideshowArrows}
            >
                <SlideshowArrow direction="left" disabled={transitionInProgress} handleClick={prevSlide} />
                <SlideshowArrow direction="right" disabled={transitionInProgress} handleClick={nextSlide} />
            </Box>
        </Box>
    );
}
