import React from 'react';

import {
    useState,
} from 'react';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import { useCookies } from 'react-cookie';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
}));



//
// Boite de dialogue permettant de configurer la gestion des cookies
//
export const WWWCookieDialog = (props) => {



    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook utilisé pour gérer les cookies
    const [cookies, setCookies] = useCookies(['cookies']);

    // Variable permettant de contrôler l'ouverture ou la fermeture
    // de la boîte de dialogue de gestion des cookies
    // Par défaut, la boîte de dialogue est fermée si un cookie 'rgpd' est présent
    // sinon elle est ouverte. Il est également possible de forcer l'ouverture via
    // la propriété 'open'
    const [open, setOpen] = useState(props.open || !Boolean(cookies.rgpd));

    // Variable permettant de gérer les cases à cocher associées aux différents types de cookies
    const [cookieStates, setCookieStates] = useState(
        cookies.rgpd ||
        {
            obligatoire: true,
            preferences: true,
            statistiques: true,
            marketing: false,
        }
    );



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // L'utilisateur a fermé la boite de dialogue sans valider ou refuser
    // ('clickaway', 'echap'...)
    const handleClose = (event) => {

        // Si la configuration n'était pas présente...
        if(!cookies.rgpd) {
            // ... alors par défaut on refuse tous les types (sauf l'obligatoire)
            setCookies(
                "rgpd",
                {
                    obligatoire: true,
                    preferences: false,
                    statistiques: false,
                    marketing: false,
                }
            );
        }
        // Si les cookies étaient déjà configurés alors on ne change rien

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur refuse tous les cookies
    const handleDiscard = (event) => {
        // On refuse tous les types (sauf l'obligatoire)
        setCookies(
            "rgpd",
            {
                obligatoire: true,
                preferences: false,
                statistiques: false,
                marketing: false,
            }
        );

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur valide ses choix
    const handleValid = (event) => {
        // On sauvegarde sa sélection
        setCookies("rgpd", cookieStates);

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur a cliqué sur l'une des cases à cocher (ou sur le texte associé)
    // La case à cocher sélectionnée est contenue dans 'value'
    const handleToggle = (event, value) => {
        // On inverse la valeur de la case à cocher
        setCookieStates({
            ...cookieStates,
            [value]: !cookieStates[value]
        });
    }



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Gestion des cookies</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ce site utilise des cookies pour vous offrir la meilleure expérience utilisateur possible. Si vous le souhaitez, vous pouvez désactiver certains d'entre eux.
                </DialogContentText>

                <List>
                    <ListItem key="obligatoire" role={undefined} dense button>
                        <ListItemIcon>
                            <Checkbox
                                disabled
                                edge="start"
                                checked={cookieStates.obligatoire}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextObligatoire' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextObligatoire"
                            primary="Obligatoire"
                            secondary="Ce type de cookies est nécessaire pour permettre au site de fonctionner normalement. Il ne contient aucune donnée personnelle."
                        />
                    </ListItem>

                    <ListItem key="preferences" role={undefined} dense button onClick={(event) => handleToggle(event, "preferences")}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={cookieStates.preferences}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextPreferences' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextPreferences"
                            primary="Préférences"
                            secondary="Ce type de cookies permet de sauvegarder vos préférences de navigation, afin de vous offrir la meilleure expérience utilisateur possible. Il ne contient aucune donnée permettant de vous identifier personnellement."
                        />
                    </ListItem>

                    <ListItem key="statistiques" role={undefined} dense button onClick={(event) => handleToggle(event, "statistiques")}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={cookieStates.statistiques}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextStatistiques' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextStatistiques"
                            primary="Statistiques"
                            secondary="Ce type de cookies permet d'étudier la fréquentation du site (nombre de visiteurs, pages les plus consultées...). Ces informations nous permettent d'améliorer et d'optimiser ce site, dans le but de vous offrir une expérience utilisateur la plus agréable possible. Il ne contient aucune donnée personnelle."
                        />
                    </ListItem>

                    <ListItem key="marketing" role={undefined} dense button>
                        <ListItemIcon>
                            <Checkbox
                                disabled
                                edge="start"
                                checked={cookieStates.marketing}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextMarketing' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextMarketing"
                            primary="Marketing"
                            secondary="Ce type de cookies est utilisé pour proposer du contenu publicitaire adapté à chaque visiteur. Ce site n'utilise pas ce type de cookies."
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDiscard} color="primary">
                    Tout refuser
                </Button>
                <Button onClick={handleValid} color="primary">
                    Valider mon choix
                </Button>
            </DialogActions>
        </Dialog>
    );
}
