import React from 'react';

import {
  Link as RouterLink,
} from "react-router-dom";

import {
    useEffect,
    useState,
    useRef,
} from 'react';

import PropTypes from 'prop-types';

import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import DraftsIcon from '@material-ui/icons/Drafts';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';


// Importation du fichier de configuration
import {
    PHONE_SEPARATOR,
    wwwConfig,
} from "PathWWW/components/WWWConfig.jsx";

import { toStrPhoneNumber } from "PathCommon/tools/utils.jsx";





//
// Boite de dialogue demandant à l'utilisateur de confirmer son inscription à la newsletter
//
const NewsletterSignUpConfirmDialog = (props) => {

    // On récupère les propriétés
    const { open, email, onClose, ...other } = props;


    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée quand l'utilisateur annule son inscrition
    const handleCancel = () => {
        // On ferme la boite de dialogue et on prévient la fenêtre principale
        // que l'utilisateur a cliqué sur le bouton 'Annuler' (= paramètre 'false')
        onClose(false);
    };


    // Callback appelée quand l'utilisateur valide son inscrition
    const handleOk = () => {
        // On ferme la boite de dialogue et on prévient la fenêtre principale
        // que l'utilisateur a cliqué sur le bouton 'Valider' (= paramètre 'true')
        onClose(true);
    };



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="newsletter-signup-confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="newsletter-signup-confirmation-dialog-title">Confirmer votre inscription</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    En cliquant sur le bouton "valider", vous acceptez que la Communauté de Communes du Vexin-Thelle
                    vous envoie régulièrement son bulletin d'information sous format électronique, à l'adresse suivante :
                </DialogContentText>

                <DialogContentText>
                    {email}
                </DialogContentText>

                <DialogContentText>
                    À tout moment, vous pourrez décider de ne plus recevoir ce bulletin d'information électronique en cliquant sur le lien intitulé
                    "se désinscrire", présent en bas de chaque bulletin.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                <Button autoFocus onClick={handleOk} color="primary">
                    S'inscrire
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NewsletterSignUpConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};





//
// Boite de dialogue utilisée pour prévenir l'utilisateur du résultat de son
// inscription à la newsletter (succès, échec...)
//
const ResultSignUpDialog = (props) => {

    // On récupère les propriétés
    const {open, title, content, success, handleOk, ...other} = props;


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="newsletter-signup-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="newsletter-signup-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}





// Style propre au composant 'Formulaire d'inscription à la newsletter'
const useFormEmailNewsletterStyles = makeStyles((theme) => ({
    formInProgressBox: {
        marginTop: '1rem',
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
    },
    footerFormNewsletter: {
        gridRow: 1,
        gridColumn: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    footerButtonNewsletter: {
        marginTop: '1rem',
        marginLeft: 'auto',
    },
    inProgressBox: {
        gridRow: 1,
        gridColumn: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));



//
// Composant utilisé pour gérer l'inscription d'un utilisateur à la newsletter
// Ce composant est constitué d'un 'TextField' permettant de saisir une adresse mail,
// ainsi qu'un bouton permettant de valider l'inscription
//
const FormEmailNewsletter = (props) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Variable contenant la valeur saisie par l'utilisateur dans le 'TextField'
    const [email, setEmail] = useState("");

    // Référence vers le 'textfield' HTML, utilisée pour récupérer directement le status du champ HTML 'validity'
    const txtEmailRef = useRef(null);

    // Variable indiquant si la boite de dialogue demandant confirmation
    // de l'inscription doit être affichée
    const [open, setOpen] = useState(false);

    // Variable indiquant si la boite de dialogue affichant le résultat de
    // l'inscription doit être affichée
    const [dlgResultStatus, setDlgResultStatus] = useState({
        title: "",
        content: "",
        success: undefined,
    });

    // Variable indiquant si le processus d'inscription (entre le serveur web et mautic)
    // est en cours, pour savoir notamment si le champ et le bouton doivent être désactivés
    // et le 'CircularProgress' doit être affiché
    const [inProgress, setInProgress] = useState(false);

    // Variable permettant de contrôler le status du 'TextField' (activé / désactivé)
    const [isTxtEmailNewsletterDisabled, setTxtEmailNewsletterDisabled] = useState(false);

    // Variable permettant de contrôler le status du 'Button' (activé / désactivé)
    const [isBtnNewsletterSignUpDisabled, setBtnNewsletterSignUpDisabled] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque le contenu du 'textfield' est modifié
    const handleTxtEmailNewsletterChange = (event) => {
        // On met à jour la valeur de la variable 'email'
        setEmail(event.target.value);
    };


    // Callback appelée à chaque fois que la valeur contenue dans le 'textfield' (permettant
    // de saisir l'adresse électronique pour l'inscription à la newsletter) est modifiée
    useEffect(() => {

        // On récupère la validité du champs, calculée par le navigateur
        // Ici, on travaille directement sur le champs HTML et non pas 'react'. On passe donc via une référence pour attaquer ce champ
        txtEmailRef.current && setBtnNewsletterSignUpDisabled(txtEmailRef.current.value.length === 0 || !txtEmailRef.current.validity.valid);

    }, [email]);


    // Callback appelée lorsque l'utilisateur a cliqué sur le bouton "s'inscrire"
    const handleBtnNewsletterSignUpClick = (event) => {
        // On ouvre la boite de dialogue demandant confirmation
        setOpen(true);
    };


    // Callback appelée lorsque l'utilisateur a cliqué sur un des boutons de la
    // boite de dialogue de confirmation de l'inscription
    // Le paramètre 'signup' est un booléen indiquant si l'utilisateur a confirmé
    // ou annulé son inscrption
    const handleNewsletterSignUpConfirmClose = (signup) => {
        // On ferme la boite de dialogue
        setOpen(false);

        // Est-ce l'utilisateur a confirmé son inscription ?
        if(signup) {
            // OUI : on affiche un 'CircularProgress' le temps du traitement
            setInProgress(true);

            // On envoie une requête au serveur demandant d'ajouter l'adresse mail à la liste
            fetch(wwwConfig.api.newsletterAddUser, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": email,
                })
            })
            .then((response) => {

                // La requête s'est bien passée... est-ce que le serveur est content ?
                if(response.ok) {
                    // Oui, tout s'est bien passé : on prévient l'utilisateur
                    setDlgResultStatus({
                        title: "Inscription validée",
                        content: "Nous vous remercions pour votre inscription. À très bientôt !",
                        success: true,
                    })
                } else {
                    // Une erreur est survenue, côté serveur : on l'affiche
                    setDlgResultStatus({
                        title: "Une erreur est survenue !",
                        content: "Une erreur est survenue durant votre inscription. Veuillez réessayer ultérieurement. Si le problème persiste, n'hésitez pas à nous contacter. Erreur : " + JSON.stringify(response.status + " - " + response.statusText),
                        success: false,
                    })
                }

                // Dans tous les cas, on vide le champ
                setEmail("");

            })
            .catch((response) => {

                // Une erreur est survenue !
                setDlgResultStatus({
                    title: "Une erreur est survenue",
                    content: "Une erreur est survenue durant votre inscription. Veuillez réessayer ultérieurement. Si le problème persiste, n'hésitez pas à nous contacter. Erreur : " + JSON.stringify(response.status + " - " + response.statusText),
                    success: false,
                })

            })
        }
    };


    // Callback appelée lorsque l'utilisateur a cliqué sur le bouton 'ok'
    // de la boite de dialogue affichant le résultat de l'inscription
    const handleResultSignUpDialogOk = (event) => {
        // On nettoie tout...
        setDlgResultStatus({
            title: "",
            content: "",
            success: undefined,
        })
        setInProgress(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useFormEmailNewsletterStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box className={classes.formInProgressBox}>
            <form
                className={classes.footerFormNewsletter}
                autoComplete="on"
            >
                <TextField
                    inputRef={txtEmailRef}
                    id="txtEmailNewsletter"
                    label="Adresse électronique"
                    type="email"
                    required
                    variant="outlined"
                    value={email}
                    disabled={isTxtEmailNewsletterDisabled || inProgress}
                    onChange={handleTxtEmailNewsletterChange}
                />

                <Button
                    id="btnNewsletterSignUp"
                    className={classes.footerButtonNewsletter}
                    variant="contained"
                    color="primary"
                    endIcon={<DraftsIcon />}
                    disabled={isBtnNewsletterSignUpDisabled || inProgress}
                    onClick={handleBtnNewsletterSignUpClick}
                >
                    S'inscrire
                </Button>
            </form>
            {inProgress &&
                <Box className={classes.inProgressBox}>
                    <CircularProgress />
                </Box>
            }

            {/* Boite de dialogue utilisée pour confirmer l'inscription */}
            <NewsletterSignUpConfirmDialog
                classes={{
                    paper: classes.paper,
                }}
                keepMounted
                open={open}
                onClose={handleNewsletterSignUpConfirmClose}
                email={email}
            />

            {/* Boite de dialogue utilisée pour prévenir l'utilisateur du résultat de l'inscription */}
            <ResultSignUpDialog
                open={dlgResultStatus.success !== undefined}
                title={dlgResultStatus.title}
                content={dlgResultStatus.content}
                success={dlgResultStatus.success}
                handleOk={handleResultSignUpDialogOk}
            />
        </Box>
    );
};




// Style propre au composant
const useStyles = makeStyles((theme) => ({
    footerRoot: {
        display: 'flex',
        flexDirection: 'column',
    },
    footerBoxInfos: {
        backgroundColor: "rgba(127, 173, 197, 0.1)",
    },
    footerContainerInfos: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footerTitre: {
        color: 'rgba(0, 0, 0, 0.54)',
        lineHeight: '3rem',
    },
    footerRSIcon: {
        width: 'auto',
        height: '2rem',
    },
    footerBoxCredits: {
        backgroundColor: "rgba(127, 173, 197, 0.5)",
    },
    footerContainerCredits: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footerBoxCreditsCopyrightAccessibilite: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerBoxCreditsCopyright: {
    },
    footerBoxCreditsAccessibilite: {
        fontSize: '0.9rem',
    },
    footerBoxLinks: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: theme.spacing(1),
    },
    footerBoxLinkAccessibilite: {
    },
    footerBoxLinkSitemap: {
    },
    footerBoxLinkMentionsLegales: {
    },
}));



//
// Composant représentant le 'footer'. On y retrouve notamment :
// - les coordonnées de la CC
// - les liens vers les réseaux sociaux
// - les informations relatives à la newsletter
// - le lien vers les mentions légales
//
export const Footer = ({ footer }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On récupère l'année courante, pour l'afficher à côté du copyright
    const currentYear = new Date().getFullYear();



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.footerRoot}
        >
            <Box
                className={classes.footerBoxInfos}
            >
                <Container
                    maxWidth="lg"
                    className={classes.footerContainerInfos}
                >
                    <Grid container spacing={1}>

                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="subtitle2"
                                className={classes.footerTitre}
                            >
                                Contacts
                            </Typography>

                            <List
                                disablePadding
                                dense
                                aria-label="Liste des moyens de contact de la Communauté de Communes du Vexin-Thelle : téléphone, adresse postale, adresse électronique et horaires"
                            >
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <LocationOnIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Box>
                                                <Typography variant="body2">{footer && footer.adresse1}</Typography>
                                                <Typography variant="body2">{footer && footer.adresse2}</Typography>
                                                <Typography variant="body2">{footer && footer.code_postal} {footer && footer.ville}</Typography>
                                            </Box>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">{footer && footer.telephone && toStrPhoneNumber(footer.telephone, PHONE_SEPARATOR)}</Typography>} />
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Link
                                                component={RouterLink}
                                                to={{ pathname: "/", state: { scrollToRef: "contactFormRef" } }}
                                            >
                                                <Typography variant="body2">{footer && footer.email}</Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <WatchLaterIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Box>
                                                {footer && footer.horaire_ouverture1 && <Typography variant="body2">{footer && footer.horaire_ouverture1}</Typography>}
                                                {footer && footer.horaire_ouverture2 && <Typography variant="body2">{footer && footer.horaire_ouverture2}</Typography>}
                                                {footer && footer.horaire_ouverture3 && <Typography variant="body2">{footer && footer.horaire_ouverture3}</Typography>}
                                                {footer && footer.horaire_ouverture4 && <Typography variant="body2">{footer && footer.horaire_ouverture4}</Typography>}
                                                {footer && footer.horaire_ouverture5 && <Typography variant="body2">{footer && footer.horaire_ouverture5}</Typography>}
                                                {footer && footer.horaire_ouverture6 && <Typography variant="body2">{footer && footer.horaire_ouverture6}</Typography>}
                                                {footer && footer.horaire_ouverture7 && <Typography variant="body2">{footer && footer.horaire_ouverture7}</Typography>}
                                            </Box>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {(
                                footer && footer.Facebook_link ||
                                footer && footer.YouTube_link ||
                                footer && footer.LinkedIn_link ||
                                footer && footer.Instagram_link
                            ) &&
                                <>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.footerTitre}
                                    >
                                        Réseaux sociaux
                                    </Typography>

                                    <List
                                        disablePadding={true}
                                        dense={true}
                                        aria-label="Liste des réseaux sociaux de la Communauté de Communes du Vexin-Thelle"
                                    >
                                        {footer && footer.Facebook_link &&
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon>
                                                    <FacebookIcon
                                                        className={classes.footerRSIcon}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            href={footer.Facebook_link}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            Facebook
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        }

                                        {footer && footer.YouTube_link &&
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon>
                                                    <YouTubeIcon
                                                        className={classes.footerRSIcon}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            href={footer.YouTube_link}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            Youtube
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        }

                                        {footer && footer.LinkedIn_link &&
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon>
                                                    <LinkedInIcon
                                                        className={classes.footerRSIcon}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            href={footer.LinkedIn_link}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            LinkedIn
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        }

                                        {footer && footer.Instagram_link &&
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon>
                                                    <InstagramIcon
                                                        className={classes.footerRSIcon}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            href={footer.Instagram_link}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            Instagram
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        }
                                    </List>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography
                                variant="subtitle2"
                                className={classes.footerTitre}
                            >
                                Newsletter
                            </Typography>

                            <Typography
                                variant="body2"
                            >
                                Pour ne rien rater, inscrivez-vous à notre newsletter
                            </Typography>

                            <FormEmailNewsletter />
                        </Grid>

                    </Grid>

                </Container>
            </Box>

            <Box
                className={classes.footerBoxCredits}
            >
                <Container
                    maxWidth="lg"
                    className={classes.footerContainerCredits}
                >
                    <Box className={classes.footerBoxCreditsCopyrightAccessibilite}>
                        <Typography className={classes.footerBoxCreditsCopyright}>
                            {`© ${currentYear} - Communauté de Communes du Vexin-Thelle`}
                        </Typography>
                        <Typography className={classes.footerBoxCreditsAccessibilite}>
                            Accessibilité du site : audit en cours
                        </Typography>
                    </Box>

                    <Box className={classes.footerBoxLinks}>
                        <Link
                            className={classes.footerBoxLinkAccessibilite}
                            component={RouterLink}
                            to="/accessibilite/"
                        >
                            Accessibilité
                        </Link>
                        <Link
                            className={classes.footerBoxLinkSitemap}
                            component={RouterLink}
                            to="/plan-du-site/"
                        >
                            Plan du site
                        </Link>
                        <Link
                            className={classes.footerBoxLinkMentionsLegales}
                            component={RouterLink}
                            to="/mentions-legales/"
                        >
                            Mentions légales
                        </Link>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
