import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';


import {
    ContentTourinsoftArticleDetailItemASC,
    ContentTourinsoftArticleDetailItemDEG,
    ContentTourinsoftArticleDetailItemFMA,
    ContentTourinsoftArticleDetailItemHEB,
    ContentTourinsoftArticleDetailItemITI,
    ContentTourinsoftArticleDetailItemORG,
    ContentTourinsoftArticleDetailItemPCN,
    ContentTourinsoftArticleDetailItemRES,
    ContentTourinsoftArticleDetailItemSEM,
} from "PathContent/components/ContentTourinsoftArticleDetailItems.jsx";





const COMPONENT_DETAIL_MAPPING = {
    ASC: ContentTourinsoftArticleDetailItemASC,
    DEG: ContentTourinsoftArticleDetailItemDEG,
    FMA: ContentTourinsoftArticleDetailItemFMA,
    HEB: ContentTourinsoftArticleDetailItemHEB,
    ITI: ContentTourinsoftArticleDetailItemITI,
    ORG: ContentTourinsoftArticleDetailItemORG,
    PCN: ContentTourinsoftArticleDetailItemPCN,
    RES: ContentTourinsoftArticleDetailItemRES,
    SEM: ContentTourinsoftArticleDetailItemSEM,
};



//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentTourinsoftArticleDetailRoot: style => ({
        ...style.contentTourinsoftArticleDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentTourinsoftArticleDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentTourinsoftArticleDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentTourinsoftArticleDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentTourinsoftArticleDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentTourinsoftArticleDetailRoot.xl,
        },
    }),

    contentTourinsoftArticleDetailTitle: style => ({
        ...style.contentTourinsoftArticleDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentTourinsoftArticleDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentTourinsoftArticleDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentTourinsoftArticleDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentTourinsoftArticleDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentTourinsoftArticleDetailTitle.xl,
        },
    }),
}));



//
// Composant représentant un contenu de type 'détail d'un article Tourinsoft'
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentTourinsoftArticleDetail = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Id de l'onglet actif
    const [activeTabId, setActiveTabId] = React.useState(0);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback utilisée pour changer l'onglet courant
    const handleChangeActiveTab = (event, newActiveTabId) => {
        setActiveTabId(newActiveTabId);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentTourinsoftArticleDetailRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentTourinsoftArticleDetailTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On détermine le composant à utiliser en fonction du flux
    const ComponentDetailItem = COMPONENT_DETAIL_MAPPING[data.article.flow];


    return (
        <Box
            className={classes.contentTourinsoftArticleDetailRoot}
        >
            {title &&
                <Typography
                    className={classes.contentTourinsoftArticleDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.article &&
                <ComponentDetailItem
                    title={title}
                    params={params}
                    style={style}
                    data={data}
                    {...props}
                />
            }
        </Box>
    );
}
