import React from 'react';

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentResourceDocumentListRoot: style => ({
        ...style.contentResourceDocumentListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListRoot.xl,
        },
    }),

    contentResourceDocumentListTitle: style => ({
        ...style.contentResourceDocumentListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListTitle.xl,
        },
    }),

    contentResourceDocumentListBox: style => ({
        ...style.contentResourceDocumentListBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListBox.xl,
        },
    }),

    contentResourceDocumentListCard: style => ({
        ...style.contentResourceDocumentListCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListCard.xl,
        },
    }),

    contentResourceDocumentListCardActionArea: style => ({
        ...style.contentResourceDocumentListCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListCardActionArea.xl,
        },
    }),

    contentResourceDocumentListCardMedia: style => ({
        ...style.contentResourceDocumentListCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListCardMedia.xl,
        },
    }),

    contentResourceDocumentListCardContent: style => ({
        ...style.contentResourceDocumentListCardContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListCardContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListCardContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListCardContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListCardContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListCardContent.xl,
        },
    }),

    contentResourceDocumentListNoItem: style => ({
        ...style.contentResourceDocumentListNoItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourceDocumentListNoItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourceDocumentListNoItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourceDocumentListNoItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourceDocumentListNoItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourceDocumentListNoItem.xl,
        },
    }),
}));





//
// Composant permettant d'afficher l'annuaire des entreprises (en mode 'preview')
//
export const ContentResourceDocumentList = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentResourceDocumentListRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListBox: {
                    common: {
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListCard: {
                    common: {
                        height: 'inherit',
                        width: '100%',
                    },
                    xs: {},
                    sm: {
                        width: '47%',
                    },
                    md: {
                        width: '31%',
                    },
                    lg: {
                        width: '23%',
                    },
                    xl: {
                        width: '23%',
                    },
                },

                contentResourceDocumentListCardActionArea: {
                    common: {
                        height: '100%',
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        gridTemplateRows: '1fr',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListCardMedia: {
                    common: {
                        gridRow: 1,
                        gridColumn: 1,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListCardContent: {
                    common: {
                        gridRow: 1,
                        gridColumn: 1,
                        padding: '1rem 0.5rem',
                        marginTop: 'auto',
                        color: theme.palette.common.white,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentResourceDocumentListNoItem: {
                    common: {},
                    xs: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    sm: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    md: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentResourceDocumentListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentResourceDocumentListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.lst_documents && data.lst_documents.length > 0 ?
                <Box
                    className={classes.contentResourceDocumentListBox}
                >
                    {data.lst_documents.map((document, index) => (
                        <Card
                            key={index}
                            component="article"
                            className={classes.contentResourceDocumentListCard}
                        >
                            <CardActionArea
                                className={classes.contentResourceDocumentListCardActionArea}
                                component="a"
                                href={document.document_url}
                                target="_blank"
                            >
                                <CardMedia
                                    className={classes.contentResourceDocumentListCardMedia}
                                    component="img"
                                    alt={document.alternate_text}
                                    image={document.thumbnail_url}
                                    title={document.name}
                                />
                                {document.legend &&
                                    <CardContent
                                        className={classes.contentResourceDocumentListCardContent}
                                        component="span"
                                    >
                                        <Typography
                                            component="h2"
                                            variant="body2"
                                            align="center"
                                        >
                                            {document.legend}
                                        </Typography>
                                    </CardContent>
                                }
                            </CardActionArea>
                        </Card>
                    ))}
                </Box>
            :
                <Typography
                    className={classes.contentResourceDocumentListNoItem}
                >
                    Aucun document disponible pour le moment
                </Typography>
            }
        </Box>
    );
}
