import React from 'react';

import {
    useState,
} from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Backdrop,
    Box,
} from '@material-ui/core';

import {
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from '@material-ui/lab';

import {
    makeStyles
} from '@material-ui/core/styles';

import WorkIcon from '@material-ui/icons/Work';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TodayIcon from '@material-ui/icons/Today';
import BuildIcon from '@material-ui/icons/Build';
import EuroIcon from '@material-ui/icons/Euro';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventIcon from '@material-ui/icons/Event';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    fond: {
        zIndex: 2000,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
    },
    speedDial: {
        zIndex: 2500,
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialLink: {
        display: 'inherit',
        justifyContent: 'inherit',
        alignItems: 'inherit',
        color: 'inherit',
        textDecoration: 'none',
    },
    speedDialTooltipLabelNoMaxWidth: {
        minWidth: 'max-content',
        boxShadow: 'unset',
        color: theme.palette.text.primary,
    },
}));



// Liste des actions (= boutons) contenues dans le "SpeedDial"
const SPEEDDIAL_ACTIONS = [
    { icon: <WorkIcon />, name: "Offres d'emploi", url: "/economie-emploi/formation-et-emploi/articles/40-offres-demploi-du-vexin-thelle/" },
    { icon: <ListAltIcon />, name: 'Annuaire des associations', url: "/sport/annuaire-des-associations/" },
    { icon: <TodayIcon />, name: 'Agenda culturel', url: "/culture/agenda-culturel-et-sportif/" },
    { icon: <BuildIcon />, name: 'Permis de construire', url: "/urbanisme/" },
    { icon: <EuroIcon />, name: 'Paiement en ligne', url: "/economie-emploi/" },
    { icon: <DeleteSweepIcon />, name: 'Déchèteries (horaires)', url: "/environnement/la-decheterie-et-le-point-propre/articles/115-la-decheterie-et-le-point-propre/" },
    { icon: <ChildFriendlyIcon />, name: 'Moyens de garde des enfants', url: "/vivre-ensemble/petite-enfance/" },

    { icon: <MenuBookIcon color="primary" />, name: 'Ressource documentaire', url: "/ressource-documentaire/" },
    { icon: <SearchIcon color="primary" />, name: 'Rechercher', url: "/rechercher/" },
    { icon: <EventIcon color="primary" />, name: 'Agenda', url: "/agenda/" },
];



//
// Composant représentant le bouton d'accès rapide,
// présent en bas à droite, sur tout le site.
//
export function WWWFAB(props) {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Utilisé pour gérer l'ouverture et la fermeture du "SpeedDial"
    const [open, setOpen] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lors de l'ouverture du "SpeedDial"
    const handleOpen = (event, reason) => {
        // On ne réagit qu'au clic (et non pas au survol)
        if(reason === "toggle") {
            setOpen(true);
        }
    };


    // Callback appelée lors de la fermeture du "SpeedDial"
    const handleClose = (event, reason) => {
        // On ne ferme pas le "Speed Dial" sur l'événement 'mouseLeave'...
        if(reason === "mouseLeave") {
            return;
        }

        // ... mais uniquement sur les événements 'toggle', 'blur' ou 'escapeKeyDown'
        setOpen(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Backdrop
                className={classes.fond}
                open={open}
            />
            <SpeedDial
                ariaLabel="Accès rapides"
                className={classes.speedDial}
                icon={
                    <SpeedDialIcon
                        icon={<FlashOnIcon />}
                        openIcon={<CloseIcon />}
                    />
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {SPEEDDIAL_ACTIONS.map((action, index) => (
                    <SpeedDialAction
                        key={index}
                        icon={
                            <Link
                                className={classes.speedDialLink}
                                to={action.url}
                            >
                                {action.icon}
                            </Link>
                        }
                        tooltipTitle={
                            <Link
                                className={classes.speedDialLink}
                                to={action.url}
                            >
                                {action.name}
                            </Link>
                        }
                        tooltipOpen
                        classes={{ staticTooltipLabel: classes.speedDialTooltipLabelNoMaxWidth }}
                        onClick={handleClose}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
