import React from 'react';

import {
    useState,
    useEffect,
} from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentFacebookRoot: style => ({
        ...style.contentFacebookRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentFacebookRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentFacebookRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentFacebookRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentFacebookRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentFacebookRoot.xl,
        },
    }),

    contentFacebookTitle: style => ({
        ...style.contentFacebookTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentFacebookTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentFacebookTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentFacebookTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentFacebookTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentFacebookTitle.xl,
        },
    }),

    contentFacebookContainer: style => ({
        ...style.contentFacebookContainer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentFacebookContainer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentFacebookContainer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentFacebookContainer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentFacebookContainer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentFacebookContainer.xl,
        },
    }),

}));



//
// Composant représentant un contenu statique, c'est-à-dire au format HTML
// Toutes les données à afficher sont contenues dans 'data'
//
export const ContentFacebook = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Instance Facebook : on initialise l'API Facebook une seule fois
    // et on sauvegarde l'API dans 'facebookInstance' pour pouvoir
    // rafraichir le widget à chaque rechargement de la page d'accueil (contenant le fil)
    const [facebookInstance, setFacebookInstance] = useState();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lors du chargement de la page : on initialise le composant Facebook
    useEffect(() => {

        // Est-ce que l'API Facebook a déjà été initialisée ?
        if(!facebookInstance) {

            // NON : on crée dynamiquement la balise <script> permettant
            // de charger l'API Facebook
            const script = document.createElement('script');
            script.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v13.0";
            script.async = true;
            script.crossorigin="anonymous";
            script.nonce="dAXkiOmW";
            script.id = "fb-script";

            document.body.appendChild(script);

            // Callback appelée automatiquement par Facebook à la fin de l'initialisation
            // de l'API. Toute l'API est alors disponible dans la variable 'FB'
            window.fbAsyncInit = function() {
                // On sauvegarde l'instance de l'API
                setFacebookInstance(FB);
            }
        }

    }, []);


    // Hook appelé à chaque affichage de la page
    useEffect(() => {
        // Si l'API Facebook est initialisée, on demande de mettre à jour le widget
        if(facebookInstance) {
            facebookInstance.XFBML.parse();
        }
    });



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentFacebookRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentFacebookTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentFacebookContainer: {
                    common: {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '340px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentFacebookRoot}
        >
            {title &&
                <Typography
                    className={classes.contentFacebookTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Box className={classes.contentFacebookContainer}>
                <div id="fb-root"></div>
                <div
                    className="fb-page"
                    data-href="https://www.facebook.com/CCVexinthelle"
                    data-tabs="timeline, events"
                    data-width="340"
                    data-height="800"
                    data-small-header="false"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="false"
                    data-target="_parent"
                >
                    <blockquote
                        cite="https://www.facebook.com/CCVexinthelle"
                        className="fb-xfbml-parse-ignore"
                    >
                        <a href="https://www.facebook.com/CCVexinthelle">Communauté de Communes du Vexin-Thelle</a>
                    </blockquote>
                </div>
            </Box>
        </Box>
    );
}
