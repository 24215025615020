import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';


import { Calendar } from "PathWWW/components/Calendar.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentCalendarRoot: style => ({
        ...style.contentCalendarRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentCalendarRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentCalendarRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentCalendarRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentCalendarRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentCalendarRoot.xl,
        },
    }),

    contentCalendarTitle: style => ({
        ...style.contentCalendarTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentCalendarTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentCalendarTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentCalendarTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentCalendarTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentCalendarTitle.xl,
        },
    }),

}));


//
// Composant permettant d'afficher un calendrier
//
export const ContentCalendar = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentCalendarRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentCalendarTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentCalendarRoot}
        >
            {title &&
                <Typography
                    className={classes.contentCalendarTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Calendar
                viewMode={(params && params.view_mode) || 'auto'}
            />

        </Box>
    );
}
