import {
    createMuiTheme,
} from '@material-ui/core/styles';

import {
    frFR
} from '@material-ui/core/locale';





// Création du thème : on récupère le thème par défaut de MUI
// et on charge les locales françaises
const theme = createMuiTheme({
    menu: {
        colors: {
            main: 'rgb(47, 126, 154)',
            mainLight: 'rgba(47, 126, 154, 0.3)',
            menu1: 'rgb(47, 126, 154)',
            menu2: 'rgb(205, 159, 214)',
            menu3: 'rgb(87, 195, 106)',
            menu4: 'rgb(243, 102, 23)',
            menu5: 'rgb(110, 203, 201)',
            menu6: 'rgb(242, 161, 15)',
            menu7: 'rgb(191, 50, 135)',
            menu8: 'rgb(241, 60, 85)',
        },
    },

    typography: {
        fontFamily: 'Lato, Arial',

        h1: {
            display: "block;",
            width: "100%",
            marginTop: '24px',
            marginBottom: '16px',
            fontSize: "1.3rem",
            padding: "8px 0px",
            color: "rgb(47, 126, 154)",
            textTransform: "uppercase",
            fontWeight: "bold",
            borderBottom: "3px solid rgb(47, 126, 154)",
        },
    },

    // Redefinition de la couleur principale
    // les couleurs 'light', 'dark' et 'contrastText' seront automatiquement
    // calculées à partir de cette couleur
    palette: {
        primary: {
            main: 'rgb(47, 126, 154)',
        }
    }

}, frFR);

export default theme;
