import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';


import { ContentFactory } from 'PathContent/components/ContentFactory.jsx';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    ///////////////////////////////////////////////
    //                                           //
    //     Style commun à tous les 'layouts'     //
    //                                           //
    ///////////////////////////////////////////////

    contentLayoutRoot: style => ({
        ...style.contentLayoutRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRoot.xl,
        },
    }),
    contentLayoutTitle: style => ({
        ...style.contentLayoutTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutTitle.xl,
        },
    }),



    ////////////////////////////////////////////
    //                                        //
    //     Mise en page de type 'columns'     //
    //                                        //
    ////////////////////////////////////////////

    contentLayoutRootColumns: style => ({
        ...style.contentLayoutRootColumns.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootColumns.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootColumns.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootColumns.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootColumns.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootColumns.xl,
        },
    }),



    /////////////////////////////////////////
    //                                     //
    //     Mise en page de type 'rows'     //
    //                                     //
    /////////////////////////////////////////

    contentLayoutRootRows: style => ({
        ...style.contentLayoutRootRows.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootRows.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootRows.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootRows.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootRows.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootRows.xl,
        },
    }),



    ///////////////////////////////////////////
    //                                       //
    //     Mise en page de type 'panels'     //
    //                                       //
    ///////////////////////////////////////////

    contentLayoutRootPanels: style => ({
        ...style.contentLayoutRootPanels.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootPanels.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootPanels.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootPanels.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootPanels.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootPanels.xl,
        },
    }),

    contentLayoutRootPanel1: style => ({
        ...style.contentLayoutRootPanel1.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootPanel1.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootPanel1.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootPanel1.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootPanel1.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootPanel1.xl,
        },
    }),

    contentLayoutRootPanel2: style => ({
        ...style.contentLayoutRootPanel2.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootPanel2.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootPanel2.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootPanel2.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootPanel2.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootPanel2.xl,
        },
    }),



    /////////////////////////////////////////
    //                                     //
    //     Mise en page de type 'grid'     //
    //                                     //
    /////////////////////////////////////////

    contentLayoutRootGrid: style => ({
        ...style.contentLayoutRootGrid.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentLayoutRootGrid.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentLayoutRootGrid.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentLayoutRootGrid.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentLayoutRootGrid.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentLayoutRootGrid.xl,
        },
    }),
}));



//
// Composant représentant un contenu de type 'layout', c'est-à-dire contenant
// les informations relatives à la structuration des composants au sein de la page
//
export const ContentLayout = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentLayoutRoot: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: '1280px',
                        gap: '1rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentLayoutTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentLayoutRootColumns: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentLayoutRootRows: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentLayoutRootPanels: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        flexDirection: 'row',
                    },
                    lg: {
                        flexDirection: 'row',
                    },
                    xl: {
                        flexDirection: 'row',
                    },
                },

                contentLayoutRootPanel1: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '100%',
                        flex: '1 1 100%',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        flex: '1 1 70%',
                        maxWidth: '70%',
                    },
                    lg: {
                        flex: '1 1 70%',
                        maxWidth: '70%',
                    },
                    xl: {
                        flex: '1 1 70%',
                        maxWidth: '70%',
                    },
                },

                contentLayoutRootPanel2: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '100%',
                        flex: '1 1 100%',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        flex: '1 1 30%',
                        maxWidth: '30%',
                    },
                    lg: {
                        flex: '1 1 30%',
                        maxWidth: '30%',
                    },
                    xl: {
                        flex: '1 1 30%',
                        maxWidth: '30%',
                    },
                },

                contentLayoutRootGrid: {
                    common: {
                        display: 'grid',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On calcule le contenu en fonction du type de layout (contenu dans 'params.kind')
    let content = null;

    switch(params.kind) {
        case "columns":
            content = (
                <Box
                    className={classes.contentLayoutRootColumns}
                >
                    {data &&
                        data.map((content, index) => (
                            <ContentFactory key={index} content={content} {...props} />
                        ))
                    }
                </Box>
            );
            break;

        case "rows":
            content = (
                <Box
                    className={classes.contentLayoutRootRows}
                >
                    {data &&
                        data.map((content, index) => (
                            <ContentFactory key={index} content={content} {...props} />
                        ))
                    }
                </Box>
            );
            break;

        case "panels":
            content = (
                <Box
                    className={classes.contentLayoutRootPanels}
                >
                    <Box
                        className={classes.contentLayoutRootPanel1}
                    >
                        {data &&
                            data.slice(0, params.nb_rows).map((content, index) => (
                                <ContentFactory key={index} content={content} {...props} />
                            ))
                        }
                    </Box>
                    <Box
                        className={classes.contentLayoutRootPanel2}
                    >
                        {data &&
                            data.slice(params.nb_rows).map((content, index) => (
                                <ContentFactory key={index} content={content} {...props} />
                            ))
                        }
                    </Box>
                </Box>
            );
            break;

        case "grid":
            content = (
                <Box
                    className={classes.contentLayoutRootGrid}
                >
                    {data &&
                        data.map((content, index) => (
                            <ContentFactory key={index} content={content} {...props} />
                        ))
                    }
                </Box>
            );
            break;
    }


    // On retourne le résultat en insérant le contenu calculé précédemment
    return (
        <Box
            className={classes.contentLayoutRoot}
        >
            {title &&
                <Typography
                    className={classes.contentLayoutTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            { content }
        </Box>
    );
}
