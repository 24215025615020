import React from 'react';

import {
    useState,
} from 'react';

import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardMedia,
    Dialog,
    Slide,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';


import {
    Slideshow,
    SlideshowContent,
    SlideshowSlide,
} from "PathCommon/tools/Slideshow.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentResourcePhotoGalleryRoot: style => ({
        ...style.contentResourcePhotoGalleryRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryRoot.xl,
        },
    }),

    contentResourcePhotoGalleryTitle: style => ({
        ...style.contentResourcePhotoGalleryTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryTitle.xl,
        },
    }),

    contentResourcePhotoGalleryBox: style => ({
        ...style.contentResourcePhotoGalleryBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryBox.xl,
        },
    }),


    contentResourcePhotoGalleryCard: style => ({
        ...style.contentResourcePhotoGalleryCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryCard.xl,
        },
    }),

    contentResourcePhotoGalleryCardActionArea: style => ({
        ...style.contentResourcePhotoGalleryCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryCardActionArea.xl,
        },
    }),

    contentResourcePhotoGalleryCardMedia: style => ({
        ...style.contentResourcePhotoGalleryCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGalleryCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGalleryCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGalleryCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGalleryCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGalleryCardMedia.xl,
        },
    }),

    contentResourcePhotoGallerySlideshowDialog: style => ({
        ...style.contentResourcePhotoGallerySlideshowDialog.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGallerySlideshowDialog.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGallerySlideshowDialog.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGallerySlideshowDialog.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGallerySlideshowDialog.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGallerySlideshowDialog.xl,
        },
    }),

    contentResourcePhotoGallerySlideshowButtonClose: style => ({
        ...style.contentResourcePhotoGallerySlideshowButtonClose.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGallerySlideshowButtonClose.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGallerySlideshowButtonClose.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGallerySlideshowButtonClose.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGallerySlideshowButtonClose.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGallerySlideshowButtonClose.xl,
        },
    }),

    contentResourcePhotoGallerySlideshowRoot: style => ({
        ...style.contentResourcePhotoGallerySlideshowRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGallerySlideshowRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGallerySlideshowRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGallerySlideshowRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGallerySlideshowRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGallerySlideshowRoot.xl,
        },
    }),

    contentResourcePhotoGallerySlideshowSlide: style => ({
        ...style.contentResourcePhotoGallerySlideshowSlide.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGallerySlideshowSlide.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGallerySlideshowSlide.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGallerySlideshowSlide.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGallerySlideshowSlide.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGallerySlideshowSlide.xl,
        },
    }),

    contentResourcePhotoGallerySlideshowImg: style => ({
        ...style.contentResourcePhotoGallerySlideshowImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoGallerySlideshowImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoGallerySlideshowImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoGallerySlideshowImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoGallerySlideshowImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoGallerySlideshowImg.xl,
        },
    }),

}));



// Utilisée pour animer l'ouverture et la fermeture de la boite de dialogue
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



//
// Composant permettant d'afficher un diaporama photo
// Ce composant est constitué de deux parties :
// - une partie 'preview' permettant d'afficher quelques photos du diaporama
// - une partie 'slider' (affiché dans une boite de dialogue) permettant d'afficher
//       tous les photos du diaporama
//
export const ContentResourcePhotoGallery = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On récupère le nombre de photos à afficher dans la preview (contenu dans les paramètres)
    // Si ce nombre n'est pas précisé, on affiche au maximum 4 photos
    const NB_PHOTOS = params.nb_photos_preview || Math.min(data.photo_gallery.length, 4);

    // On calcule la longueur (en %) de chaque photo, en gardant 4% pour avoir un
    // espacement entre chaque photo
    const PHOTO_WIDTH = Math.floor(96 / NB_PHOTOS);


    // Index de la première slide à afficher dans le slider :
    // si l'utilisateur a cliqué sur une image en particulier
    // alors on affiche le slider à partir de cette image
    const [firstSlide, setFirstSlide] = useState(null);

    // Variable indiquant le statut de la boite de dialogue
    // utilisée pour afficher la galerie photos
    const [open, setOpen] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque l'utilisateur a sélectionné une photo dans le diaporama
    const handleClickOpen = (photo_id) => {

        // On recherche l'index dans la liste de la photo sélectionnée, grâce à son ID 'photo_id'
        let index = data.photo_gallery.findIndex((element) => element.id === photo_id);
        if(index < 0) {
            // La photo n'a pas été trouvée (ce cas ne devrait pas se produire)
            // dans ce cas, on commence le diaporama à la première photo
            index = 0;
        }

        // On sauvegarde l'index
        setFirstSlide(index);

        // Et on affiche la boite de dialogue
        setOpen(true);
    };


    // Callback appelée pour fermer la boite de dialogue
    const handleClose = () => {
        // On ferme la boite de dialogue
        setOpen(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentResourcePhotoGalleryRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGalleryTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGalleryBox: {
                    common: {
                        height: '30vh',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGalleryCard: {
                    common: {
                        width: `${PHOTO_WIDTH}%`,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGalleryCardActionArea: {
                    common: {
                        width: '100%',
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGalleryCardMedia: {
                    common: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGallerySlideshowDialog: {
                    common: {
                        position: 'relative',
                        width: '100vw',
                        height: '100vh',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGallerySlideshowButtonClose: {
                    common: {
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        margin: theme.spacing(3),
                        zIndex: 5000,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGallerySlideshowRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGallerySlideshowSlide: {
                    common: {
                        height: '100vh',
                        backgroundColor: theme.palette.grey['900'],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoGallerySlideshowImg: {
                    common: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            {/* Conteneur utilisé pour afficher les photos en mode 'preview' */}
            <Box
                className={classes.contentResourcePhotoGalleryRoot}
            >
                {title &&
                    <Typography
                        className={classes.contentResourcePhotoGalleryTitle}
                        component="h1"
                        variant="h1"
                    >
                        {title}
                    </Typography>
                }

                <Box className={classes.contentResourcePhotoGalleryBox}>
                    {data.photo_gallery.slice(0, NB_PHOTOS).map((photo) => (
                        <Card key={photo.id} className={classes.contentResourcePhotoGalleryCard}>
                            <CardActionArea
                                className={classes.contentResourcePhotoGalleryCardActionArea}
                                onClick={() => handleClickOpen(photo.id)}
                            >
                                <CardMedia
                                    className={classes.contentResourcePhotoGalleryCardMedia}
                                    component="img"
                                    src={photo.src}
                                    srcSet={photo.srcset}
                                    sizes={`${PHOTO_WIDTH}w`}
                                    title={`${photo.legend} - ${photo.credits}`}
                                    alt={photo.alt}
                                />
                            </CardActionArea>
                        </Card>
                    ))}
                </Box>
            </Box>

            {/* Boite de dialogue utilisée pour afficher le diaporama photo */}
            <Dialog
                className={classes.contentResourcePhotoGallerySlideshowDialog}
                fullScreen={true}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Button
                    className={classes.contentResourcePhotoGallerySlideshowButtonClose}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    startIcon={<CloseIcon />}
                    aria-label="fermer le diaporama"
                >
                    Fermer
                </Button>

                <Slideshow
                    name="idSlideshowGallery"
                    className={classes.contentResourcePhotoGallerySlideshowRoot}
                    nbVisibleSlides={1}
                    firstSlide={firstSlide}
                    autoPlay={false}
                    transitionDuration={1}
                    overlayArrows={true}
                >
                    <SlideshowContent>
                        {data.photo_gallery.map((item) => (
                            <SlideshowSlide
                                key={item.id}
                                className={classes.contentResourcePhotoGallerySlideshowSlide}
                            >
                                <Box
                                    className={classes.contentResourcePhotoGallerySlideshowImg}
                                    component="img"
                                    src={item.src}
                                    srcSet={item.srcset}
                                    sizes="100vw"
                                    title={`${item.legend} - ${item.credits}`}
                                    alt={item.alt}
                                />
                            </SlideshowSlide>
                        ))}
                    </SlideshowContent>
                </Slideshow>
            </Dialog>
        </>
    );
}
