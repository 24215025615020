import { useEffect } from "react";
import { useLocation } from "react-router-dom";





//
// Composant permettant de se replacer en haut de la page. En effet, par défaut,
// lors de la navigation entre les pages, le scroll est conservé. Or, il est généralement
// plus logique de se replacer en haut de chaque page. Cela peut être fait en plaçant
// ce composant avant le routage des pages
//
export function ScrollOnTop(props) {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    const location = useLocation();



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée à chaque fois que l'URL a changé
    useEffect(() => {

        // Est-ce que le 'state' courant contient un paramètre 'scrollToRef' ?
        if(!(location.state && location.state.scrollToRef)) {
            // NON : on se replace en haut de la page
            window.scrollTo(0, 0);
        }

    }, [location.pathname]);



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Rien d'autre à faire...
    return null;
}
