import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    List,
    ListItem,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';





// Style propre au composant
const useArticlePreviewCardStyles = makeStyles((theme) => ({
    articlePreviewCard: {
        height: '100%',
        width: '100%',
    },
    articlePreviewCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    articlePreviewCardMedia: {
        objectFit: 'cover',
        width: '100%',
        height: '20vw',
        maxWidth: '20vw',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '15rem',
            minHeight: '15rem',
            maxWidth: 'unset',
        },
    },
    articlePreviewCardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
    },
    articlePreviewCardContentTitle: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.3rem',
        }
    },
    articlePreviewCardContentContent: {
        paddingTop: theme.spacing(2),
        maskImage: 'linear-gradient(to bottom, black 70%, transparent 90%)',
        height: '20vh',
        '-webkit-mask-image': 'linear-gradient(to bottom, black 70%, transparent 90%)',
    }
}));





//
// Composant représentant un article présenté sous
// forme d'une carte.
//
export const ArticlePreviewCard = ({ article, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useArticlePreviewCardStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.articlePreviewCard}>
            <CardActionArea
                className={classes.articlePreviewCardActionArea}
                component={Link}
                to={{ pathname: article.url_detail }}
            >
                {article.img_header &&
                    <CardMedia
                        className={classes.articlePreviewCardMedia}
                        component="img"
                        src={article.img_header.src}
                        srcSet={article.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${article.img_header.legend} - ${article.img_header.credits}`}
                        alt={article.img_header.alt}
                    />
                }

                <CardContent
                    component="span"
                    className={classes.articlePreviewCardContent}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.articlePreviewCardContentTitle}
                    >
                        {article.title}
                    </Typography>

                    <Box
                        component="span"
                        className={classes.articlePreviewCardContentContent}
                        dangerouslySetInnerHTML={{ __html: article.content }}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
}





//
// Style propre au composant 'ArticlePreviewList'
//
const useArticlePreviewListStyles = makeStyles((theme) => ({
    articlePreviewList: {
        width: '100%',
    },
    articlePreviewListActionArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    articlePreviewListMedia: {
        width: '30%',
        minWidth: '30%',
        height: '15rem',
        objectFit: 'cover',
        backgroundColor: '#fafafa',
    },
    articlePreviewListContent: {
        width: '70%',
        height: '15rem',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    articlePreviewListContentTitle: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.3rem',
        }
    },
    articlePreviewListContentContent: {
        paddingTop: theme.spacing(2),
        maskImage: 'linear-gradient(to bottom, black 70%, transparent 90%)',
        height: '20vh',
        '-webkit-mask-image': 'linear-gradient(to bottom, black 70%, transparent 90%)',
    }
}));



//
// Composant représentant un article présenté sous
// forme d'une liste'.
//
export const ArticlePreviewList = ({ article, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useArticlePreviewListStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.articlePreviewList}>
            <CardActionArea
                className={classes.articlePreviewListActionArea}
                component={Link}
                to={article.url_detail}
            >
                {article.img_header &&
                    <CardMedia
                        className={classes.articlePreviewListMedia}
                        component="img"
                        src={article.img_header.src}
                        srcSet={article.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${article.img_header.legend} - ${article.img_header.credits}`}
                        alt={article.img_header.alt}
                    />
                }
                <CardContent
                    component="span"
                    className={classes.articlePreviewListContent}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.articlePreviewListContentTitle}
                    >
                        {article.title}
                    </Typography>

                    <Box
                        component="span"
                        className={classes.articlePreviewListContentContent}
                        dangerouslySetInnerHTML={{ __html: article.content }}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
}





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentArticleListRoot: style => ({
        ...style.contentArticleListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListRoot.xl,
        },
    }),

    contentArticleListTitle: style => ({
        ...style.contentArticleListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListTitle.xl,
        },
    }),

    contentArticleListItem: style => ({
        ...style.contentArticleListItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListItem.xl,
        },
    }),

}));



//
// Composant permettant d'afficher une liste d'article, en mode 'preview'
//
export const ContentArticleList = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        Object.assign(
            {
                contentArticleListRoot: {
                    common: {
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentArticleListTitle: {
                    common: {
                        color: theme.menu.colors[data.content_color],
                        borderColor: theme.menu.colors[data.content_color],
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentArticleListItem: {
                    common: {
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Actuellement, deux modes d'affichage sont disponbiles :
    // > md : mode 'desktop' (grand écran)
    // <= md : mode 'mobile'
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Box
            className={classes.contentArticleListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentArticleListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.lst_articles.length > 0 ?

                (params.mode === 'grid' && isMDUp) ?

                    <Grid
                        container
                        spacing={3}
                    >
                        {data.lst_articles.map((article) => (
                            <Grid
                                item
                                xs={12} sm={6} lg={4}
                                key={article.id}
                            >
                                <ArticlePreviewCard
                                    article={article}
                                />
                            </Grid>
                        ))}
                    </Grid>

                :

                    <List className={classes.root}>
                        {data.lst_articles.map((article) =>
                            <ListItem
                                key={article.id}
                                className={classes.contentArticleListItem}
                                alignItems="flex-start"
                                disableGutters
                            >
                                <ArticlePreviewList
                                    article={article}
                                />
                            </ListItem>
                        )}
                    </List>

            :
                <Typography>
                    Aucun article disponible pour le moment
                </Typography>
            }
        </Box>
    );
}
