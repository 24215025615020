import React from 'react';

import {
    useState,
    useEffect,
    useRef,
} from 'react';

import {
  Link,
} from "react-router-dom";

import {
    AppBar,
    Box,
    Divider,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';


// Composants propres au projet
import { Entete } from "PathWWW/components/Entete.jsx";
import { Menu } from "PathWWW/components/Menu.jsx";

import { CCVTIcon } from "PathWWW/components/CCVTIcon.jsx";

import {
    MENU_ICON_MAPPING,
} from 'PathWWW/components/WWWConfig.jsx';





//
// Style propre au composant 'MobileMenuItem' (menu en version 'mobile')
//
const useStylesMobileMenuItem = makeStyles((theme) => ({
    mobileMenuItemListItemN1: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    mobileMenuItemListItemN1Link: {
        width: '100%',
        padding: '0.5rem',
        marginBottom: theme.spacing(1),
        backgroundColor: props => theme.menu.colors[props.menuColor],

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.common.white,
    },
    mobileMenuItemListItemN1Icon: {
        minWidth: theme.spacing(4),
        color: theme.palette.common.white,
    },
    mobileMenuItemListN2: {
        width: '100%',
    },
    mobileMenuItemListItemN2: {
        padding: theme.spacing(0.5, 0, 0.5, 3),
    },
    mobileMenuItemListItemN2Link: {
        width: '100%',
        textDecoration: 'none',
        color: props => theme.menu.colors[props.menuColor],
    },
}));



//
// Composant représentant un menu de niveau 1, en version 'mobile'
// Ce composant permet notamment de modifier dynamiquement la couleur du
// menu (texte et fond) en fonction de la section actuelle, passée dans les
// propriétés
//
const MobileMenuItem = ({ menu, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStylesMobileMenuItem({
        menuColor: menu.color,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On récupère dynamiquement le composant React contenant l'icone associé au menu courant
    const CurrentIcon = MENU_ICON_MAPPING[menu.icon];

    return (
        <ListItem
            button
            component="li"
            className={classes.mobileMenuItemListItemN1}
        >
            <Link
                className={classes.mobileMenuItemListItemN1Link}
                to={menu.url}
            >
                <ListItemIcon
                    className={classes.mobileMenuItemListItemN1Icon}
                >
                    <CurrentIcon />
                </ListItemIcon>

                <ListItemText
                    primary={menu.label}
                />
            </Link>

            <List
                disablePadding={true}
                className={classes.mobileMenuItemListN2}
            >
                {menu.submenus.map((submenuItem, index) => (
                    <ListItem
                        key={index}
                        button
                        className={classes.mobileMenuItemListItemN2}
                        component="li"
                    >
                        <Link
                            className={classes.mobileMenuItemListItemN2Link}
                            to={submenuItem.url}
                        >
                            <ListItemText
                                primary={submenuItem.label}
                            />
                        </Link>
                    </ListItem>
                ))}
            </List>
        </ListItem>
    );
};





//
// Style propre au composant 'CustomAppBar'
//
const useStylesAppBar = makeStyles((theme) => ({
    appBar: {
        color: 'rgb(47, 126, 154)',
        backgroundColor: 'rgb(250, 250, 250)',

        [theme.breakpoints.up('md')]: {
            // Utilisée pour afficher ou masquer la 'toolbar' en fonction du scroll
            transform: offsetToolbar => `translateY(-${offsetToolbar}px)`,
            transition: 'transform 0.5s ease 0s',
        },
    },
    drawerHeader: {
        width: '80vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(1),
        justifyContent: 'flex-start',
    },
    drawerHeaderLink: {
        width: 'auto',
        height: '2.5rem',
    },
    drawerHeaderIcon: {
        width: 'auto',
        height: '100%',
    },
    drawerHeaderTagline: {
        marginLeft: theme.spacing(1),
    },
    drawerHeaderTaglineCC: {
        color: theme.menu.colors.main,
        fontSize: '0.7rem',
    },
    drawerHeaderTaglineTexte: {
        color: theme.menu.colors.main,
        fontSize: '0.8rem',
        fontFamily: 'Satisfy, Arial',
    },
    drawerHeaderCloseIcon: {
        marginLeft: 'auto',
    },
}));



//
// Composant utilisé pour afficher le bandeau dans l'entête de la page
// Ce bandeau est composé :
// - d'une 'AppBar' composée d'une 'Toolbar'
// - du bouton permettant d'afficher le 'Menu Drawer' (mode 'mobile' uniquement)
// - du 'menu drawer' (mode 'mobile' uniquement)
// - de l'entête de la page composée du logo de la CCVT et de la 'tagline'
// - du menu
//
export const Header = ({ header, menu }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Référence vers l'objet 'toolbar' à masquer lors du scroll
    // Utilisée pour récupérer dynamiquement sa hauteur
    const refToolbar = useRef();


    // Translation à appliquer à la 'toolbar'
    // Peut prendre deux valeurs :
    // - 0 : dans ce cas, la 'toolbar' est visible
    // - 'toolbarSize' : dans ce cas, la 'toolbar' est masquée
    const [offsetToolbar, setOffsetToolbar] = useState(0);


    // Permet de gérer l'état du 'drawer' (ouvert ou fermé)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé au chargement de la page :
    // - on récupère la hauteur de la 'toolbar' qui sera masquée lors du scroll
    // - on met en place un listener sur l'événement 'scroll'
    useEffect(() => {
        // On ajoute un listener sur l'événement 'scroll'
        const onScroll = window.addEventListener('scroll', scrollEvent)

        return () => {
            // Lorsque que la fenêtre est détruite, on n'oublie pas de
            // se désabonner de l'événement 'scroll'
            window.removeEventListener('scroll', onScroll)
        }
    }, []);


    // Callback appelée dès qu'un scroll a lieu dans la fenêtre
    const scrollEvent = () => {

        // Est-ce que la taille du scroll vertical est supérieure à la taille
        // de la 'toolbar' ?
        if(refToolbar.current && (window.scrollY >= refToolbar.current.offsetHeight)) {
            // OUI : on masque la 'toolbar'. Pour cela, on sauvegarde dans
            // 'offsetToolbar' la valeur en pixel correspondant à la translation
            // de l'élément (ici, on translate de la taille de la 'toolbar')
            setOffsetToolbar(refToolbar.current.offsetHeight);

        } else {
            // NON : on affiche la 'toolbar'. Pour cela, la translation sera de 0 pixel
            setOffsetToolbar(0);
        }

    }


    // Callback utilisée pour demander l'ouverture du 'menu drawer'
    const handleDrawerOpen = () => {
        setIsOpenDrawer(true);
    };


    // Callback utilisée pour demander la fermeture du 'menu drawer'
    const handleDrawerClose = () => {
        setIsOpenDrawer(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStylesAppBar(offsetToolbar);



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <AppBar
            position="sticky"
            className={classes.appBar}
        >
            <Toolbar
                ref={refToolbar}
            >
                {/* Le bouton ouvrant le 'drawer' et le 'drawer' lui-même ne sont visibles qu'en mode 'xs' ou 'sm' */}
                <Hidden
                    mdUp
                    implementation="css"
                >
                    <IconButton
                        color="inherit"
                        aria-label="Ouverture du menu de navigation"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    <SwipeableDrawer
                        anchor="left"
                        open={isOpenDrawer}
                        onClick={handleDrawerClose}
                        onOpen={handleDrawerOpen}
                        onClose={handleDrawerClose}
                    >
                        <Box className={classes.drawerHeader}>
                            <Link
                                className={classes.drawerHeaderLink}
                                to="/"
                            >
                                <CCVTIcon
                                    className={classes.drawerHeaderIcon}
                                />
                            </Link>

                            <Box
                                className={classes.drawerHeaderTagline}
                            >
                                <Typography
                                    className={classes.drawerHeaderTaglineCC}
                                    component="h1"
                                    variant="h6"
                                >
                                    Communauté de Communes du Vexin-Thelle
                                </Typography>

                                <Typography
                                    className={classes.drawerHeaderTaglineTexte}
                                    component="span"
                                    variant="h6"
                                >
                                    {`\u00AB\u00A0Ensemble, construisons notre avenir\u00A0\u00BB`}
                                </Typography>
                            </Box>

                            <IconButton
                                className={classes.drawerHeaderCloseIcon}
                                onClick={handleDrawerClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Divider />

                        <List>
                            {menu.menu_full.map((menuItem, index) =>
                                <MobileMenuItem
                                    key={index}
                                    menu={menuItem}
                                />
                            )}
                        </List>
                    </SwipeableDrawer>
                </Hidden>

                {/* Entête de la page : logo de la CCVT + tagline */}
                <Entete isVisible={true} />
            </Toolbar>

            <Divider />

            {/* Le menu n'est visible qu'en mode 'md', 'lg' ou 'xl' */}
            <Hidden
                smDown
                implementation="css"
            >
                <Menu menu={menu} />
            </Hidden>

        </AppBar>
    );
}
