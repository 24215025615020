import React from 'react';

import {
    FormControl,
    FormHelperText,
} from '@material-ui/core';

// Bibliothèque 'formik', utilisée pour la gestion des formulaires
import {
    useField,
} from 'formik';

// Bibliothèque utilisée pour gérer le "captcha" de Google (formulaire de contact...)
import ReCAPTCHA from "react-google-recaptcha";





//
// Composant utilisé pour wrapper un composant 'Google ReCaptcha' dans un formulaire 'formik'
//
export const FormikReCaptchaField = ({recaptchaRef, ...props}) => {

    // ATTENTION : pour le moment, on ne passe pas par le composant <Field ... component={FormikTextField} />
    // mais directement depuis <FormikTextField ... /> avec le hook 'useField' car dans le premier cas,
    // 'meta' n'est pas contenu dans les propiétés renvoyées par '<Field />'. Du coup, il est plus complexe
    // dans ce premier cas de récupérer les valeurs de 'touched' et 'error' pour chaque champ


    // On construit le champ en fonction de 'props'
    // field : name, onBlur, onChange, value
    // meta : error, initialError, initialTouched, initialValue, touched, value
    // helpers : setError, setTouched, setValue
    const [field, meta, helpers] = useField(props);


    // Callback appelée lorsque la valeur du captcha a changé
    const onChange = (value) => {
        // Si la validation s'est bien passée alors 'value' contient une clé
        // Sinon, s'il y a eu un problème (réseau, délai expiré...) alors 'value' vaut 'null'
        helpers.setTouched(true);
        helpers.setValue(value);
    }


    // On affiche le résultat via un 'Checkbox' de Material-UI
    return (
        <FormControl
            error={meta.touched && Boolean(meta.error)}
            component="fieldset"
            {...props}
        >
            <ReCAPTCHA
                id={field.name}
                name={field.name}
                ref={recaptchaRef}
                sitekey={props.sitekey}
                onChange={onChange}
            />

            {meta.touched && meta.error &&
                <FormHelperText>{meta.error}</FormHelperText>
            }
        </FormControl>
    );
};
